import Swiper from "swiper/swiper-bundle";

const option__original = {
  loop: false,
  autoplay: false,
  spaceBetween: 24,
  slidesPerView: 3,
  slidesPerGroup: 1,
  breakpoints: {
    320: {
      slidesPerView: 1.1,
      spaceBetween: 10,
      slidesOffsetBefore: 10,
    },
    768: {
      slidesPerView: 2.1,
      slidesOffsetBefore: 10,
      slidesPerGroup: 2,
    },
    1025: {
      slidesPerView: 2.5,
      slidesPerGroup: 2,
    },
  },
};

const option__means = Object.assign({}, option__original, {
  navigation: {
    nextEl: "#main-carousel__arrows-next__means",
    prevEl: "#main-carousel__arrows-prev__means",
  },
});

const option__subsidy = Object.assign({}, option__original, {
  navigation: {
    nextEl: "#main-carousel__arrows-next__subsidy",
    prevEl: "#main-carousel__arrows-prev__subsidy",
  },
});

const option__loan = Object.assign({}, option__original, {
  navigation: {
    nextEl: "#main-carousel__arrows-next__loan",
    prevEl: "#main-carousel__arrows-prev__loan",
  },
});

const option__shindan = Object.assign({}, option__original, {
  navigation: {
    nextEl: "#main-carousel__arrows-next__shindan",
    prevEl: "#main-carousel__arrows-prev__shindan",
  },
});

const option__history = Object.assign({}, option__original, {
  navigation: {
    nextEl: "#main-carousel__arrows-next__history",
    prevEl: "#main-carousel__arrows-prev__history",
  },
  breakpoints: {
    320: {
      slidesPerView: 1.7,
      spaceBetween: 10,
      slidesOffsetBefore: 10,
    },
    768: {
      slidesPerView: 3,
      slidesOffsetBefore: 10,
    },
    1025: {
      slidesPerView: 4,
      slidesOffsetBefore: 0,
    },
  },
});

const option__history__only = Object.assign({}, option__original, {
  navigation: {
    nextEl: "#main-carousel__arrows-next__history",
    prevEl: "#main-carousel__arrows-prev__history",
  },
  breakpoints: {
    320: {
      slidesPerView: 1.7,
      spaceBetween: 10,
      slidesOffsetBefore: 10,
    },
    768: {
      slidesPerView: 3,
      slidesOffsetBefore: 10,
    },
    1025: {
      slidesPerView: 4.2,
      spaceBetween: 16,
      slidesOffsetBefore: 0,
    },
  },
});

const swiper__means = new Swiper("#swiper-container__means", option__means);
const swiper__subsidy = new Swiper(
  "#swiper-container__subsidy",
  option__subsidy
);
const swiper__loan = new Swiper("#swiper-container__loan", option__loan);

const swiper__history = new Swiper(
  "#swiper-container__history",
  option__history
);

const swiper__history__only = new Swiper(
  "#swiper-container__history--onlycarousel",
  option__history__only
);

const swiper__shidan = new Swiper(
  "#swiper-container__shindan",
  option__shindan
);
