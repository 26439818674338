// // TOPページのタグ
// const topSearchTagData = [
//   "融資",
//   "金融機関",
//   "計画",
//   "審査",
//   "基本",
//   "資金繰り",
//   "銀行",
//   "専門家",
//   "申請",
//   "起業・開業",
// ];

// // 検索結果ページのタグで、バックエンドから取得
// const resultSearchTagData = [
//   "テスト1",
//   "テスト2",
//   "融資",
//   "金融機関",
//   "計画",
//   "審査",
//   "基本",
//   "資金繰り",
//   "銀行",
//   "専門家",
//   "申請",
//   "起業・開業",
// ];

const tagsInput = document.getElementById("ls-tags");

const searchTagsContainer = document.getElementById("ls-tagButton");
const searchTagsContainerSp = document.getElementById("ls-tagButton-sp");
const searchTagsResultContainer = document.getElementById("ls-tagButtonResult");
const searchTagsResultContainerSp = document.getElementById(
  "ls-tagButtonResult-sp"
);

const createTagsList = (tagData, targetList, isSp) => {
  tagData.forEach((tag) => {
    const button = document.createElement("button");
    button.textContent = tag;
    button.type = "button";
    button.classList.add("ls-searchTag__item");
    if (isSp) {
      button.classList.add("ls-searchTag__item--sp");
    }
    targetList.appendChild(button);

    // タグを押したときの挙動を実装
    button.addEventListener("click", () => {
      toggleTagSelection(tag);
    });
  });
};

const createTagsList1 = () => {
  const tagItems = document.querySelectorAll(":not(#ls-popluarTagButton) > .ls-searchTag__item")

  tagItems.forEach((tagItem) => {
    // タグを押したときの挙動を実装
    tagItem.addEventListener("click", () => {
      toggleTagSelection(tagItem.textContent);
    });
  });
};

const toggleTagSelection = (tag) => {
  const buttons = document.querySelectorAll(":not(#ls-popluarTagButton) > .ls-searchTag__item")
  buttons.forEach((button) => {
    if (button.textContent === tag) {
      button.classList.toggle("selected");
    }
  });
  updateTagsInput();
};

// タグを押したときに一番前に持ってくる
const prependTag = (targetList) => {
  document.querySelectorAll(".ls-searchTag__item--sp").forEach((button) => {
    button.addEventListener("click", function () {
      targetList.prepend(this);
      sortSpTag(targetList);
    });
  });
};

// タグを押したときに一番前に持ってくる
const prependTag1 = (targetList) => {
  document.querySelectorAll("#ls-tagButton > .ls-searchTag__item").forEach((pcTagButton) => {
    pcTagButton.addEventListener("click", function () {
      let targetItem = null;
      targetList.querySelectorAll("button").forEach((targetButton) => {
        if (targetButton.textContent === pcTagButton.textContent) {
          targetItem = targetButton;
        }
      });
      if (targetItem) {
        targetList.prepend(targetItem);
      }
      sortSpTag(targetList);
    });
  });
}

// 選択されているタグが前に選択されていないタグが後になるようにタグを並べ替える
const sortSpTag = (targetList) => {
  selectedButtons = []
  unSelectedButtons = []
  targetList.querySelectorAll("button").forEach((button) => {
    if (button.classList.contains("selected")) {
      selectedButtons.push(button);
    } else {
      unSelectedButtons.push(button);
    }
  });
  selectedButtons.forEach((button) => {
    targetList.append(button);
  });
  unSelectedButtons.forEach((button) => {
    targetList.append(button);
  });
}

// TOPページにタグを表示
if (searchTagsContainer && searchTagsContainerSp) {
  // createTagsList(topSearchTagData, searchTagsContainer);
  // createTagsList(topSearchTagData, searchTagsContainerSp, true);
  createTagsList1();
  prependTag(searchTagsContainerSp);
  prependTag1(searchTagsContainerSp);
}

// // 結果ページにタグを表示
// if (searchTagsResultContainer && searchTagsResultContainerSp) {
//   createTagsList(resultSearchTagData, searchTagsResultContainer);
//   createTagsList(resultSearchTagData, searchTagsResultContainerSp, true);
//   prependTag(searchTagsResultContainerSp);

//   // 結果ページでは検索に使われているタグを選択状態にする
//   document.addEventListener("DOMContentLoaded", function () {
//     const urlParams = new URLSearchParams(window.location.search);
//     const tagsParam = urlParams.get("sc[tag]");
//     tagsInput.value = tagsParam;
//     if (tagsParam) {
//       const tags = tagsParam.split(",").map((tag) => tag.trim());
//       const tagButtons = document.querySelectorAll(".ls-searchTag__item");
//       tagButtons.forEach((button) => {
//         if (tags.includes(button.textContent)) {
//           button.classList.add("selected");
//         }
//       });
//     }
//   });
// }

// 結果ページを読み込み時にタグを並べ替える
if (searchTagsResultContainerSp) {
  document.addEventListener("DOMContentLoaded", sortSpTag(searchTagsResultContainerSp));
}

// クエリパラメータに反映させる
const updateTagsInput = () => {
  const selectedTags = [];
  const selectedTagButtons = document.querySelectorAll(
    ".ls-searchTag__item.selected"
  );

  if (selectedTagButtons.length > 0) {
    selectedTagButtons.forEach((tag) => {
      selectedTags.push(tag.textContent);
      const uniqueSelectedTags = [...new Set(selectedTags)];
      tagsInput.value = uniqueSelectedTags.join(", ");
    });
  } else {
    tagsInput.value = "";
  }
};
