// const suggestResult = ["助成金", "補助金", "助成金申請"]; // バックエンドから送られてくるサジェスト配列

const keywordHidden = document.getElementById("ls-keyword");
const inputField = document.getElementById("ls-keywords");
const suggestionsBox = document.getElementById("ls-suggestions");
const inputFieldSp = document.getElementById("ls-keywords-sp");
const suggestionsBoxSp = document.getElementById("ls-suggestions-sp");

let getSuggestionsTimer;
let inputStr;

document.addEventListener("DOMContentLoaded", () => {
  if (inputField) {
    inputField.addEventListener("input", reflectInputTextField);
    inputField.addEventListener("keyup", reflectInputTextField);
    inputField.addEventListener("click", reflectInputTextField);

    inputField.addEventListener("input", handleCursorMove);
    inputField.addEventListener("keyup", handleCursorMove);
    inputField.addEventListener("click", handleCursorMove);
  }
  if (inputFieldSp) {
    inputFieldSp.addEventListener("input", reflectInputTextField);
    inputFieldSp.addEventListener("keyup", reflectInputTextField);
    inputFieldSp.addEventListener("click", reflectInputTextField);

    inputFieldSp.addEventListener("input", handleCursorMove);
    inputFieldSp.addEventListener("keyup", handleCursorMove);
    inputFieldSp.addEventListener("click", handleCursorMove);
  }

  document.addEventListener("click", (event) => {
    if (
      suggestionsBox &&
      !suggestionsBox.contains(event.target) &&
      event.target !== inputField
    ) {
      hideSuggestions(suggestionsBox);
    }

    if (
      suggestionsBoxSp &&
      !suggestionsBoxSp.contains(event.target) &&
      event.target !== inputFieldSp
    ) {
      hideSuggestions(suggestionsBoxSp);
    }
  });

  function handleCursorMove(event) {
    if (event.target == inputField) {
      inputStr = getWordAtCursor(inputField);
    } else {
      inputStr = getWordAtCursor(inputFieldSp);
    }

    if (getSuggestionsTimer) {
      clearTimeout(getSuggestionsTimer);
    }
    getSuggestionsTimer = setTimeout(getSuggestions, 100)
  }

  function getSuggestions() {
    if (inputStr) {
      query = "/api/v1/study_search_suggestions?str=" + inputStr
      $.getJSON(query)
      .done(function(data) {
        let suggestResult = [];
        if (data) {
          suggestResult = data;
        }
        displaySuggestions(suggestResult, inputField, suggestionsBox);
        displaySuggestions(suggestResult, inputFieldSp, suggestionsBoxSp);
      });
    } else {
      hideSuggestions(suggestionsBox);
      hideSuggestions(suggestionsBoxSp);
    }
  }

  function getWordAtCursor(inputElement) {
    const { selectionStart, value } = inputElement;

    const leftPart = value.slice(0, selectionStart);
    const leftSpaceIndex = leftPart.lastIndexOf(" ");
    const leftFullWidthSpaceIndex = leftPart.lastIndexOf("　");
    const left = Math.max(leftSpaceIndex, leftFullWidthSpaceIndex) + 1;

    const rightPart = value.slice(selectionStart);
    const rightSpaceIndex = rightPart.indexOf(" ");
    const rightFullWidthSpaceIndex = rightPart.indexOf("　");
    const right =
      selectionStart +
      (rightSpaceIndex === -1 && rightFullWidthSpaceIndex === -1
        ? rightPart.length
        : Math.min(
            rightSpaceIndex === -1 ? Infinity : rightSpaceIndex,
            rightFullWidthSpaceIndex === -1
              ? Infinity
              : rightFullWidthSpaceIndex
          ));

    const cursorWord = value.slice(left, right);

    return cursorWord;
  }

  function displaySuggestions(suggestions, inputField, suggestionsBox) {
    suggestionsBox.innerHTML = "";
    suggestions.forEach((suggestion) => {
      const suggestionItem = document.createElement("div");
      suggestionItem.classList.add("ls-suggestion__item");
      suggestionItem.textContent = suggestion;
      suggestionItem.addEventListener("click", () => {
        replaceWordAtCursor(inputField, suggestion);
        reflectInputTextField();
        suggestionsBox.innerHTML = "";
      });
      suggestionsBox.appendChild(suggestionItem);
    });

    showSuggestions(suggestionsBox);
  }

  function replaceWordAtCursor(inputElement, newWord) {
    const { selectionStart, value } = inputElement;

    const leftPart = value.slice(0, selectionStart);
    const leftSpaceIndex = leftPart.lastIndexOf(" ");
    const leftFullWidthSpaceIndex = leftPart.lastIndexOf("　");
    const left = Math.max(leftSpaceIndex, leftFullWidthSpaceIndex) + 1;

    const rightPart = value.slice(selectionStart);
    const rightSpaceIndex = rightPart.indexOf(" ");
    const rightFullWidthSpaceIndex = rightPart.indexOf("　");
    const right =
      selectionStart +
      (rightSpaceIndex === -1 && rightFullWidthSpaceIndex === -1
        ? rightPart.length
        : Math.min(
            rightSpaceIndex === -1 ? Infinity : rightSpaceIndex,
            rightFullWidthSpaceIndex === -1
              ? Infinity
              : rightFullWidthSpaceIndex
          ));

    const before = value.slice(0, left);
    const after = value.slice(right);

    inputElement.value = before + newWord + after;

    const newCursorPosition = left + newWord.length;
    inputElement.setSelectionRange(newCursorPosition, newCursorPosition);
  }
});

function reflectInputTextField() {
  if (inputField.value !== keywordHidden.value) {
    keywordHidden.value = inputField.value;
    inputFieldSp.value = inputField.value;
  }
  if (inputFieldSp.value !== keywordHidden.value) {
    keywordHidden.value = inputFieldSp.value;
    inputField.value = inputFieldSp.value;
  }
}

function showSuggestions(suggestionsBox) {
  if (suggestionsBox) {
    suggestionsBox.style.display = "block";
  }
}

function hideSuggestions(suggestionsBox) {
  if (suggestionsBox) {
    suggestionsBox.style.display = "none";
  }
}
