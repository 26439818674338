// // 仮のカテゴリーデータ
// const categoryData = [
//   {
//     major: "大カテゴリ1",
//     minor: ["小カテゴリ1-1", "小カテゴリ1-2", "小カテゴリ1-3"],
//   },
//   {
//     major: "大カテゴリ2",
//     minor: ["小カテゴリ2-1", "小カテゴリ2-2", "小カテゴリ2-3"],
//   },
//   {
//     major: "大カテゴリ3",
//     minor: ["小カテゴリ3-1", "小カテゴリ3-2", "小カテゴリ3-3"],
//   },
// ];

const categoryInput = document.getElementById("ls-category");

const categoryButton = document.getElementById("ls-categoryButton");
const categoryList = document.getElementById("ls-categoryList");

const categoryButtonSp = document.getElementById("ls-categoryButtonSp");
const categoryListSp = document.getElementById("ls-categoryListSp");
const categoryListCloseSp = document.getElementById("ls-categoryListCloseSp");
const categoryListBgSp = document.getElementById("ls-categoryListBgSp");

const categoryListResult = document.getElementById("ls-categoryListResult");

document.addEventListener("DOMContentLoaded", () => {
  if (categoryList) {
    // categoryList.innerHTML = "";
    // categoryData.forEach((category) => {
    //   const majorItem = createMajorCategory(category.major);
    //   categoryList.appendChild(majorItem);
    //   category.minor.forEach((minorCategory) => {
    //     const minorItem = createMinorCategory(minorCategory);
    //     categoryList.appendChild(minorItem);
    //   });
    // });
    createCategoryDrawer1(categoryList);
  }

  if (categoryListSp) {
    // createCategoryDrawer(categoryListSp);
    createCategoryDrawer2(categoryListSp);
  }
  if (categoryListResult) {
    // createCategoryDrawer(categoryListResult);
    createCategoryDrawer3(categoryListResult);
  }
});

if (categoryButton) {
  categoryButton.addEventListener("click", () => {
    categoryList.classList.toggle("show");
    const iconPlus = categoryButton.querySelector(
      ".ls-categoryButton__icon--plus"
    );
    const iconMinus = categoryButton.querySelector(
      ".ls-categoryButton__icon--minus"
    );
    if (iconPlus && iconMinus) {
      iconPlus.classList.toggle("hidden");
      iconMinus.classList.toggle("hidden");
    }
  });
}

if (categoryButtonSp) {
  categoryButtonSp.addEventListener("click", () => {
    categoryListSp.classList.toggle("show");
    categoryListBgSp.classList.toggle("show");
  });
}

if (categoryButtonSp) {
  categoryListBgSp.addEventListener("click", () => {
    categoryListSp.classList.toggle("show");
    categoryListBgSp.classList.toggle("show");
  });
}

if (categoryListCloseSp) {
  categoryListCloseSp.addEventListener("click", () => {
    categoryListSp.classList.remove("show");
    categoryListBgSp.classList.remove("show");
  });
}

const createMajorCategory = (majorCategory) => {
  const item = document.createElement("button");
  item.className = "ls-categoryList__item ls-categoryList__item--major";
  item.textContent = majorCategory;
  item.addEventListener("click", (event) => {
    event.preventDefault();
    updateCategory(majorCategory);
  });
  return item;
};
const createMinorCategory = (minorCategory) => {
  const item = document.createElement("button");
  item.className = "ls-categoryList__item ls-categoryList__item--minor";
  item.textContent = minorCategory;
  item.addEventListener("click", (event) => {
    event.preventDefault();
    updateCategory(minorCategory);
  });
  return item;
};
const createCategoryDrawer = (targetList) => {
  categoryData.forEach((category) => {
    const categoryItem = document.createElement("div");
    categoryItem.className = "ls-categoryListSp__item";

    const majorItem = document.createElement("div");
    majorItem.className = "ls-categoryListSp__item--major";
    majorItem.innerHTML = `
    <div class="ls-categoryListSp__itemWrapper--major">
    <button type="button">${category.major}</button>
    <div class="ls-categoryListSp__toggleIcon">
    <div class="ls-categoryListSp__toggleIcon--plus"></div>
    <div class="ls-categoryListSp__toggleIcon--minus hidden"></div>
    </div>
    </div>`;

    const majorButton = majorItem.querySelector("button");
    majorButton.addEventListener("click", (event) => {
      event.preventDefault();
      updateCategory(category.major);
    });

    const minorItemWrapper = document.createElement("div");
    minorItemWrapper.className = "ls-categoryListSp__itemWrapper--minor";

    category.minor.forEach((item) => {
      const minorItem = document.createElement("button");
      minorItem.type = "button";
      minorItem.textContent = item;
      minorItem.className = "ls-categoryListSp__item--minor";
      minorItemWrapper.appendChild(minorItem);

      minorItem.addEventListener("click", (event) => {
        event.preventDefault();
        updateCategory(item);
      });
    });

    const toggleIcon = majorItem.querySelector(
      ".ls-categoryListSp__toggleIcon"
    );
    const plusIcon = toggleIcon.querySelector(
      ".ls-categoryListSp__toggleIcon--plus"
    );
    const minusIcon = toggleIcon.querySelector(
      ".ls-categoryListSp__toggleIcon--minus"
    );

    toggleIcon.addEventListener("click", (e) => {
      e.stopPropagation();

      if (!minorItemWrapper.classList.contains("show")) {
        minorItemWrapper.style.display = "block";
        const height = minorItemWrapper.scrollHeight;
        minorItemWrapper.style.display = "";
        minorItemWrapper.style.setProperty("--content-height", `${height}px`);
      } else {
        minorItemWrapper.style.setProperty("--content-height", "0px");
      }

      minorItemWrapper.classList.toggle("show");
      plusIcon.classList.toggle("hidden");
      minusIcon.classList.toggle("hidden");
    });

    categoryItem.appendChild(majorItem);
    categoryItem.appendChild(minorItemWrapper);
    targetList.appendChild(categoryItem);
  });
};

const createCategoryDrawer1 = (targetList) => {
  const categoryListItems = targetList.querySelectorAll(".ls-categoryList__item");

  categoryListItems.forEach((categoryListItem) => {
    categoryListItem.addEventListener("click", (event) => {
      event.preventDefault();
      updateCategory(categoryListItem.textContent);
    });
  });
}

const createCategoryDrawer2 = (targetList) => {
  const categoryItems = targetList.querySelectorAll(".ls-categoryListSp__item");

  categoryItems.forEach((categoryItem) => {
    const majorItem = categoryItem.querySelector(".ls-categoryListSp__item--major");

    const majorButton = majorItem.querySelector("button");
    majorButton.addEventListener("click", (event) => {
      event.preventDefault();
      updateCategory(majorButton.textContent);
    });

    const minorItemWrapper = categoryItem.querySelector(".ls-categoryListSp__itemWrapper--minor");

    const minorItems = minorItemWrapper.querySelectorAll(".ls-categoryListSp__item--minor");
    minorItems.forEach((minorItem) => {
      minorItem.addEventListener("click", (event) => {
        event.preventDefault();
        updateCategory(minorItem.textContent);
      });
    });

    const toggleIcon = majorItem.querySelector(
      ".ls-categoryListSp__toggleIcon"
    );
    const plusIcon = toggleIcon.querySelector(
      ".ls-categoryListSp__toggleIcon--plus"
    );
    const minusIcon = toggleIcon.querySelector(
      ".ls-categoryListSp__toggleIcon--minus"
    );

    toggleIcon.addEventListener("click", (e) => {
      e.stopPropagation();

      if (!minorItemWrapper.classList.contains("show")) {
        minorItemWrapper.style.display = "block";
        const height = minorItemWrapper.scrollHeight;
        minorItemWrapper.style.display = "";
        minorItemWrapper.style.setProperty("--content-height", `${height}px`);
      } else {
        minorItemWrapper.style.setProperty("--content-height", "0px");
      }

      minorItemWrapper.classList.toggle("show");
      plusIcon.classList.toggle("hidden");
      minusIcon.classList.toggle("hidden");
    });
  });
};

const createCategoryDrawer3 = (targetList) => {
  const categoryItems = targetList.querySelectorAll(".ls-categoryListSp__item");

  categoryItems.forEach((categoryItem) => {
    const majorItem = categoryItem.querySelector(".ls-categoryListSp__item--major");
    const minorItemWrapper = categoryItem.querySelector(".ls-categoryListSp__itemWrapper--minor");

    const toggleIcon = majorItem.querySelector(
      ".ls-categoryListSp__toggleIcon"
    );
    const plusIcon = toggleIcon.querySelector(
      ".ls-categoryListSp__toggleIcon--plus"
    );
    const minusIcon = toggleIcon.querySelector(
      ".ls-categoryListSp__toggleIcon--minus"
    );

    toggleIcon.addEventListener("click", (e) => {
      e.stopPropagation();

      if (!minorItemWrapper.classList.contains("show")) {
        minorItemWrapper.style.display = "block";
        const height = minorItemWrapper.scrollHeight;
        minorItemWrapper.style.display = "";
        minorItemWrapper.style.setProperty("--content-height", `${height}px`);
      } else {
        minorItemWrapper.style.setProperty("--content-height", "0px");
      }

      minorItemWrapper.classList.toggle("show");
      plusIcon.classList.toggle("hidden");
      minusIcon.classList.toggle("hidden");
    });
  });
};

const updateCategory = (category) => {
  const categoryButtonText = categoryButton.querySelector(
    ".ls-categoryButton__text"
  );
  if (categoryButtonText) {
    categoryButtonText.textContent = category;
  }
  const categoryButtonTextSp = categoryButtonSp.querySelector(
    ".ls-categoryButton__text"
  );
  if (categoryButtonTextSp) {
    categoryButtonTextSp.textContent = category === "すべて" ? "カテゴリ選択" : category;
  }

  categoryList.classList.remove("show");
  categoryListSp.classList.remove("show");
  categoryListBgSp.classList.remove("show");
  categoryInput.value = category === "すべて" ? "" : category;

  const categoryItem = categoryList.querySelectorAll(".ls-categoryList__item");
  categoryItem.forEach((item) => {
    if (item.textContent === category) {
      item.classList.add("selected");
    } else {
      item.classList.remove("selected");
    }
  });

  const categorySpMajorItem = categoryListSp.querySelectorAll(".ls-categoryListSp__item--major");
  categorySpMajorItem.forEach((item) => {
    const button = item.querySelector("button");
    if (button.textContent === category) {
      item.classList.add("selected");
    } else {
      item.classList.remove("selected");
    }
  });

  const categorySpMinorItem = categoryListSp.querySelectorAll(".ls-categoryListSp__item--minor");
  categorySpMinorItem.forEach((item) => {
    if (item.textContent === category) {
      item.classList.add("selected");
    } else {
      item.classList.remove("selected");
    }
  });

  const categoryButtonIcon = categoryButton.querySelector(
    ".ls-categoryButton__icon"
  );
  if (categoryButtonIcon) {
    const plusIcon = categoryButtonIcon.querySelector(
      ".ls-categoryButton__icon--plus"
    );
    const minusIcon = categoryButtonIcon.querySelector(
      ".ls-categoryButton__icon--minus"
    );

    if (plusIcon && minusIcon) {
      if (categoryList.classList.contains("show")) {
        plusIcon.classList.add("hidden");
        minusIcon.classList.remove("hidden");
      } else {
        plusIcon.classList.remove("hidden");
        minusIcon.classList.add("hidden");
      }
    }
  }
};
