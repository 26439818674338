import { setWithCountAnimation, sanitizeMoney, updateDelimitedMoney, setDelimitedMoney, delimitedMoney } from "./suchiUtil"
import { handleUntenShikinSetSimulationBar } from "./numBtn"

// 運転資金データ
let runningData = [
  [0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0],
];
let uriageSum = [0, 0, 0];
let profit = [0, 0, 0];

function getMonthlyUriage(year) {
  return parseInt(uriageSum[year - 1] / 12);
}

// タブ切り替え
function handleTabChange(e) {
  const rangeForm = document.getElementById("running-slider");
  const runningInput = document.getElementById("running-input");

  const target = e.target;
  const tabId = target.id.slice(-1);
  rangeForm.style.display = "grid";
  runningInput.style.display = "none";
  // tabのview変更
  const prevSelected = document.querySelector(".nav-link.active");
  if (prevSelected) prevSelected.classList.remove("active");
  target.classList.add("active");
  const selectedYear = tabId;
  // 運転資金項目の反映
  changeUntenView(selectedYear)
  // 月額利益の反映
  updateMonthlyProfit(selectedYear);
  changeProfitView(selectedYear);
}

function changeUntenView(year) {
  const dataInputsRunning = document.querySelectorAll(".data-input--running");
  const rangeInputsRunning = document.querySelectorAll(".input-slider--running");
  dataInputsRunning.forEach((input, i) => {
    input.value = runningData[year - 1][i].toLocaleString('ja-JP');
  });

  const maxRange = getMonthlyUriage(year) < 100 ? 100 : getMonthlyUriage(year);
  rangeInputsRunning.forEach((input, i) => {
    const inputData = runningData[year - 1][i];
    input.max = maxRange
    input.value = inputData;
    input.defaultValue = inputData;
    if (inputData !== 0) {
      input.style.backgroundSize =
        (inputData * 100) / input.max + 1 + "% 100%";
    } else {
      input.style.backgroundSize = "0% 100%";
    }
  });
}

// 比較タブのクリック時
function handleClickCompareTab() {
  // 現在のタブをinactiveにする
  const prevSelected = document.querySelector(".nav-link.active");
  if (prevSelected) prevSelected.classList.remove("active");

  const rangeForm = document.getElementById("running-slider");
  const runningInput = document.getElementById("running-input");

  rangeForm.style.display = "none";
  runningInput.style.display = "grid";
  setCompareInputs(runningData)
}

export function handleSetCompareInputs() {
  setCompareInputs(runningData);
}

// 比較タブにrunningDataの内容を反映する
function setCompareInputs(runningData) {
  const compareInputs = document.querySelectorAll(".data-input--compare");
  const compareProfits = document.querySelectorAll(".data-input--compare--profit");

  compareInputs.forEach((input, i) => {
    const year = input.dataset.year;
    const itemId = input.dataset.itemid;
    input.value = runningData[year - 1][itemId - 1];
    setDelimitedMoney(input);
  });
  compareProfits.forEach((input, i) => {
    input.value = profit[i];
    setDelimitedMoney(input);
  });
}

// 比較タブ(支出項目)変更
function handleCompareInputChange(event) {
  const textFieldElement = event.target;
  const val = sanitizeMoney(textFieldElement.value);

  const inputId = textFieldElement.dataset.itemid;
  const selectedYear = textFieldElement.dataset.year;

  // text fieldをカンマ区切り&hidden fieldへのセット
  updateDelimitedMoney(textFieldElement);

  runningData[selectedYear - 1][inputId - 1] = val;
  updateMonthlyProfit(selectedYear)
  setCompareInputs(runningData)
  updateAsideRunning();
}

// 比較タブ(利益項目)変更
function handleCompareProfitChange(event) {
  const textFieldElement = event.target;
  const profitOfThisYear = sanitizeMoney(textFieldElement.value);
  const selectedYear = textFieldElement.id.slice(-1);

  updateItemsByProfit(selectedYear, profitOfThisYear);
  setCompareInputs(runningData);
  updateAsideRunning();
}

// 売上入力
function handleUriageChange(event) {
  const textFieldElement = event.target;

  const currentTab = document.querySelector(".nav-link.active")
  const currentTabId = currentTab ? Number(document.querySelector(".nav-link.active").id.slice(-1)) : 9999;

  const uriageId = textFieldElement.id.slice(-1); // 末尾の数字を取得

  uriageSum[uriageId - 1] = sanitizeMoney(textFieldElement.value);

  // hidden fieldの更新
  updateDelimitedMoney(textFieldElement);

  // 売上入力後に利益を更新する
  updateMonthlyProfit(uriageId)

  if (Number(currentTabId) === Number(uriageId)) {
    changeUntenView(uriageId);
    changeProfitView(uriageId);
  }

  setCompareInputs(runningData)
  updateAsideRunning();
}

// レンジスライダー運転資金
function handleRangeInputRunningChange(e) {
  const selectedYear = Number(document.querySelector(".nav-link.active").id.slice(-1));
  const target = e.target;
  const max = Number(target.max);
  const val = Number(target.value);

  const inputId = target.id.replace("input-slider--running", "data-input--running");
  const inputIdNum = inputId.slice(-1);
  const inputElement = document.getElementById(inputId);
  target.defaultValue = val;
  inputElement.value = target.defaultValue;
  runningData[selectedYear - 1][inputIdNum - 1] = val;

  if (val !== 0) {
    target.style.backgroundSize = (val * 100) / max + 1 + "% 100%";
  } else {
    target.style.backgroundSize = "0% 100%";
  }

  // 月額利益の更新
  updateMonthlyProfit(selectedYear);
  changeProfitView(selectedYear);
  setCompareInputs(runningData)
  updateAsideRunning();
}

// 運転資金数値入力
function handleDataInputRunningChange(e) {
  const selectedYear = Number(document.querySelector(".nav-link.active").id.slice(-1));

  const target = e.target;
  const val = sanitizeMoney(target.value);
  const sliderId = target.id.replace("data-input--running", "input-slider--running");
  const sliderIdNum = sliderId.slice(-1);
  const sliderElement = document.getElementById(sliderId);
  
  sliderElement.defaultValue = val;
  sliderElement.value = val;
  runningData[selectedYear - 1][sliderIdNum - 1] = val;
  if (val !== 0) {
    sliderElement.style.backgroundSize =
      (val * 100) / sliderElement.max + 1 + "% 100%";
  } else {
    sliderElement.style.backgroundSize = "0% 100%";
  }

  // カンマ区切りに変更
  target.value = delimitedMoney(target.value);

  updateMonthlyProfit(selectedYear);
  changeProfitView(selectedYear);
  setCompareInputs(runningData)
  updateAsideRunning();
}

// 月額利益
function handleProfitSliderInput(e) {
  const selectedYear = Number(document.querySelector(".nav-link.active").id.slice(-1));
  const target = e.target;
  const profitOfThisYear = Number(target.value);

  updateItemsByProfit(selectedYear, profitOfThisYear);
  changeRangeInputsView(selectedYear);
  changeProfitView(selectedYear);
  setCompareInputs(runningData)
  updateAsideRunning();
}

function handleProfitChange(e) {
  const selectedYear = Number(document.querySelector(".nav-link.active").id.slice(-1));

  const target = e.target;
  let profitOfThisYear = sanitizeMoney(target.value);

  updateItemsByProfit(selectedYear, profitOfThisYear);
  changeRangeInputsView(selectedYear);
  changeProfitView(selectedYear);
  setCompareInputs(runningData)
  updateAsideRunning();
}

function handleProfitSliderBackground(e) {
  // スライドしている間利益を表面上変わっているようにする
  const target = e.target;
  const val = Number(target.value);
  const max = Number(target.max);
  target.defaultValue = val;
  if (val !== 0) {
    target.style.backgroundSize = (val * 100) / max + 1 + "% 100%";
  } else {
    target.style.backgroundSize = "0% 100%";
  }
}

function updateItemsByProfit(selectedYear, value) {
  let profitOfThisYear = value;

  // 利益の入力が月額売上以上だった場合、月額売上を上限として下方修正する
  const monthlyUriage = getMonthlyUriage(selectedYear)
  if (profitOfThisYear > monthlyUriage) profitOfThisYear = monthlyUriage;

  let expense = runningData[selectedYear - 1].reduce((total, item) => {
    return total + item;
  });

  // 月額売り上げが記入されていたら差額の計算をする
  if (uriageSum[selectedYear - 1] !== 0) {
    const isDeficit = expense + profitOfThisYear > monthlyUriage
    // 利益と支出の合計が売り上げを上回ったら支出を減らす
    const diff = Math.abs(expense + profitOfThisYear - monthlyUriage);
    // 支出がゼロの場合、売上−利益分を支出それぞれに分配する
    if (expense == 0) {
      const runningDataSize = runningData[selectedYear - 1].length
      runningData[selectedYear - 1] = runningData[selectedYear - 1].map(() => {
        return parseInt((monthlyUriage - profitOfThisYear) / runningDataSize);
      });
    } else {
      if (isDeficit) {
        runningData[selectedYear - 1] = runningData[selectedYear - 1].map((data) => {
          const ratio = data / expense;
          return parseInt(data - ratio * diff);
        });
      } else {
        runningData[selectedYear - 1] = runningData[selectedYear - 1].map((data) => {
          const ratio = data / expense;
          return parseInt(data + ratio * diff);
        });
      }
    }
    // 支出の計算をした上で再計算
    expense = runningData[selectedYear - 1].reduce((total, item) => {
      return total + item;
    });
    // 少数の切り捨てで誤差を利益を追加する
    const salesDiff = monthlyUriage - (expense + profitOfThisYear);
    profit[selectedYear - 1] = profitOfThisYear + salesDiff;
  }
}

function changeRangeInputsView(selectedYear) {
  try {
    const rangeInputsRunning = document.querySelectorAll(".input-slider--running");
    const dataInputsRunning = document.querySelectorAll(".data-input--running");
    const maxRange = getMonthlyUriage(selectedYear) < 100 ? 100 : getMonthlyUriage(selectedYear)
    runningData[selectedYear - 1].forEach((data, i) => {
      if (data !== 0) {
        rangeInputsRunning[i].max = maxRange
        rangeInputsRunning[i].style.backgroundSize =
          (data * 100) / rangeInputsRunning[i].max + 1 + "% 100%";
        rangeInputsRunning[i].value = data;
        rangeInputsRunning[i].defaultValue = data;
        dataInputsRunning[i].value = data.toLocaleString('ja-JP');
      } else {
        rangeInputsRunning[i].max = maxRange;
        rangeInputsRunning[i].style.backgroundSize = "0% 100%";
        rangeInputsRunning[i].value = data;
        rangeInputsRunning[i].defaultValue = data;
        dataInputsRunning[i].value = data.toLocaleString('ja-JP');
      }
    });
  } catch (error) {
    // console.warn(error);
  }
}

function updateMonthlyProfit(selectedYear) {
  // 月額利益の更新
  if (uriageSum[selectedYear - 1] !== 0) {
    const expense = runningData[selectedYear - 1].reduce((total, item) => {
      return total + item;
    });
    const diff = parseInt(uriageSum[selectedYear - 1] / 12 - expense);
    profit[selectedYear - 1] = diff;
  }
}

function changeProfitView(selectedYear) {
  try {
    const profitSliderInput = document.getElementById("input-slider--profit");
    const profitSumInput = document.getElementById("data-input--profit");

    const maxRange = getMonthlyUriage(selectedYear) < 100 ? 100 : getMonthlyUriage(selectedYear)
    profitSliderInput.max = maxRange
    profitSliderInput.value = profit[selectedYear - 1];
    profitSliderInput.defaultValue = profit[selectedYear - 1];
    if (profitSliderInput.value !== 0) {
      profitSliderInput.style.backgroundSize =
        (profitSliderInput.value * 100) / profitSliderInput.max + 1 + "% 100%";
    } else {
      profitSliderInput.style.backgroundSize = "0% 100%";
    }
    profitSumInput.value = profit[selectedYear - 1].toLocaleString('ja-JP');
  } catch (error) {
    // console.warn(error);
  }
}

function updateAsideRunning() {
  const expense1 = runningData[0].reduce((total, item) => {return total + item;}) * 12;
  const expense2 = runningData[1].reduce((total, item) => {return total + item;}) * 12;
  const expense3 = runningData[2].reduce((total, item) => {return total + item;}) * 12;
  const yearlyProfit1 = profit[0] * 12
  const yearlyProfit2 = profit[1] * 12
  const yearlyProfit3 = profit[2] * 12 
  if (document.getElementById("running-sum1").textContent != uriageSum[0].toString()){
    setWithCountAnimation(document.getElementById("running-sum1"), 0, uriageSum[0]);
  }
  if (document.getElementById("running-sum2").textContent != uriageSum[1].toString()){
    setWithCountAnimation(document.getElementById("running-sum2"), 0, uriageSum[1]);
  }
  if (document.getElementById("running-sum3").textContent != uriageSum[2].toString()){
    setWithCountAnimation(document.getElementById("running-sum3"), 0, uriageSum[2]);
  }
  if (document.getElementById("running-cost1").textContent != expense1.toString()){
    setWithCountAnimation(document.getElementById("running-cost1"), 0, expense1);
  }
  if (document.getElementById("running-cost2").textContent != expense2.toString()){
    setWithCountAnimation(document.getElementById("running-cost2"), 0, expense2);
  }
  if (document.getElementById("running-cost3").textContent != expense3.toString()){
    setWithCountAnimation(document.getElementById("running-cost3"), 0, expense3);
  }
  if (document.getElementById("running-profit1").textContent != yearlyProfit1.toString()){
    setWithCountAnimation(document.getElementById("running-profit1"), 0, yearlyProfit1);
  }
  if (document.getElementById("running-profit2").textContent != yearlyProfit2.toString()){
    setWithCountAnimation(document.getElementById("running-profit2"), 0, yearlyProfit2);
  }
  if (document.getElementById("running-profit3").textContent != yearlyProfit3.toString()){
    setWithCountAnimation(document.getElementById("running-profit3"), 0, yearlyProfit3);
  }

  handleUntenShikinSetSimulationBar(
    uriageSum[0], uriageSum[1], uriageSum[2],
    expense1, expense2, expense3,
    yearlyProfit1, yearlyProfit2, yearlyProfit3
  );
}

export function addSuchiKeikakuEventListenerAll() {
  // 運転資金の年次ごとのタブクリック
  const tabs = document.querySelectorAll(".form-tab");
  tabs.forEach((tab) => {
    tab.addEventListener("click", handleTabChange);
  });
  try {
    // 比較ボタンクリック
    const compareTab = document.getElementById("tab--compare");
    compareTab.addEventListener("click", handleClickCompareTab);
  } catch (error) {
    // console.warn(error);
  }
  // レンジスライダー運転資金
  const rangeInputsRunning = document.querySelectorAll(".input-slider--running");
  rangeInputsRunning.forEach((input) => {
    input.addEventListener("input", handleRangeInputRunningChange);
  });
  // レンジスライダー横のインプット運転資金
  const dataInputsRunning = document.querySelectorAll(".data-input--running");
  dataInputsRunning.forEach((input) => {
    input.addEventListener("change", handleDataInputRunningChange);
  });

  // 月額利益
  try {
    const profitSlider = document.getElementById("input-slider--profit");
    const profitInput = document.getElementById("data-input--profit");
    profitSlider.addEventListener("input", handleProfitSliderBackground);
    profitSlider.addEventListener("change", handleProfitSliderInput);
    profitInput.addEventListener("change", handleProfitChange);
  } catch (error) {
    // console.log(error);
  }

  // 売上の変更イベント
  const sumRunningInputs = document.querySelectorAll(".js-uriage-input");
  sumRunningInputs.forEach((input) => {
    input.addEventListener("change", handleUriageChange);
  });

  // 運転資金比較項目
  const compareInputs = document.querySelectorAll(".js-untenshikin-input");
  const compareProfits = document.querySelectorAll(".js-untenshikin-profit-input");
  compareInputs.forEach((input) => {
    input.addEventListener("change", handleCompareInputChange);
  });
  compareProfits.forEach((input) => {
    input.addEventListener("change", handleCompareProfitChange);
  });
}

export function setInitialValueForUntenShikin(){
  // 売上のセット
  const sumRunningInputs = document.querySelectorAll(".data-sum--running");
  sumRunningInputs.forEach((input, index) => {
    uriageSum[index] = Number(input.value);
  });

  // 運転資金のrunningDataのセット
  const compareInputs = document.querySelectorAll(".data-input--compare");
  compareInputs.forEach((input) => {
    const inputId = input.dataset.itemid;
    const year = input.dataset.year;
    runningData[year - 1][inputId - 1] = sanitizeMoney(input.value);
  });

  // 月額利益のセット
  [1, 2, 3].forEach((tab)=> {
    updateMonthlyProfit(tab);
  });

  const selectedYear = 1;
  changeRangeInputsView(selectedYear);
  changeProfitView(selectedYear);
  updateAsideRunning();
}

export function hideYakuinHoshu() {
  const businessType = $('input[name="founding_template[business_type]"]:checked').val();
  if(businessType !== "hojin") {
    const yakuinhoshu1y = document.querySelector('input[name="founding_template[yakuinhoshu_1y]');
    const yakuinhoshu2y = document.querySelector('input[name="founding_template[yakuinhoshu_2y]');
    const yakuinhoshu3y = document.querySelector('input[name="founding_template[yakuinhoshu_3y]');
    yakuinhoshu1y.value = 0;
    yakuinhoshu2y.value = 0;
    yakuinhoshu3y.value = 0;
    // 役員報酬額をゼロにする
    [yakuinhoshu1y, yakuinhoshu2y, yakuinhoshu3y].forEach((target) => {
      const inputId = target.dataset.itemid;
      const selectedYear = target.dataset.year;
      runningData[selectedYear - 1][inputId - 1] = parseInt(target.value);
      updateMonthlyProfit(selectedYear)
    });

    // サマリー部分の再セット
    var selectedYear = 0;
    if (document.querySelector(".nav-link.active")) selectedYear = Number(document.querySelector(".nav-link.active").id.slice(-1));
    if (selectedYear !== 0) {
      changeRangeInputsView(selectedYear);
      changeProfitView(selectedYear);
    }
    updateAsideRunning();
    $(".js-yakuinHoshu").addClass("d-none");
  } else {
    $(".js-yakuinHoshu").removeClass("d-none");
  }
}

$(function() {
  if (document.getElementById('startCalculationButton') == null && 
      document.getElementById('untenshikin_title_section')) {
    setInitialValueForUntenShikin();
    addSuchiKeikakuEventListenerAll();
    // 事業形態が個人の場合は役員報酬を隠す
    hideYakuinHoshu();
    $('input[name="founding_template[business_type]"]').on('change', function() {
      hideYakuinHoshu();
    });
  }
});