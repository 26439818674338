import {addSuchiKeikakuEventListenerAll, setInitialValueForUntenShikin, hideYakuinHoshu, handleSetCompareInputs} from "./untenShikinSlider"
import {setInitialValueForKaigyoShikin, addKaigyoShikinEventListenerAll } from "./kaigyoShikinSlider"

$(function() {
  $(document).on('click', '.js_automatic-calculation', function() {
    const target = $(this).data('target');
    const suchikeikakuIndustryId = $('#modal-select-hidden').val();
    const businessType = $('input[name="founding_template[business_type]"]:checked').val();
    const jikoshikin = $('#jikoshikin').val();
    const kariire = $('#kariire').val();
    const kaigyoshikinSonota = $('#kaigyoshikin_sonota').val();
    const uriage1y = $('#sales1').val();
    const uriage2y = $('#sales2').val();
    const uriage3y = $('#sales3').val();

    if (!automaticCalculationValidate(true)) {
      return false;
    }

    let send_data = {}

    if (target === 'all') {
      send_data = {
        'target': target,
        'founding_template[suchikeikaku_industry_id]': suchikeikakuIndustryId, // 業種
        'founding_template[business_type]': businessType,                      // 事業形態
        'founding_template[jikoshikin]': jikoshikin,                           // 開業資金 自己資金
        'founding_template[kariire]': kariire,                                 // 開業資金 借入
        'founding_template[kaigyoshikin_sonota]': kaigyoshikinSonota,          // 開業資金 その他
        'founding_template[uriage_1y]': uriage1y,                              // 売上 1年目
        'founding_template[uriage_2y]': uriage1y,                              // 売上 2年目に売上1年目を使用
        'founding_template[uriage_3y]': uriage1y,                              // 売上 3年目に売上1年目を使用
      }
      // 売上2年目、3年目に1年目の値をセット
      $('#sales2').val(uriage1y);
      $('#sales3').val(uriage1y);
    } else if (target === 'kaigyohiyo') {
      send_data = {
        'target': target,
        'founding_template[suchikeikaku_industry_id]': suchikeikakuIndustryId, // 業種
        'founding_template[jikoshikin]': jikoshikin,                           // 開業資金 自己資金
        'founding_template[kariire]': kariire,                                 // 開業資金 借入
        'founding_template[kaigyoshikin_sonota]': kaigyoshikinSonota,          // 開業資金 その他
      }
    } else if (target === 'untenshikin') {
      send_data = {
        'target': target,
        'founding_template[suchikeikaku_industry_id]': suchikeikakuIndustryId, // 業種
        'founding_template[business_type]': businessType,                      // 事業形態
        'founding_template[uriage_1y]': uriage1y,                              // 売上 1年目
        'founding_template[uriage_2y]': uriage2y,                              // 売上 2年目
        'founding_template[uriage_3y]': uriage3y,                              // 売上 3年目
      }
    }

    $.ajax({
      type: "POST",
      url: "/plan/founding_templates/automatic_calculation",
      data: send_data
    }).done(function(data, textStatus, jqXHR) {
      setInitialValueForKaigyoShikin();
      setInitialValueForUntenShikin();
      addKaigyoShikinEventListenerAll();
      addSuchiKeikakuEventListenerAll();
      hideYakuinHoshu();
      addEventListenerToTooltips(target);
      if (target === 'all') {
        $("#step2").addClass("main-stepper__item--active")
        $("#step2").removeClass("main-stepper__item")
        $('#stepper_arrow').addClass("main-stepper__arrow--active");
        $('#stepper_arrow').removeClass("main-stepper__arrow")
        // サマリー部分を入れ替え
        $("#initSuuchiSummary").addClass("d-none");
        $("#suuchiSummary").removeClass("d-none");
        // 売上部分のtext fieldに値を反映
        [$("#sales1"), $("#sales2"), $("#sales3")].forEach((item) => {
          setDelimitedMoney(item[0]); // DOM要素を渡すためにjQueryオブジェクトの0番目を指定
        });
        // 役員報酬の表示をスイッチするイベントを付与
        $('input[name="founding_template[business_type]"]').on('change', function() {
          hideYakuinHoshu();
        });
        // フロー下のテキストを入れ替え
        $('#step1_description').addClass("d-none");
        $('#step2_description').removeClass("d-none");
      }
      toggleAutoCalcButtonAndTooltip();
    }).fail(function(jqXHR, textStatus, errorThrown) {
    });
  });
});

// 自動計算実行確認モーダル
import { Modal } from 'bootstrap'
$(function() {
  const modalSelector = "#auto-calculate";
  const modalCalcTargetStrSelector = "#auto-calculate .js-calc_target_str";
  const modalCalcTargetSelector = "#auto-calculate .js_automatic-calculation";
  var myModal;

  if ($(modalSelector).length) {
    myModal = new Modal($(modalSelector), {});
  }

  $(document).on('click', '.js-founding_template_auto_calculate_button', function() {
    var targetStr = $(this).data('founding-template-auto-calculate-target-str');
    var target = $(this).data('founding-template-auto-calculate-target');

    $(modalCalcTargetStrSelector).text(targetStr);
    $(modalCalcTargetSelector).data('target', target);

    myModal.show();
  });
});

import { Tooltip } from 'bootstrap'
import { setDelimitedMoney } from "./suchiUtil";
// ツールチップへのイベントリスナー再付与
function addEventListenerToTooltips(target){
  const kaigyoHiyoTooltip = "#kaigyoHiyoTooltip"
  const untenShikinTooltip = "#untenShikinTooltip"
  const jinkenhiTooltip = "#jinkenhiTooltip"
  if (target === "untenshikin") {
    new Tooltip($(jinkenhiTooltip))
  } else {
    new Tooltip($(kaigyoHiyoTooltip))
    new Tooltip($(untenShikinTooltip))
    new Tooltip($(jinkenhiTooltip))
  }
}

$(function() {
  toggleAutoCalcButtonAndTooltip();
});
// 業種その他の場合に自動計算のボタンを表示・非表示を設定
export function toggleAutoCalcButtonAndTooltip() {
  if ($('input[name="founding_template[suchikeikaku_industry_id]"]')) {
    if ($('input[name="founding_template[suchikeikaku_industry_id]"]').val() === "13") {
      $("#unten-shikin-auto-calc-button").hide();
      $("#kaigyo-hiyo-auto-calc-button").hide();
      $("#kaigyo-hiyo-auto-calc-tooltip").hide();
      $("#unten-shikin-auto-calc-tooltip").hide();
    } else {
      $("#unten-shikin-auto-calc-button").show();
      $("#kaigyo-hiyo-auto-calc-button").show();
      $("#kaigyo-hiyo-auto-calc-tooltip").show();
      $("#unten-shikin-auto-calc-tooltip").show();
    }
  }
}

// 自動計算バリデーション
export function automaticCalculationValidate(isScroll = false, item = null) {
  let isValid = true;

  const scrollOffset = 20;

  // 業種
  if (item == null || item == '#modal-select-hidden') {
    if ($("#modal-select-hidden").val() === "") {
      isValid = false;
      $('.main-form__select-button').addClass('is-invalid');
      $('.suchikeikaku-industry-invalid-feedback').addClass('d-block');
      if (isScroll) {
        $(window).scrollTop($('.main-form__select-button').offset().top - scrollOffset);
        isScroll = false;
      }
    } else {
      $('.main-form__select-button').removeClass('is-invalid');
      $('.suchikeikaku-industry-invalid-feedback').removeClass('d-block');
    }
  }

  // 事業形態
  if (item == null || item == 'input[name="founding_template[business_type]"]') {
    if ($('input[name="founding_template[business_type]"]:checked').length === 0) {
      isValid = false;
      $('input[name="founding_template[business_type]"]').addClass('is-invalid');
      $('.business-type-invalid-feedback').addClass('d-block');
      if (isScroll) {
        $(window).scrollTop($('#business_type1').offset().top - scrollOffset);
        isScroll = false;
      }
    } else {
      $('input[name="founding_template[business_type]"]').removeClass('is-invalid');
      $('.business-type-invalid-feedback').removeClass('d-block');
    }
  }

  // 自己資金
  if (item == null || item == '#text_jikoshikin') {
    if ($("#text_jikoshikin").val() === "") {
      isValid = false;
      $('#text_jikoshikin').addClass('is-invalid');
      $('.jikoshikin-invalid-feedback').addClass('d-block');
      if (isScroll) {
        $(window).scrollTop($('#text_jikoshikin').offset().top - scrollOffset);
        isScroll = false;
      }
    } else {
      $('#text_jikoshikin').removeClass('is-invalid');
      $('.jikoshikin-invalid-feedback').removeClass('d-block');
    }
  }

  // 借入
  if (item == null || item == '#text_kariire') {
    if ($("#text_kariire").val() === "") {
      isValid = false;
      $('#text_kariire').addClass('is-invalid');
      $('.kariire-invalid-feedback').addClass('d-block');
      if (isScroll) {
        $(window).scrollTop($('#text_kariire').offset().top - scrollOffset);
        isScroll = false;
      }
    } else {
      $('#text_kariire').removeClass('is-invalid');
      $('.kariire-invalid-feedback').removeClass('d-block');
    }
  }

  // その他
  if (item == null || item == '#text_kaigyoshikin_sonota') {
    if ($("#text_kaigyoshikin_sonota").val() === "") {
      isValid = false;
      $('#text_kaigyoshikin_sonota').addClass('is-invalid');
      $('.kaigyoshikin-sonota-invalid-feedback').addClass('d-block');
      if (isScroll) {
        $(window).scrollTop($('#text_kaigyoshikin_sonota').offset().top - scrollOffset);
        isScroll = false;
      }
    } else {
      $('#text_kaigyoshikin_sonota').removeClass('is-invalid');
      $('.kaigyoshikin-sonota-invalid-feedback').removeClass('d-block');
    }
  }

  // 売上1年目
  if (item == null || item == '#text_sales1') {
    if ($("#text_sales1").val() === "") {
      isValid = false;
      $('#text_sales1').addClass('is-invalid');
      $('.sales1-invalid-feedback').addClass('d-block');
      if (isScroll) {
        $(window).scrollTop($('#text_sales1').offset().top - scrollOffset);
        isScroll = false;
      }
    } else {
      $('#text_sales1').removeClass('is-invalid');
      $('.sales1-invalid-feedback').removeClass('d-block');
    }
  }

  return isValid;
}

// 結果ページサイドバナー表示、非表示
$(function() {
  if ($('.founding-templates-aside').length) {
    const offset = 24;

    $(window).scroll(function() {
      const jfcTemplatesBannerTop = $('#jfc_templates_banner').offset().top;
      const jfcTemplatesBannerHeight = $('#jfc_templates_banner').height();
      const jfcTemplatesBannerBottom = jfcTemplatesBannerTop + jfcTemplatesBannerHeight;

      const jfcTemplatesBannerDisplayEndPointTop = $('#jfc_templates_banner_display_end_point').offset().top;

      if (jfcTemplatesBannerDisplayEndPointTop + offset <= jfcTemplatesBannerBottom) {
        $('#jfc_templates_banner').addClass('hidden');
      } else {
        $('#jfc_templates_banner').removeClass('hidden');
      }
    });
  }
});

$(function() {
  $(".js-show_form_suuchi_initial_menu_sp").on("click", function() {
    $(".js-form_suuchi_category_menu_sp").removeClass("d-none");
    $(".js-form_suuchi_main_sp").addClass("d-none");
  });
  $(".js-hide_form_suuchi_initial_menu_sp").on("click", function() {
    $(".js-form_suuchi_category_menu_sp").addClass("d-none");
    $(".js-form_suuchi_main_sp").removeClass("d-none");
  });

  const reCalcModal = document.getElementById("re-calc-sp");
  if (reCalcModal) {
    reCalcModal.addEventListener("show.bs.modal", event => {
      const button = event.relatedTarget;

      const reCalcTargetStr = button.getAttribute("data-bs-re-calc-target-str");
      const destElements = reCalcModal.getElementsByClassName("js-re-calc-target-str");
      for (let i = 0; i < destElements.length; i++) {
        destElements[i].textContent = reCalcTargetStr;
      }

      const reCalcTargetIdentifier = button.getAttribute("data-bs-re-calc-target-identifier");
      const reCalcModalSubmitButton = reCalcModal.querySelector("#re_calc_modal_submit_button");
      reCalcModalSubmitButton.setAttribute("value", reCalcTargetIdentifier);
    });
  }

  if ($("#form_suuchi_step1_sp").length) {
    $("#startCalculationButton").on("click", function() {
      if (!automaticCalculationValidate(true)) {
        return false;
      }
    });
  }

  if ($("#form_suuchi_step2_sp").length) {
    setInitialValueForKaigyoShikin();
    setInitialValueForUntenShikin();
    addKaigyoShikinEventListenerAll();
    addSuchiKeikakuEventListenerAll();
    hideYakuinHoshu();

    $('input[name="founding_template[business_type]"]').on('change', function() {
      hideYakuinHoshu();
      handleSetCompareInputs();
    });
  }
});
