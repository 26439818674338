const categoryInput = document.getElementById("ls-category");
const heading = document.getElementById("ls-resultHeading");
const noResultHeading = document.getElementById("ls-noResultHeading");

function getQueryParam(param) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
}

function updateHeading() {
  const category = getQueryParam("sc[category]") || "";
  const tag = getQueryParam("sc[tag]") || "";
  const keyword = getQueryParam("sc[keyword]") || "";

  const decodedCategory = decodeURIComponent(category);
  const decodedTag = decodeURIComponent(tag);
  const decodedKeyword = decodeURIComponent(keyword);

  let headingText = "";
  let noResultHeadingText = "キーワードの検索結果";
  if (decodedCategory) headingText += `${decodedCategory} `;
  if (decodedTag) headingText += `${decodedTag} `;
  if (decodedKeyword) {
    headingText += `${decodedKeyword} `;
    noResultHeadingText = `${decodedKeyword}の検索結果`;
  }
  headingText += "の検索結果";

  if (heading) {
    heading.textContent = headingText.trim();
  }
  if (noResultHeading) {
    noResultHeading.textContent = noResultHeadingText;
  }

  updateCategoryButtonText(decodedCategory);
  updateKeywordInput(decodedKeyword);
}

function updateCategoryButtonText(category) {
  const categoryButton = document.getElementById("ls-categoryButton");
  const categoryButtonSp = document.getElementById("ls-categoryButtonSp");
  if (categoryButton) {
    const categoryText = categoryButton.querySelector(
      ".ls-categoryButton__text"
    );
    if (categoryText) {
      categoryText.textContent = category || "すべて";
    }
    categoryInput.value = category;
  }
  if (categoryButtonSp) {
    const categoryText = categoryButtonSp.querySelector(
      ".ls-categoryButton__text"
    );
    if (categoryText) {
      categoryText.textContent = category || "カテゴリ選択";
    }
    categoryInput.value = category;
  }
}

function updateKeywordInput(keyword) {
  const keywordInput = document.getElementById("ls-keywords");
  const keywordInputSp = document.getElementById("ls-keywords-sp");
  const keywordHidden = document.getElementById("ls-keyword");
  if (keywordInput) {
    keywordInput.value = keyword;
  }
  if (keywordInputSp) {
    keywordInputSp.value = keyword;
  }
  if (keywordHidden) {
    keywordHidden.value = keyword;
  }
}

// document.addEventListener("DOMContentLoaded", updateHeading);
