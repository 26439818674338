// モーダル。'jquery-3.4.1.min.js'が必要。
import { Modal } from "bootstrap";
import { calcSection7 } from "./sogyo-keikaku";

// 創業数値計画選択一覧モーダル
$(function () {
  const modalSelector = "#new";
  const modalContentTargetSelector = "#new #modal-content-target";
  const url = "/plan/founding_templates/select_list";
  var myModal;

  if ($(modalSelector).length) {
    myModal = new Modal($(modalSelector), {});
  }

  $(".js-open_founding_template_select_list_modal").on("click", function () {
    $.get(url, function (data) {
      $(modalContentTargetSelector).html(data);
      myModal.show();
    });
  });

  $(".js-open_founding_template_select_list_modal_sp").on("click", function () {
    $.get(url, { sp: 'true' }, function (data) {
      $(modalContentTargetSelector).html(data);
      myModal.show();
    });
  });
});
// 創業数値計画反映確認モーダル
$(function () {
  const modalSelector = "#reflect";
  const modalContentTargetSelector = "#reflect #modal-content-target";
  var myModal;

  if ($(modalSelector).length) {
    myModal = new Modal($(modalSelector), {});
  }

  $(document).on(
    "click",
    ".js-open_founding_template_reflect_modal",
    function () {
      var selectedFoundingTemplate = $(this).data("selected-founding-template");

      $(modalContentTargetSelector).data(
        "selected-founding-template",
        selectedFoundingTemplate
      );

      myModal.show();
    }
  );
});

// 創業数値計画反映
$(function () {
  $(".js-founding_template_reflect").on("click", function () {
    const selectedFoundingTemplate = $(this).data("selected-founding-template");
    const selectedFoundingTemplateJson = JSON.parse(
      document.getElementById(selectedFoundingTemplate).text
    );

    $(".js-founding_template_reflect_target_wrapper")
      .find("input, textarea")
      .val("");

    // 設備資金
    const jfc_template_setsubishikin_gaiyo_1 =
      selectedFoundingTemplateJson.jfc_template_setsubishikin_gaiyo_1;
    if (jfc_template_setsubishikin_gaiyo_1 != null) {
      $("#jfc_template_setsubishikin_gaiyo_1").val(
        jfc_template_setsubishikin_gaiyo_1
      );
    }

    const jfc_template_setsubishikin_kingaku_1 =
      selectedFoundingTemplateJson.jfc_template_setsubishikin_kingaku_1;
    if (jfc_template_setsubishikin_kingaku_1 != null) {
      $("#setsubishikin_kingaku_1").val(jfc_template_setsubishikin_kingaku_1);
      $("#text_setsubishikin_kingaku_1").val(
        jfc_template_setsubishikin_kingaku_1.toLocaleString("ja-JP")
      );
    }

    const jfc_template_setsubishikin_gaiyo_2 =
      selectedFoundingTemplateJson.jfc_template_setsubishikin_gaiyo_2;
    if (jfc_template_setsubishikin_gaiyo_2 != null) {
      $("#jfc_template_setsubishikin_gaiyo_2").val(
        jfc_template_setsubishikin_gaiyo_2
      );
    }

    const jfc_template_setsubishikin_kingaku_2 =
      selectedFoundingTemplateJson.jfc_template_setsubishikin_kingaku_2;
    if (jfc_template_setsubishikin_kingaku_2 != null) {
      $("#setsubishikin_kingaku_2").val(jfc_template_setsubishikin_kingaku_2);
      $("#text_setsubishikin_kingaku_2").val(
        jfc_template_setsubishikin_kingaku_2.toLocaleString("ja-JP")
      );
    }

    const jfc_template_setsubishikin_gaiyo_3 =
      selectedFoundingTemplateJson.jfc_template_setsubishikin_gaiyo_3;
    if (jfc_template_setsubishikin_gaiyo_3 != null) {
      $("#jfc_template_setsubishikin_gaiyo_3").val(
        jfc_template_setsubishikin_gaiyo_3
      );
    }

    const jfc_template_setsubishikin_kingaku_3 =
      selectedFoundingTemplateJson.jfc_template_setsubishikin_kingaku_3;
    if (jfc_template_setsubishikin_kingaku_3 != null) {
      $("#setsubishikin_kingaku_3").val(jfc_template_setsubishikin_kingaku_3);
      $("#text_setsubishikin_kingaku_3").val(
        jfc_template_setsubishikin_kingaku_3.toLocaleString("ja-JP")
      );
    }

    const jfc_template_setsubishikin_gaiyo_4 =
      selectedFoundingTemplateJson.jfc_template_setsubishikin_gaiyo_4;
    if (jfc_template_setsubishikin_gaiyo_4 != null) {
      $("#jfc_template_setsubishikin_gaiyo_4").val(
        jfc_template_setsubishikin_gaiyo_4
      );
    }

    const jfc_template_setsubishikin_kingaku_4 =
      selectedFoundingTemplateJson.jfc_template_setsubishikin_kingaku_4;
    if (jfc_template_setsubishikin_kingaku_4 != null) {
      $("#setsubishikin_kingaku_4").val(jfc_template_setsubishikin_kingaku_4);
      $("#text_setsubishikin_kingaku_4").val(
        jfc_template_setsubishikin_kingaku_4.toLocaleString("ja-JP")
      );
    }

    const jfc_template_setsubishikin_gaiyo_5 =
      selectedFoundingTemplateJson.jfc_template_setsubishikin_gaiyo_5;
    if (jfc_template_setsubishikin_gaiyo_5 != null) {
      $("#jfc_template_setsubishikin_gaiyo_5").val(
        jfc_template_setsubishikin_gaiyo_5
      );
    }

    const jfc_template_setsubishikin_kingaku_5 =
      selectedFoundingTemplateJson.jfc_template_setsubishikin_kingaku_5;
    if (jfc_template_setsubishikin_kingaku_5 != null) {
      $("#setsubishikin_kingaku_5").val(jfc_template_setsubishikin_kingaku_5);
      $("#text_setsubishikin_kingaku_5").val(
        jfc_template_setsubishikin_kingaku_5.toLocaleString("ja-JP")
      );
    }

    const jfc_template_setsubishikin_gaiyo_6 =
      selectedFoundingTemplateJson.jfc_template_setsubishikin_gaiyo_6;
    if (jfc_template_setsubishikin_gaiyo_6 != null) {
      $("#jfc_template_setsubishikin_gaiyo_6").val(
        jfc_template_setsubishikin_gaiyo_6
      );
    }

    const jfc_template_setsubishikin_kingaku_6 =
      selectedFoundingTemplateJson.jfc_template_setsubishikin_kingaku_6;
    if (jfc_template_setsubishikin_kingaku_6 != null) {
      $("#setsubishikin_kingaku_6").val(jfc_template_setsubishikin_kingaku_6);
      $("#text_setsubishikin_kingaku_6").val(
        jfc_template_setsubishikin_kingaku_6.toLocaleString("ja-JP")
      );
    }

    const jfc_template_setsubishikin_gaiyo_7 =
      selectedFoundingTemplateJson.jfc_template_setsubishikin_gaiyo_7;
    if (jfc_template_setsubishikin_gaiyo_7 != null) {
      $("#jfc_template_setsubishikin_gaiyo_7").val(
        jfc_template_setsubishikin_gaiyo_7
      );
    }

    const jfc_template_setsubishikin_kingaku_7 =
      selectedFoundingTemplateJson.jfc_template_setsubishikin_kingaku_7;
    if (jfc_template_setsubishikin_kingaku_7 != null) {
      $("#setsubishikin_kingaku_7").val(jfc_template_setsubishikin_kingaku_7);
      $("#text_setsubishikin_kingaku_7").val(
        jfc_template_setsubishikin_kingaku_7.toLocaleString("ja-JP")
      );
    }

    // 運転資金
    const jfc_template_untenshikin_gaiyo_1 =
      selectedFoundingTemplateJson.jfc_template_untenshikin_gaiyo_1;
    if (jfc_template_untenshikin_gaiyo_1 != null) {
      $("#jfc_template_untenshikin_gaiyo_1").val(
        jfc_template_untenshikin_gaiyo_1
      );
    }

    const jfc_template_untenshikin_kingaku_1 =
      selectedFoundingTemplateJson.jfc_template_untenshikin_kingaku_1;
    if (jfc_template_untenshikin_kingaku_1 != null) {
      $("#untenshikin_kingaku_1").val(jfc_template_untenshikin_kingaku_1);
      $("#text_untenshikin_kingaku_1").val(
        jfc_template_untenshikin_kingaku_1.toLocaleString("ja-JP")
      );
    }

    const jfc_template_untenshikin_gaiyo_2 =
      selectedFoundingTemplateJson.jfc_template_untenshikin_gaiyo_2;
    if (jfc_template_untenshikin_gaiyo_2 != null) {
      $("#jfc_template_untenshikin_gaiyo_2").val(
        jfc_template_untenshikin_gaiyo_2
      );
    }

    const jfc_template_untenshikin_kingaku_2 =
      selectedFoundingTemplateJson.jfc_template_untenshikin_kingaku_2;
    if (jfc_template_untenshikin_kingaku_2 != null) {
      $("#untenshikin_kingaku_2").val(jfc_template_untenshikin_kingaku_2);
      $("#text_untenshikin_kingaku_2").val(
        jfc_template_untenshikin_kingaku_2.toLocaleString("ja-JP")
      );
    }

    const jfc_template_untenshikin_gaiyo_3 =
      selectedFoundingTemplateJson.jfc_template_untenshikin_gaiyo_3;
    if (jfc_template_untenshikin_gaiyo_3 != null) {
      $("#jfc_template_untenshikin_gaiyo_3").val(
        jfc_template_untenshikin_gaiyo_3
      );
    }

    const jfc_template_untenshikin_kingaku_3 =
      selectedFoundingTemplateJson.jfc_template_untenshikin_kingaku_3;
    if (jfc_template_untenshikin_kingaku_3 != null) {
      $("#untenshikin_kingaku_3").val(jfc_template_untenshikin_kingaku_3);
      $("#text_untenshikin_kingaku_3").val(
        jfc_template_untenshikin_kingaku_3.toLocaleString("ja-JP")
      );
    }

    const jfc_template_untenshikin_gaiyo_4 =
      selectedFoundingTemplateJson.jfc_template_untenshikin_gaiyo_4;
    if (jfc_template_untenshikin_gaiyo_4 != null) {
      $("#jfc_template_untenshikin_gaiyo_4").val(
        jfc_template_untenshikin_gaiyo_4
      );
    }

    const jfc_template_untenshikin_kingaku_4 =
      selectedFoundingTemplateJson.jfc_template_untenshikin_kingaku_4;
    if (jfc_template_untenshikin_kingaku_4 != null) {
      $("#untenshikin_kingaku_4").val(jfc_template_untenshikin_kingaku_4);
      $("#text_untenshikin_kingaku_4").val(
        jfc_template_untenshikin_kingaku_4.toLocaleString("ja-JP")
      );
    }

    // 自己資金
    const jfc_template_jikoshikin =
      selectedFoundingTemplateJson.jfc_template_jikoshikin;
    if (jfc_template_jikoshikin != null) {
      $("#jikoshikin").val(jfc_template_jikoshikin);
      $("#text_jikoshikin").val(
        jfc_template_jikoshikin.toLocaleString("ja-JP")
      );
    }

    // 親、兄弟、知人、友人等からの借入 金額
    const jfc_template_shinzoku_kariirekin =
      selectedFoundingTemplateJson.jfc_template_shinzoku_kariirekin;
    if (jfc_template_shinzoku_kariirekin != null) {
      $("#shinzoku_kariirekin").val(jfc_template_shinzoku_kariirekin);
      $("#text_shinzoku_kariirekin").val(
        jfc_template_shinzoku_kariirekin.toLocaleString("ja-JP")
      );
    }

    // 日本政策金融公庫 国民生活事業からの借入 金額
    const jfc_template_koko_kariirekin =
      selectedFoundingTemplateJson.jfc_template_koko_kariirekin;
    if (jfc_template_koko_kariirekin != null) {
      $("#koko_kariirekin").val(jfc_template_koko_kariirekin);
      $("#text_koko_kariirekin").val(
        jfc_template_koko_kariirekin.toLocaleString("ja-JP")
      );
    }

    // 売上高 創業当初
    const jfc_template_tosho_uriagedaka =
      selectedFoundingTemplateJson.jfc_template_tosho_uriagedaka;
    if (jfc_template_tosho_uriagedaka != null) {
      $("#tosho_uriagedaka").val(jfc_template_tosho_uriagedaka);
      $("#text_tosho_uriagedaka").val(
        jfc_template_tosho_uriagedaka.toLocaleString("ja-JP")
      );
    }

    // 売上高 軌道に乗った後
    const jfc_template_kidogo_uriagedaka =
      selectedFoundingTemplateJson.jfc_template_kidogo_uriagedaka;
    if (jfc_template_kidogo_uriagedaka != null) {
      $("#kidogo_uriagedaka").val(jfc_template_kidogo_uriagedaka);
      $("#text_kidogo_uriagedaka").val(
        jfc_template_kidogo_uriagedaka.toLocaleString("ja-JP")
      );
    }

    // 売上原価(仕入高) 創業当初
    const jfc_template_tosho_uriagegenka =
      selectedFoundingTemplateJson.jfc_template_tosho_uriagegenka;
    if (jfc_template_tosho_uriagegenka != null) {
      $("#tosho_uriagegenka").val(jfc_template_tosho_uriagegenka);
      $("#text_tosho_uriagegenka").val(
        jfc_template_tosho_uriagegenka.toLocaleString("ja-JP")
      );
    }

    // 売上原価(仕入高) 軌道に乗った後
    const jfc_template_kidogo_uriagegenka =
      selectedFoundingTemplateJson.jfc_template_kidogo_uriagegenka;
    if (jfc_template_kidogo_uriagegenka != null) {
      $("#kidogo_uriagegenka").val(jfc_template_kidogo_uriagegenka);
      $("#text_kidogo_uriagegenka").val(
        jfc_template_kidogo_uriagegenka.toLocaleString("ja-JP")
      );
    }

    // 人件費 創業当初
    const jfc_template_tosho_jinkenhi =
      selectedFoundingTemplateJson.jfc_template_tosho_jinkenhi;
    if (jfc_template_tosho_jinkenhi != null) {
      $("#tosho_jinkenhi").val(jfc_template_tosho_jinkenhi);
      $("#text_tosho_jinkenhi").val(
        jfc_template_tosho_jinkenhi.toLocaleString("ja-JP")
      );
    }

    // 人件費 軌道に乗った後
    const jfc_template_kidogo_jinkenhi =
      selectedFoundingTemplateJson.jfc_template_kidogo_jinkenhi;
    if (jfc_template_kidogo_jinkenhi != null) {
      $("#kidogo_jinkenhi").val(jfc_template_kidogo_jinkenhi);
      $("#text_kidogo_jinkenhi").val(
        jfc_template_kidogo_jinkenhi.toLocaleString("ja-JP")
      );
    }

    // 家賃 創業当初
    const jfc_template_tosho_yachin =
      selectedFoundingTemplateJson.jfc_template_tosho_yachin;
    if (jfc_template_tosho_yachin != null) {
      $("#tosho_yachin").val(jfc_template_tosho_yachin);
      $("#text_tosho_yachin").val(
        jfc_template_tosho_yachin.toLocaleString("ja-JP")
      );
    }

    // 家賃 軌道に乗った後
    const jfc_template_kidogo_yachin =
      selectedFoundingTemplateJson.jfc_template_kidogo_yachin;
    if (jfc_template_kidogo_yachin != null) {
      $("#kidogo_yachin").val(jfc_template_kidogo_yachin);
      $("#text_kidogo_yachin").val(
        jfc_template_kidogo_yachin.toLocaleString("ja-JP")
      );
    }

    // 支払利息 創業当初
    const jfc_template_tosho_risoku =
      selectedFoundingTemplateJson.jfc_template_tosho_risoku;
    if (jfc_template_tosho_risoku != null) {
      $("#tosho_risoku").val(jfc_template_tosho_risoku);
      $("#text_tosho_risoku").val(
        jfc_template_tosho_risoku.toLocaleString("ja-JP")
      );
    }

    // 支払利息 軌道に乗った後
    const jfc_template_kidogo_risoku =
      selectedFoundingTemplateJson.jfc_template_kidogo_risoku;
    if (jfc_template_kidogo_risoku != null) {
      $("#kidogo_risoku").val(jfc_template_kidogo_risoku);
      $("#text_kidogo_risoku").val(
        jfc_template_kidogo_risoku.toLocaleString("ja-JP")
      );
    }

    // その他 創業当初
    const jfc_template_tosho_sonota =
      selectedFoundingTemplateJson.jfc_template_tosho_sonota;
    if (jfc_template_tosho_sonota != null) {
      $("#tosho_sonota").val(jfc_template_tosho_sonota);
      $("#text_tosho_sonota").val(
        jfc_template_tosho_sonota.toLocaleString("ja-JP")
      );
    }

    // その他 軌道に乗った後
    const jfc_template_kidogo_sonota =
      selectedFoundingTemplateJson.jfc_template_kidogo_sonota;
    if (jfc_template_kidogo_sonota != null) {
      $("#kidogo_sonota").val(jfc_template_kidogo_sonota);
      $("#text_kidogo_sonota").val(
        jfc_template_kidogo_sonota.toLocaleString("ja-JP")
      );
    }

    // 項目7 必要な資金と調達方法の合計値計算
    calcSection7();

    // モバイル画面の入力状態表示を変更
    $('#founding_template_reflected').trigger('click');
  });
});

// 隠していたフォームの表示
$(function () {
  $(".js-show-additional-form").on("click", function () {
    var targetId = $(this).data("target");
    // 子要素で隠れているものを全表示
    $("#" + targetId)
      .children()
      .each(function () {
        $(this).removeClass("d-none");
      });
    // ボタン要素を非表示
    $(this).addClass("d-none");
  });
});

// フローティングメニュー
$(function () {
  if ($(".jfc-templates-aside").length) {
    const thresholdOffset = 80;

    $(window).scroll(function () {
      const sectionBasicTop = $("#section_basic").offset().top;
      const section1Top = $("#section_1").offset().top;
      const section2Top = $("#section_2").offset().top;
      const section3Top = $("#section_3").offset().top;
      const section4Top = $("#section_4").offset().top;
      const section5Top = $("#section_5").offset().top;
      const section6Top = $("#section_6").offset().top;
      const section7Top = $("#section_7").offset().top;
      const section8Top = $("#section_8").offset().top;
      const section9Top = $("#section_9").offset().top;

      const scroll = $(window).scrollTop();

      const sectionThreshold = scroll + thresholdOffset;

      if (
        sectionBasicTop <= sectionThreshold &&
        sectionThreshold < section1Top
      ) {
        $(".jfc-templates-aside__menu-item").removeClass(
          "jfc-templates-aside__menu-item__active"
        );
        $("a[href='#section_basic'].jfc-templates-aside__menu-item").addClass(
          "jfc-templates-aside__menu-item__active"
        );
      } else if (
        section1Top <= sectionThreshold &&
        sectionThreshold < section2Top
      ) {
        $(".jfc-templates-aside__menu-item").removeClass(
          "jfc-templates-aside__menu-item__active"
        );
        $("a[href='#section_1'].jfc-templates-aside__menu-item").addClass(
          "jfc-templates-aside__menu-item__active"
        );
      } else if (
        section2Top <= sectionThreshold &&
        sectionThreshold < section3Top
      ) {
        $(".jfc-templates-aside__menu-item").removeClass(
          "jfc-templates-aside__menu-item__active"
        );
        $("a[href='#section_2'].jfc-templates-aside__menu-item").addClass(
          "jfc-templates-aside__menu-item__active"
        );
      } else if (
        section3Top <= sectionThreshold &&
        sectionThreshold < section4Top
      ) {
        $(".jfc-templates-aside__menu-item").removeClass(
          "jfc-templates-aside__menu-item__active"
        );
        $("a[href='#section_3'].jfc-templates-aside__menu-item").addClass(
          "jfc-templates-aside__menu-item__active"
        );
      } else if (
        section4Top <= sectionThreshold &&
        sectionThreshold < section5Top
      ) {
        $(".jfc-templates-aside__menu-item").removeClass(
          "jfc-templates-aside__menu-item__active"
        );
        $("a[href='#section_4'].jfc-templates-aside__menu-item").addClass(
          "jfc-templates-aside__menu-item__active"
        );
      } else if (
        section5Top <= sectionThreshold &&
        sectionThreshold < section6Top
      ) {
        $(".jfc-templates-aside__menu-item").removeClass(
          "jfc-templates-aside__menu-item__active"
        );
        $("a[href='#section_5'].jfc-templates-aside__menu-item").addClass(
          "jfc-templates-aside__menu-item__active"
        );
      } else if (
        section6Top <= sectionThreshold &&
        sectionThreshold < section7Top
      ) {
        $(".jfc-templates-aside__menu-item").removeClass(
          "jfc-templates-aside__menu-item__active"
        );
        $("a[href='#section_6'].jfc-templates-aside__menu-item").addClass(
          "jfc-templates-aside__menu-item__active"
        );
      } else if (
        section7Top <= sectionThreshold &&
        sectionThreshold < section8Top
      ) {
        $(".jfc-templates-aside__menu-item").removeClass(
          "jfc-templates-aside__menu-item__active"
        );
        $("a[href='#section_7'].jfc-templates-aside__menu-item").addClass(
          "jfc-templates-aside__menu-item__active"
        );
      } else if (
        section8Top <= sectionThreshold &&
        sectionThreshold < section9Top
      ) {
        $(".jfc-templates-aside__menu-item").removeClass(
          "jfc-templates-aside__menu-item__active"
        );
        $("a[href='#section_8'].jfc-templates-aside__menu-item").addClass(
          "jfc-templates-aside__menu-item__active"
        );
      } else if (section9Top <= sectionThreshold) {
        $(".jfc-templates-aside__menu-item").removeClass(
          "jfc-templates-aside__menu-item__active"
        );
        $("a[href='#section_9'].jfc-templates-aside__menu-item").addClass(
          "jfc-templates-aside__menu-item__active"
        );
      } else {
        $(".jfc-templates-aside__menu-item").removeClass(
          "jfc-templates-aside__menu-item__active"
        );
      }
    });
  }
});

$(function () {
  if ($("#jfc_templates_edit_sp_main_section").length) {
    beforeunloadHandler();

    $("button[type='submit'], a:not(.anchor-link)").on("click", function() {
      $(window).off("beforeunload");

      if ($(this).attr("id") == "plan_download_modal_plan_download_link") {
        setTimeout(function() {
          beforeunloadHandler();
        }, 500);
      }
    });

    function beforeunloadHandler() {
      $(window).on("beforeunload", function(event) {
        event.preventDefault();
        event.returnValue = "";
      });
    }
  }
});
