document.addEventListener("DOMContentLoaded", function () {
  toggleStarActivation("popularSample01");
  toggleStarActivation("popularSample02");
  toggleStarActivation("popularSample03");
  toggleStarActivation("usefulSample01");
  toggleStarActivation("usefulSample02");
  toggleStarActivation("usefulSample03");
  toggleStarActivation("recommendSample01");
  toggleStarActivation("recommendSample02");
  toggleStarActivation("recommendSample03");
  toggleStarActivation("favoriteSample01");
  toggleStarActivation("favoriteSample02");
  toggleStarActivation("favoriteSample03");
  toggleStarActivation("resultSample01");
  toggleStarActivation("resultSample02");
  toggleStarActivation("resultSample03");

  toggleStarActivationDetail();
});

const toggleStarActivation = (contentId) => {
  const popularItem = document.getElementById(contentId);

  if (popularItem) {
    const favoriteButton = popularItem.querySelector("button");
    const graystar = popularItem.querySelector(".ls-favoriteIcon");
    const yellowstar = popularItem.querySelector(".ls-favoriteIcon--active");

    favoriteButton.addEventListener("click", function () {
      graystar.classList.toggle("hidden");
      yellowstar.classList.toggle("hidden");
    });
  }
};

const toggleStarActivationDetail = () => {
  const favoriteButtons = document.querySelectorAll(".detailFavorite");

  favoriteButtons.forEach((button) => {
    const graystar = button.querySelector(".ls-favoriteIcon");
    const yellowstar = button.querySelector(".ls-favoriteIcon--active");

    button.addEventListener("click", function () {
      graystar.classList.toggle("hidden");
      yellowstar.classList.toggle("hidden");
    });
  });
};
