// ▼ 注意書き *********************************** //

function toggleCautionQuestion104Answer() {
  const question104Answer = $("#kantanshindan_answer_question_104_answer").prop("checked");
  const question102Answer = $("input[name='kantanshindan_answer[question_102_answer]']:checked").val();

  if (!(question104Answer || (!question104Answer && question102Answer === '1'))) {
    $("#caution__question_104_answer").show();
  } else {
    $("#caution__question_104_answer").hide();
  }
}

function toggleCautionQuestion105Answer() {
  const question105Answer = $("#kantanshindan_answer_question_105_answer").prop("checked");

  if (!question105Answer) {
    $("#caution__question_105_answer").show();
  } else {
    $("#caution__question_105_answer").hide();
  }
}

function toggleCautionQuestion106Answer() {
  const question106Answer = $("#kantanshindan_answer_question_106_answer").prop("checked");

  if (!question106Answer) {
    $("#caution__question_106_answer").show();
  } else {
    $("#caution__question_106_answer").hide();
  }
}

function toggleCautionQuestion107Answer() {
  const question107Answer = $("#kantanshindan_answer_question_107_answer").prop("checked");

  if (!question107Answer) {
    $("#caution__question_107_answer").show();
  } else {
    $("#caution__question_107_answer").hide();
  }
}

function toggleCautionQuestion108Answer() {
  const question108Answer = $("#kantanshindan_answer_question_108_answer").prop("checked");

  if (!question108Answer) {
    $("#caution__question_108_answer").show();
  } else {
    $("#caution__question_108_answer").hide();
  }
}

function toggleCautionQuestion109Answer() {
  const question109Answer = $("#kantanshindan_answer_question_109_answer").prop("checked");

  if (!question109Answer) {
    $("#caution__question_109_answer").show();
  } else {
    $("#caution__question_109_answer").hide();
  }
}

function toggleCautionQuestion110Answer() {
  const question110Answer = $("#kantanshindan_answer_question_110_answer").prop("checked");

  if (!question110Answer) {
    $("#caution__question_110_answer").show();
  } else {
    $("#caution__question_110_answer").hide();
  }
}

// ▲ 注意書き *********************************** //

// ▼ 設問表示切替 ******************************* //

// 「雇用」について実施したいことを選択してください
function toggleQ3Section() {
  const question103Answer = $("#kantanshindan_answer_question_103_answer").prop("checked");
  const question105Answer = $("#kantanshindan_answer_question_105_answer").prop("checked");
  const question106Answer = $("#kantanshindan_answer_question_106_answer").prop("checked");
  const question108Answer = $("#kantanshindan_answer_question_108_answer").prop("checked");
  const question109Answer = $("#kantanshindan_answer_question_109_answer").prop("checked");
  const question110Answer = $("#kantanshindan_answer_question_110_answer").prop("checked");

  if (!question103Answer || !question105Answer || !question106Answer || !question108Answer || !question109Answer || !question110Answer) {
    $("#shindan_q3").hide();
  } else {
    $("#shindan_q3").show();
  }
}

// 「従業員」について実施したいことを選択してください
function toggleQ4Section() {
  const question102Answer = $("input[name='kantanshindan_answer[question_102_answer]']:checked").val();
  const question103Answer = $("#kantanshindan_answer_question_103_answer").prop("checked");
  const question105Answer = $("#kantanshindan_answer_question_105_answer").prop("checked");
  const question106Answer = $("#kantanshindan_answer_question_106_answer").prop("checked");
  const question108Answer = $("#kantanshindan_answer_question_108_answer").prop("checked");
  const question109Answer = $("#kantanshindan_answer_question_109_answer").prop("checked");
  const question110Answer = $("#kantanshindan_answer_question_110_answer").prop("checked");

  if ((question102Answer === '1' && !question103Answer) || !question105Answer || !question106Answer || !question108Answer || !question109Answer || !question110Answer) {
    $("#shindan_q4").hide();
  } else {
    $("#shindan_q4").show();
  }
}

// ▲ 設問表示切替 ******************************* //

$('#shindan_q1 input').on('change', function() {
  toggleCautionQuestion104Answer();
  toggleCautionQuestion105Answer();
  toggleCautionQuestion106Answer();
  toggleCautionQuestion107Answer();
  toggleCautionQuestion108Answer();
  toggleCautionQuestion109Answer();
  toggleCautionQuestion110Answer();

  toggleQ3Section();
  toggleQ4Section();
});

// 表示非表示関係はここまで

// フォームallチェック
const employmentCheckboxes = document.querySelectorAll(
  ".needs-of-employment--checkbox"
);
const businessCheckboxes = document.querySelectorAll(
  ".needs-of-business--checkbox"
);
const employeeCheckboxes = document.querySelectorAll(
  ".needs-of-employee--checkbox"
);
const othersCheckboxes = document.querySelectorAll(
  ".needs-of-others--checkbox"
);

$("#needs-of-employment-allcheck").on("change", function () {
  if ($(this).prop("checked")) {
    employmentCheckboxes.forEach((checkbox) => {
      checkbox.checked = true;
    });
  } else {
    employmentCheckboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
  }
});

$("#needs-of-business-allcheck").on("change", function () {
  if ($(this).prop("checked")) {
    businessCheckboxes.forEach((checkbox) => {
      checkbox.checked = true;
    });
  } else {
    businessCheckboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
  }
});

$("#needs-of-employee-allcheck").on("change", function () {
  if ($(this).prop("checked")) {
    employeeCheckboxes.forEach((checkbox) => {
      checkbox.checked = true;
    });
  } else {
    employeeCheckboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
  }
});

// ページロード時
$(function() {
  toggleCautionQuestion104Answer();
  toggleCautionQuestion105Answer();
  toggleCautionQuestion106Answer();
  toggleCautionQuestion107Answer();
  toggleCautionQuestion108Answer();
  toggleCautionQuestion109Answer();
  toggleCautionQuestion110Answer();

  toggleQ3Section();
  toggleQ4Section();

  allCheck('.needs-of-employment--checkbox', '#needs-of-employment-allcheck');
  allCheck('.needs-of-business--checkbox', '#needs-of-business-allcheck');
  allCheck('.needs-of-employee--checkbox', '#needs-of-employee-allcheck');

  function allCheck(innerCheckboxSelector, allCheckboxSelector) {
    if ($(innerCheckboxSelector).length == $(innerCheckboxSelector + ':checked').length) {
      $(allCheckboxSelector).prop("checked", true);
    }
  }
});
