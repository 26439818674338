import {selectOnChangeSubmit} from '../packs/modules/select_on_change_submit'
import {deleteParameterFromUrl} from "../packs/modules/delete_parameter_from_url";

$(() => {
  selectOnChangeSubmit(".js-easy_diagnosis_order_subsidy_type_select", "#order_subsidy_type_hidden_value", "#easy_diagnosis_complete_form");

  if (location.pathname.indexOf('/search/diagnose/') === 0) {
    deleteParameterFromUrl(location, 'from_submit_button');
  }
});

$(function() {
  if ($('#easy_diagnosis_start_form').length) {
    $('a[href="/log_in"], a[href="/sign_up"], #easy_diagnosis_start_form_submit').on('click', function() {
      // かんたん診断回答フォームの値をjson形式に変換してcookieに保存
      const form = $('#easy_diagnosis_start_form');
      const elements = form.find('input:checked');

      const diagnosisAnswersArray = {}

      elements.each(function() {
        const regex = /^kantanshindan_answer\[question_[0-9]{3}_answer\]$/
        if (regex.test($(this).attr('name'))) {
          const item = $(this).attr('name').match(/[0-9]{3}/);
          if (item != null) {
            const key = item[0];
            const value = $(this).val();

            diagnosisAnswersArray[key] = value;
          }
        }
      });

      const diagnosisAnswersJson = JSON.stringify(diagnosisAnswersArray);

      setCookie('diagnosis_answers', diagnosisAnswersJson);
    });
  }

  function setCookie(key, value) {
    let cookie = key + '=' + encodeURIComponent(value) + ';';

    cookie += 'path=/;';

    // 有効期限: 20年
    let expires = new Date();
    expires.setFullYear(expires.getFullYear() + 20);
    cookie += 'expires=' + expires + ';';

    cookie += 'samesite=lax;';

    document.cookie = cookie;
  };

  function deleteCookie(key) {
    let cookie = key + '=;path=/;max-age=0;';
    document.cookie = cookie;
  };
});
