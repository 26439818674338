import { Modal } from 'bootstrap'
// 一時保存ページロード後モーダル表示
$(function() {
  if ($('.js-open_plan_save_success_modal').length) {
    const preserve = new Modal(document.getElementById('preserve'));
    preserve.show();
  }
});
// 削除確認モーダル表示
$(function() {
  const modalSelector = "#copy";
  const changeTargetSelector = "#copy #js-plan_delete_form";
  var myModal;

  if ($(modalSelector).length) {
    myModal = new Modal($(modalSelector), {});
  }

  $('.js-plan_delete_button').on('click', function() {
    var href = $(this).data('link-href');
    $(changeTargetSelector).attr('action', href);

    myModal.show();
  });
});

$(function() {
  // メール送信ボタン活性状態切替
  const emailAddressInputSelector = ".js-plan_download_modal_email_form_email_address";
  const submitButtonSelector = ".js-plan_download_modal_email_form_submit_button";
  // ロード時
  if ($(submitButtonSelector).length) {
    const sendButton = $(submitButtonSelector);
    const emailAddressVal = sendButton.siblings(emailAddressInputSelector).val();
    if (!emailAddressIsValid(emailAddressVal)) {
      sendButton.prop("disabled", true);
    } else {
      sendButton.prop("disabled", false);
    }
  }
  // メールアドレス変更時
  $(emailAddressInputSelector).on("change", function() {
    const sendButton = $(this).siblings(submitButtonSelector);
    if (!emailAddressIsValid($(this).val())) {
      sendButton.prop("disabled", true);
    } else {
      sendButton.prop("disabled", false);
    }
  });
  // メールアドレスバリデーション
  function emailAddressIsValid(value) {
    let result = true;
    if (value == "") {
      result = false;
    } else if (!value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)) {
      result = false;
    }
    return result;
  }

  // ダウンロードモーダル
  const downloadModalSelector = "#download-sp";
  var downloadModal;
  if ($(downloadModalSelector).length) {
    downloadModal = new Modal($(downloadModalSelector), {});
  }
  if ($('.js-open_plan_download_modal').length) {
    downloadModal.show();
  }

  // メール送信完了モーダル
  const emailSendCompletelyModalSelector = "#send-sp";
  var emailSendCompletelyModal;
  if ($(emailSendCompletelyModalSelector).length) {
    emailSendCompletelyModal = new Modal($(emailSendCompletelyModalSelector), {});
  }

  // メール送信フォームsubmit時に、ダウンロードモーダルを閉じて、メール送信完了モーダルを開く
  $(".js-plan_download_modal_email_form").on("submit", function() {
    downloadModal.hide();
    emailSendCompletelyModal.show();
  });

  // ダウンロードボタンクリック時に、ダウンロードモーダルを閉じる
  $(".js-close_plan_download_modal").on("click", function() {
    downloadModal.hide();
  });
});

// ポイントモーダル
$(function() {
  const modalSelector = "#point";
  var myModal;

  if ($(modalSelector).length) {
    myModal = new Modal($(modalSelector), {});
  }

  if ($('.js-open_plan_point_modal').length) {
    myModal.show();
  }
});

$(function() {
  // 100文字以上入力されたらトリムする
  $(".js-trimOver100chars").on('load input', function(e) {
    trimOverNchars(100, e.target);
  });
  // 5000文字以上入力されたらトリムする
  $(".js-trimOver5000chars").on('load input', function(e) {
    trimOverNchars(5000, e.target);
  });
});

function trimOverNchars(maxLength, element) {
  if (element.value && element.value.length > maxLength) {
    element.value = element.value.slice(0, maxLength)
  }
}

$(function() {
  if ($('.js-open_signup_only_modal').length) {
    const noveltyRegisterModal = new Modal(document.getElementById('novelty-register-modal'));
    noveltyRegisterModal.show();
  }
});
