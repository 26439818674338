function toggleTableAccordion(btnId, itemClass, btnTextId, btnIconId) {
  const accordionBtn = document.getElementById(btnId);
  const accordionItems = document.querySelectorAll(`.${itemClass}`);
  const btnText = document.getElementById(btnTextId);
  const btnIcon = document.getElementById(btnIconId);
  if (accordionBtn && accordionItems && btnText && btnIcon) {
    accordionBtn.addEventListener("click", function () {
      accordionItems.forEach((item) => {
        if (item.classList.contains("display")) {
          item.classList.remove("display");
          btnText.textContent = "内訳を見る";
          btnIcon.style.transform = "rotate(90deg)";
        } else {
          item.classList.add("display");
          btnText.textContent = "閉じる";
          btnIcon.style.transform = "rotate(270deg)";
        }
      });
    });
  }
}

for (let i = 1; i <= 3; i++) {
  const btnId = `suuchiBtn${i}`;
  const itemClass = `suuchiTableDetail${i}`;
  const btnTextId = `suuchiBtnText${i}`;
  const btnIconId = `suuchiBtnIcon${i}`;
  toggleTableAccordion(btnId, itemClass, btnTextId, btnIconId);
}
