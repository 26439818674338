import { Tooltip } from "bootstrap";

$(function () {
  for (let i = 1; i < 3; i++) {
    $("#copyButton" + i).on("click", function () {
      let copyTarget = $.trim($("#copyTarget" + i).html()).replace(
        /<br>/g,
        "\n"
      );
      navigator.clipboard.writeText(copyTarget);

      // ツールチップで完了通知
      var tooltipEl = document.getElementById("copyButton" + i);
      var tooltip = new Tooltip(tooltipEl);
      tooltip.show();
      setTimeout(function () {
        tooltip.hide();
      }, 1600);
    });
  }
});
