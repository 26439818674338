// 新規会員登録画面で事業形態が選択されていなければ、同意チェックボックスを非活性にする
$(function() {
  if ($('#register_agree_check_box').length) {
    var businessTypeRadioButtonSelector = '#user_registration_business_type input[name="user[business_type_cd]"]';
    var registerAgreeCheckBoxSelector = '#register_agree_check_box';

    toggleRegisterAgreeCheckBox();

    $(businessTypeRadioButtonSelector).on('change', function() {
      toggleRegisterAgreeCheckBox();
    });

    function toggleRegisterAgreeCheckBox() {
      var errorStatus = checkMandatoryFields();
      if (errorStatus === true) {
        $(registerAgreeCheckBoxSelector).prop('disabled', true);
        $(registerAgreeCheckBoxSelector).prop('checked', false);
        $(registerAgreeCheckBoxSelector).trigger('change');
      } else {
        $(registerAgreeCheckBoxSelector).prop('disabled', false);
      }
    }

    function checkMandatoryFields() {
      var errorStatus = false;
      // 事業形態のnullチェック
      if($(businessTypeRadioButtonSelector+':checked').val() === undefined) {
        errorStatus = true;
      }
      return errorStatus
    }
  }
});

// 利用規約同意と退会の確認チェックボックスとsubmitボタン
$(function() {
  const registerCheckBoxId = '#register_agree_check_box';
  const registerSubmitButtonId = '#register_submit_button';
  toggleSubmitButtonDisabled(registerCheckBoxId, registerSubmitButtonId);

  const withdrawalCheckBoxId = '#withdrawal_verified_check_box';
  const withdrawalSubmitButtonId = '#withdrawal_submit_button';
  toggleSubmitButtonDisabled(withdrawalCheckBoxId, withdrawalSubmitButtonId);

  function toggleSubmitButtonDisabled(checkBoxId, submitButtonId) {
    $(checkBoxId).on('change', function() {
      if ($(this).prop('checked')) {
        $(submitButtonId).prop('disabled', false);
      } else {
        $(submitButtonId).prop('disabled', true);
      }
    });
  }
});

// 事業形態がon change時の挙動
$('input[name="user[business_type_cd]"]').on('change', function() {
  userBusinessTypeValidation($(this));
});
// ロード時の事業形態の挙動
$(function() {
  var element = $('input[name="user[business_type_cd]"]:checked')
  if (element.length) {
    userBusinessTypeValidation(element);
  }
});

// 事業形態ラジオボタンのチェック状態の要素をクリックでチェックをはずす
/*
$(function() {
  const inputs = $('input[name="user[business_type_cd]"]');
  let checked = inputs.filter(':checked').val();

  inputs.on('click', function() {
    if ($(this).val() === checked) {
      $(this).prop('checked', false);
      checked = '';
    } else {
      $(this).prop('checked', true);
      checked = $(this).val();
    }

    userBusinessTypeValidation($(this));
  });
});
*/

function userBusinessTypeValidation (element) {
  if (element.prop('checked')) {
    // 事業形態が「創業前」の時、「従業員数」と「継続年数」を非活性状態とする
    if (element.val() === "創業前") {
      $('#user_employee_number').val("").addClass("default").prop('disabled', true);
      $('#user_business_period_cd').val("").addClass("default").prop('disabled', true);
    } else {
      $('#user_employee_number').prop('disabled', false);
      $('#user_business_period_cd').prop('disabled', false);
    }
    // 事業形態が「法人」または初期値以外の場合、「資本金額」を非活性状態とする
    if (element.val() === "法人" || element.val() === "") {
      $('#user_capital_amount').prop('disabled', false);
    } else {
      $('#user_capital_amount').val("").addClass("default").prop('disabled', true);
    }
  } else {
    $('#user_employee_number').prop('disabled', false);
    $('#user_business_period_cd').prop('disabled', false);
    $('#user_capital_amount').prop('disabled', false);
  }
}

import {checkedCitiesOver, checkFreewordLengthOver} from './muitiple-selective-checkbox';
$('.user-form-submit-button').on('click', (e) => {
  // 市区町村が制限以上またはフリーワードの文字数が制限以上の場合submitしない
  var hasError = checkedCitiesOver() || checkFreewordLengthOver();
  if(hasError){
    return false;
  }
});
