// チェックボックスとセレクトボックスの複合。'jquery-3.4.1.min.js'が必要。
$(function() {

  const checkPrefClassName = ".chex-pref"
  const mainGroupedInputClassName =".mcheck-all-99"
  const mainPrefInputCalssName = ".mchex-99"
  const groupedInputClassName = ".check-all-99";
  const prefInputCalssName = ".chex-99";
  const cityInputCalssName = ".cchex-99";
  const industoryInputClassName = ".chex-ind"
  const usageInputClassName = ".chex-use";
  const prefApiUrl = "/api/v1/cities/";

  //* DOMツリー構成後にチェック済みの市区町村を取得し、チェックをつける ---------------------- *
  $(document).ready(function() {
    if (typeof checkedPrefs !== 'undefined' && checkedPrefs.length) {
      // 初期チェック済み都道府県のチェック
      checkPrefs(checkedPrefs);
    }
  });

  //* DOMツリー構成後に業種、課題・資金使途の初期値をセットする
  $(document).ready(function() {
    // 業種のセット
    var checkboxExIndustory = $(industoryInputClassName).first().closest(".checkbox-ex");
    setIndustory(checkboxExIndustory);

    // 課題資金使途のセット
    var checkboxExUsage = $(usageInputClassName).first().closest(".checkbox-ex--tags");
    setUsage(checkboxExUsage);
  });

  //* 【Main 都道府県】クリックイベント ---------------------- *
  $("body").on('change', mainGroupedInputClassName, function() {
    checkPrefGroupElement($(this), "main")
    addPrefsToArray($(this), "main");
    checkPrefs(checkedPrefs);
  });

  $("body").on('change', mainPrefInputCalssName, function() {
    checkPrefElement($(this), "main")
    addPrefsToArray($(this), "main");
    checkPrefs(checkedPrefs);
  });

  //* 【詳細 都道府県】クリックイベント ---------------------- *
  $("body").on('change', groupedInputClassName, function() {
    checkPrefGroupElement($(this), "detail")
    addPrefsToArray($(this), "detail");
    checkPrefs(checkedPrefs);
  });

  $("body").on('change', prefInputCalssName, function() {
    checkPrefElement($(this), "detail");
    addPrefsToArray($(this), "detail");
    checkPrefs(checkedPrefs);
  });

  //* 【詳細 市区町村】クリックイベント ---------------------- *

  $("body").on('change', cityInputCalssName, function() {
    checkCityElement($(this))
    addCitiesToArray()
  });

  //* 検索条件クリア後、または、検索条件に登録情報を反映後に実行する処理
  $('#after_reset_search_condition').on('click', function() {
    //* チェック済みの市区町村を取得し、チェックをつける
    if (typeof checkedPrefs !== 'undefined') {
      checkPrefs(checkedPrefs);
      addPrefsToArray($(prefInputCalssName), "detail");
    }

    //* 業種、課題・資金使途の初期値をセットする
    // 業種のセット
    var checkboxExIndustory = $(industoryInputClassName).first().closest(".checkbox-ex");
    setIndustory(checkboxExIndustory);

    // 課題資金使途のセット
    var checkboxExUsage = $(usageInputClassName).first().closest(".checkbox-ex--tags");
    setUsage(checkboxExUsage);
  });

  function checkPrefs(checkedPrefsArray) {
    //checkされているものを一度リセット
    $('input' + mainPrefInputCalssName + ':checked').each(function(idx, elm) {
      $(elm).prop('checked', false);
      checkPrefElement($(elm), "main")
    });
    $('input' + prefInputCalssName + ':checked').each(function(idx, elm) {
      $(elm).prop('checked', false);
      checkPrefElement($(elm), "detail")
    });
    //checkedPrefsArrayに基づいてcheck
    checkedPrefsArray.forEach(function(prefCode) {
      $('input[value="' + prefCode + '"]' + mainPrefInputCalssName).each(function(idx, elm) {
        $(elm).prop('checked', true);
        checkPrefElement($(elm), "main")
      });
      $('input[value="' + prefCode + '"]' + prefInputCalssName).each(function(idx, elm) {
        $(elm).prop('checked', true);
        checkPrefElement($(elm), "detail")
      });
    });
    createCities($(".checkbox-ex__select-group"), "detail");
  }

  function checkCities(checkedCitiesArray) {
    checkedCitiesArray.forEach(function(prefCode) {
      $('input[value="' + prefCode + '"]' + cityInputCalssName).each(function(idx, elm) {
        $(elm).prop('checked', true);
        checkCityElement($(elm));
      });
    });
  }

  //* 【選択済み都道府県一覧取得】 ---------------------- *
  function collectPrefArray(parentElement) {
    return parentElement.find('input' + checkPrefClassName + ':checked')
  }

  function collectCityArray() {
    return $("#select-city").find('input' + cityInputCalssName + ':checked')
  }

  function addPrefsToArray(thisElement, location) {
    var targetCheckBoxEx = location === "main" ? ".m-checkbox-ex" : ".checkbox-ex";
    var targetInputClassName = location === "main" ? mainPrefInputCalssName : prefInputCalssName
    const checkboxEx = $(thisElement.closest(targetCheckBoxEx))
    checkedPrefs = collectPrefArray(checkboxEx).map(function() {
      return $(this).val();
    }).get();
    // hiddenフィールドへの格納
    if ($("#search_anken_business_area_cds_str_hidden_value").length){
      $("#search_anken_business_area_cds_str_hidden_value").val(checkedPrefs.join(','));
    }
    if ($("#detail_search_anken_business_area_cds_str_hidden_value").length) {
      $("#detail_search_anken_business_area_cds_str_hidden_value").val(checkedPrefs.concat(checkedCities).join(','));
    }
    if ($("#search_anken_business_area_cds_str_hidden_value_lp_1").length){
      $("#search_anken_business_area_cds_str_hidden_value_lp_1").val(checkedPrefs.join(','));
    }
    if ($("#search_anken_business_area_cds_str_hidden_value_lp_2").length){
      $("#search_anken_business_area_cds_str_hidden_value_lp_2").val(checkedPrefs.join(','));
    }
  }

  function addCitiesToArray() {
    checkedCities = collectCityArray().map(function() {
      return $(this).val();
    }).get();
    // hiddenフィールドへの格納
    if ($("#detail_search_anken_business_area_cds_str_hidden_value").length) {
      $("#detail_search_anken_business_area_cds_str_hidden_value").val(checkedPrefs.concat(checkedCities).join(','));
    }
  }

  function createCities(thisElement, location) {
    var targetCheckBoxEx = location === "main" ? ".m-checkbox-ex" : ".checkbox-ex";
    const checkboxEx = $(thisElement.closest(targetCheckBoxEx))
    const prefArray = collectPrefArray(checkboxEx)
    getPrefJson(prefArray)
  }

  function checkPrefGroupElement(thisElement, location) {
    var targetCheckboxExSelected = location === "main" ? ".m-checkbox-ex__select-group" : ".checkbox-ex__select-group"
    var targetCheckBoxEx = location === "main" ? ".m-checkbox-ex" : ".checkbox-ex";
    const selectGroup = thisElement.parent().next(targetCheckboxExSelected);
    selectGroup.find("input").prop('checked', thisElement.is(':checked'));
    const checkboxEx = $(thisElement.closest(targetCheckBoxEx))
    syncTextField(checkboxEx, location);
  }

  function checkPrefElement(thisElement, location) {
    var targetHeadingClass = location === "main" ? ".m-checkbox-ex__heading" : ".checkbox-ex__heading";
    var targetCheckBoxEx = location === "main" ? ".m-checkbox-ex" : ".checkbox-ex";
    var targetInputClassName = location === "main" ? mainGroupedInputClassName : groupedInputClassName
    const selectGroup = thisElement.parent().parent();
    const selectHeaderInput = selectGroup.prev(targetHeadingClass).find(targetInputClassName)
    if (selectGroup.find("input").filter(":checked").length == selectGroup.find("input").length){
      selectHeaderInput.prop('checked', true);
    }else{
      selectHeaderInput.prop('checked', false);
    }
    const checkboxEx = $(thisElement.closest(targetCheckBoxEx))
    syncTextField(checkboxEx, location);
  }

  function syncTextField(checkboxEx, location) {
    var targetDataObjectClassName = location === "main" ? ".m-checkbox-ex__data-object" : ".checkbox-ex__data-object"
    var targetCheckboxExSelected = location === "main" ? ".m-checkbox-ex__selected" : ".checkbox-ex__selected"
    const defaultHtml = location === "main" ? '地域を選択（必須）' : '地域（都道府県）を選択';
    const prefs = collectPrefArray(checkboxEx);
    const valsPref = prefs.map(function() {
      return $(this).next().find(targetDataObjectClassName).text();
    }).get();
    checkboxEx.find(targetCheckboxExSelected).removeClass('null-alert');
    if (valsPref.length >= 4) {
      const value = '選択中（' + valsPref.length + '）'
      checkboxEx.find(targetCheckboxExSelected).removeClass('default').text(value);
    }else if(valsPref.length >= 1) {
      checkboxEx.find(targetCheckboxExSelected).removeClass('default').text(valsPref.join(", "));
    }else{
      checkboxEx.find(targetCheckboxExSelected).addClass('default').html(defaultHtml);
    }
  }

  function citySyncTextField(checkboxEx) {
    const defaultHtml = '<span class="default">地域（市区町村）を選択</span>'
    const valsPref = $(checkboxEx).find('input' + cityInputCalssName + ':checked').map(function() {
      return $(this).next().find(".checkbox-ex__data-object").text();
    }).get();
    if (valsPref.length >= 4) {
      const value = '選択中（' + valsPref.length + '）'
      checkboxEx.find(".checkbox-ex__selected").html('<span>' + value + '</span>');
    }else if(valsPref.length >= 1) {
      checkboxEx.find(".checkbox-ex__selected").html('<span>' + valsPref.join(", ") + '</span>');
    }else{
      checkboxEx.find(".checkbox-ex__selected").html(defaultHtml);
    }
  }

  function checkCityElement(thisElement) {
    citySyncTextField($(thisElement.closest(".checkbox-ex")));
  }

  //* 【市区町村のAPIを実行】 ---------------------- *
  function getPrefJson(prefArray) {
    var values = prefArray.map(function() {
      return $(this).val()
    }).get();
    if (prefArray.length) {
      var query = prefApiUrl + "?"
      $.each(values, function(index, element) {
        if ((values.length -1) === index) {
          query += "values%5B%5D=" + element
        } else {
          query += "values%5B%5D=" + element + "&"
        }
      })
      $.getJSON(query)
      .done(function(data) {
        if (data) {
          assembleCities(data);
          checkCities(checkedCities);
          addCitiesToArray();
          citySyncTextField($("#select-city"));
        }
      });
    } else {
      //初期リセット
      checkedCities = [];
      $("#cities").empty();
      checkCities(checkedCities);
      addCitiesToArray();
      citySyncTextField($("#select-city"));
    }
  }

  function assembleCities(data) {
    const targetSelector = "#cities"

    var target = $(targetSelector)

    // API呼び出し都度クリア
    target.empty()

    // テンプレートの読み込み
    var tempRow = $($('#template-row').html());
    var tempHead = $($('#template-head').html());
    var tempSelect = $($('#template-select').html());

    $.each(data.prefs, function(idx, val) {
      var tempRowClone = tempRow.clone(true);
      var tempHeadClone = tempHead.clone(true);

      //Head要素の追加
      tempHeadClone.find(".checkbox-ex__data-object").text(val.pref);
      tempRowClone.prepend(tempHeadClone);

      //Select要素の追加
      $.each(val.cities, function(idxSelect, val){
        var tempSelectClone = tempSelect.clone(true);
        tempSelectClone.find(".form-check-input").attr('id', 'chx-city-' + idx + idxSelect);
        tempSelectClone.find(".form-check-input").val(val.city_cd)
        tempSelectClone.find(".form-check-label").attr('for', 'chx-city-' + idx + idxSelect)
        tempSelectClone.find(".checkbox-ex__data-object").text(val.city)
        tempRowClone.find(".checkbox-ex__select-group").append(tempSelectClone)
      });
      target.append(tempRowClone)
    });
  }

  //* 【業種分類】 ---------------------- *
  // チェック状態の時に、セレクトボックスの中身を同期させる
  $("body").on('change', industoryInputClassName, function() {
    const checkboxEx = $(this.closest(".checkbox-ex"))
    setIndustory(checkboxEx);
  });

  function setIndustory(checkboxEx) {
    const defaultHtml = '<span class="default">業種分類を選択</span>';
    const valsPref = $(checkboxEx).find('input' + industoryInputClassName + ':checked').map(function() {
      return $(this).next().find(".checkbox-ex__data-object").text();
    }).get();
    if (valsPref.length >= 4) {
      const value = '選択中（' + valsPref.length + '）'
      checkboxEx.find(".checkbox-ex__selected").text(value);
    }else if(valsPref.length >= 1) {
      checkboxEx.find(".checkbox-ex__selected").text(valsPref.join(", "));
    }else{
      checkboxEx.find(".checkbox-ex__selected").html(defaultHtml);
    }
    // hiddenフィールドへのセット
    if ($("#detail_search_anken_industry_cds_str_hidden_value").length) {
      const vals = $(checkboxEx).find('input' + industoryInputClassName + ':checked').map(function() {
        return $(this).val();
      }).get();
      $("#detail_search_anken_industry_cds_str_hidden_value").val(vals.join(","));
    }
  }

  //* 【課題・資金使途】 ---------------------- *
  $("body").on('change', usageInputClassName, function() {
    const checkboxEx = $(this.closest(".checkbox-ex--tags"))
    setUsage(checkboxEx);
  });

  function setUsage(checkboxEx) {
    const defaultHtml = '<span class="default">課題・資金使途を選択</span>';
    const valsUsage = $(checkboxEx).find('input' + usageInputClassName + ':checked').map(function() {
      return $(this).next().find(".checkbox-ex__tag-data-object").text();
    }).get();
    if (valsUsage.length >= 3) {
      const value = '選択中（' + valsUsage.length + '）'
      checkboxEx.find(".checkbox-ex__selected").text(value);
    }else if(valsUsage.length >= 1) {
      let html = "";
      $.each(valsUsage, function (index, val) {
        html += '<span class="tag-data-object--active">' + val + '</span>';
      });
      checkboxEx.find(".checkbox-ex__selected").html(html);
    }else{
      checkboxEx.find(".checkbox-ex__selected").html(defaultHtml);
    }
    // hiddenフィールドへのセット
    if ($("#detail_search_anken_usage_cds_str_hidden_value").length) {
      const vals = $(checkboxEx).find('input' + usageInputClassName + ':checked').map(function() {
        return $(this).val();
      }).get();
      $("#detail_search_anken_usage_cds_str_hidden_value").val(vals.join(","));
    }
  }

  //* 【事業形態の連動処理 & 赤文字除去】 ---------------------- *
  $(".business-type").on("change", function(){
    $(".main-firstview__selected").removeClass('null-alert');
    $(".business-type").removeClass('null-alert');
    var selectedValue = $(this).val();
    $(".business-type").each(function(index, element) {
      $(element).val(selectedValue);
    });
    if(selectedValue === "") {
      $(".main-firstview__selected").addClass('default');
      $(".checkbox-ex--flat__select.business-type").addClass('default');
    } else {
      $(".main-firstview__selected").removeClass('default');
      $(".checkbox-ex--flat__select.business-type").removeClass('default');
    }
  });

  // 検索フリーワードのプレースホルダーを元に戻す
  $(document).on("focus", ".input-freeword.freeword-alert.search-freeword", function(){
    $(this).attr('placeholder', 'フリーワードで検索（補助金の名称、○○銀行など）');
    $(this).removeClass('freeword-alert');
  });

  // 会員情報フリーワードのプレースホルダーを元に戻す
  $(document).on("focus", ".input-freeword.freeword-alert.user-freeword", function(){
    $(this).attr('placeholder', 'フリーワード（補助金の名称、〇〇銀行など）');
    $(this).removeClass('freeword-alert');
  });

});

export function checkMandatoryFields(submitButtonEl) {
  var targetMainBusinessAreaSelected = ".m-checkbox-ex__selected";
  var targetBusinessAreaSelected = "#pref-selected-count";
  var targetMainBusinessTypeSelected = ".main-firstview__selected";
  var targetBusinessTypeSelected = "#detail_search_q_anken_business_type_cd";
  var errorStatus = false;

  // 簡易検索
  if (submitButtonEl === ".js-search_submit_button"){
    // 地域(都道府県)のnullチェック
    if($('#m-pref-checkbox-ex').find('input.chex-pref:checked').length === 0){
      $(targetMainBusinessAreaSelected).removeClass('default').addClass('null-alert');
      errorStatus = true;
    }
    // 事業形態のnullチェック
    if($('#q_anken_business_type_cd').val() === ""){
      $(targetMainBusinessTypeSelected).removeClass('default').addClass('null-alert');
      errorStatus = true;
    }
  }
  // 詳細検索
  if (submitButtonEl === ".js-detail_search_submit_button"){
    // 地域(都道府県)のnullチェック
    if($('#pref-checkbox-ex').find('input.chex-pref:checked').length === 0 ){
      $(targetBusinessAreaSelected).removeClass('default').addClass('null-alert');
      errorStatus = true;
    }
    // 事業形態のnullチェック
    if($('#detail_search_q_anken_business_type_cd').val() === ""){
      $(targetBusinessTypeSelected).removeClass('default').addClass('null-alert');
      errorStatus = true;
    }
  }
  return errorStatus
}

export function checkedCitiesOver() {
  const cityInputCalssName = ".cchex-99";
  const limitChecks = 50;
  const checkboxEx = $('#select-city');
  const errorHTML = '<span class="null-alert">' + limitChecks + '件以内で選択してください</span>'
  var errorStatus = false;

  const valsCities = checkboxEx.find('input' + cityInputCalssName + ':checked');
  if (valsCities.length > limitChecks) {
    errorStatus = true;
    var currentHTML = checkboxEx.find(".checkbox-ex__selected").html();
    if (!currentHTML.match(/null-alert/)){
      checkboxEx.find(".checkbox-ex__selected").html(currentHTML + errorHTML);
    }
  }
  return errorStatus;
}

//* 【キーワード入力フィールド】検索ボタンクリック時に文字数制限にかかればエラーを表示 ---------------------- *
export function checkFreewordLengthOver() {
  const freewordFieldClassName = ".input-freeword";
  const maxLength = 30;
  let errorStatus = false;

  if ($(freewordFieldClassName).length && $(freewordFieldClassName).val().length > maxLength) {
    errorStatus = true;
    $(freewordFieldClassName).addClass('freeword-alert');
    $(freewordFieldClassName).attr('placeholder', maxLength + '文字以内で入力してください');
    $(freewordFieldClassName).val('');
  }
  return errorStatus;
}