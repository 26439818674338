import {
  setWithCountAnimation,
  sanitizeMoney,
  updateDelimitedMoney,
  setDelimitedMoney,
  delimitedMoney,
} from "./suchiUtil";
import {
  toggleAutoCalcButtonAndTooltip,
  automaticCalculationValidate,
} from "./founding-template";
import {
  handleKaigyoShikinSetSimulationBar
} from "./numBtn";

const modalSelectButtons = document.querySelectorAll(".modal__button");
function handleClickSelectButton(e) {
  const val = e.currentTarget.value;
  const hiddenVal = e.currentTarget.getAttribute("data-hidden-value");
  document.getElementById("modal-select").textContent = val;
  document
    .getElementById("modal-select")
    .classList.remove("main-form__not-selected");
  document.getElementById("modal-select").classList.add("main-form__selected");
  document.getElementById("modal-select-hidden").value = hiddenVal;
  if ($("#uriage_init_title").length) {
    automaticCalculationValidate(false, "#modal-select-hidden");
  }
  toggleAutoCalcButtonAndTooltip();
}
modalSelectButtons.forEach((button) => {
  button.addEventListener("click", handleClickSelectButton);
});

$(function () {
  [
    "#modal-select-hidden",
    'input[name="founding_template[business_type]"]',
  ].forEach((item) => {
    $(item).on("change", function () {
      if ($("#uriage_init_title").length) {
        automaticCalculationValidate(false, item);
      }
    });
  });
  [
    "#text_jikoshikin",
    "#text_kariire",
    "#text_kaigyoshikin_sonota",
    "#text_sales1",
  ].forEach((item) => {
    $(item).on("input", function () {
      if ($("#uriage_init_title").length) {
        automaticCalculationValidate(false, item);
      }
    });
  });
});

function switchStartButton(valid) {
  if (valid) {
    $("#startCalculationButton").prop("disabled", false);
  } else {
    $("#startCalculationButton").prop("disabled", true);
  }
}

function validMandatoryFields() {
  if ($("#modal-select-hidden").val() === "") return false;
  if ($("input[type='radio']:checked").length === 0) return false;
  if ($("#text_jikoshikin").val() === "") return false;
  if ($("#text_kariire").val() === "") return false;
  if ($("#text_kaigyoshikin_sonota").val() === "") return false;
  if ($("#text_sales1").val() === "") return false;

  return true;
}

function setInitAsideSummary() {
  // 開業資金合計
  const sumInputs = document.querySelectorAll(".data-sum");
  const uriage1y = document.getElementById("sales1");
  let kaigyoShikinTotal = 0;
  sumInputs.forEach((input) => {
    kaigyoShikinTotal += sanitizeMoney(input.value);
  });
  if (
    document.getElementById("initFund1").textContent !=
    kaigyoShikinTotal.toString()
  ) {
    setWithCountAnimation(
      document.getElementById("initFund1"),
      0,
      kaigyoShikinTotal
    );
  }
  if (
    document.getElementById("initRunningSum1").textContent !=
    sanitizeMoney(uriage1y.value).toString()
  ) {
    setWithCountAnimation(
      document.getElementById("initRunningSum1"),
      0,
      sanitizeMoney(uriage1y.value)
    );
  }
}

// 開業費用スライダーの変更
function handleRangeInputChange(e) {
  const target = e.target;
  const max = Number(target.max);
  const val = Number(target.value);

  const inputId = target.id.replace("input-slider", "data-input");
  const hiddenElement = document.getElementById(inputId);
  const textFieldElement = document.getElementById("text_" + inputId);
  target.defaultValue = val;

  // text fieldへのセット
  textFieldElement.value = delimitedMoney(target.defaultValue);
  // hidden fieldへのセット
  hiddenElement.value = target.defaultValue;
  if (val !== 0) {
    target.style.backgroundSize = (val * 100) / max + "% 100%";
  } else {
    target.style.backgroundSize = "0% 100%";
  }
  setAsideSummary();
}

// 開業費用インプットフィールドの変更
function handleKaigyoHiyoInputChange(event) {
  const textFieldElement = event.target;
  const val = sanitizeMoney(textFieldElement.value);
  // カンマ区切り処理
  updateDelimitedMoney(textFieldElement);

  try {
    // スライダーへの反映
    const sliderId = textFieldElement.dataset.target.replace(
      "data-input",
      "input-slider"
    );
    const sliderElement = document.getElementById(sliderId);
    sliderElement.defaultValue = val;
    sliderElement.value = val;

    if (val == 0) {
      sliderElement.style.backgroundSize = "0% 100%";
    } else if (val > sliderElement.max) {
      sliderElement.style.backgroundSize = "100% 100%";
    } else {
      sliderElement.style.backgroundSize =
        (val * 100) / sliderElement.max + "% 100%";
    }
  } catch (error) {
    // console.warn(error);
  }
  setAsideSummary();
}

function kaigyoShikinSum() {
  const sumInputs = document.querySelectorAll(".data-sum");
  let result = 0;
  sumInputs.forEach((input) => {
    result += Number(input.value);
  });
  return result;
}

function setAsideSummary() {
  // 開業資金合計
  const sumInputs = document.querySelectorAll(".data-sum");
  let kaigyoShikinTotal = 0;
  sumInputs.forEach((input) => {
    kaigyoShikinTotal += Number(input.value);
  });
  // 開業費用合計
  const dataInputs = document.querySelectorAll(".data-input");
  let kaigyoHiyoTotal = 0;
  dataInputs.forEach((item) => {
    kaigyoHiyoTotal += Number(item.value);
  });

  setWithCountAnimation(document.getElementById("fund1"), 0, kaigyoShikinTotal);
  setWithCountAnimation(document.getElementById("fund2"), 0, kaigyoHiyoTotal);
  setWithCountAnimation(
    document.getElementById("fund3"),
    0,
    kaigyoShikinTotal - kaigyoHiyoTotal
  );

  checkShikinZanMinus(kaigyoShikinTotal - kaigyoHiyoTotal);

  handleKaigyoShikinSetSimulationBar(kaigyoShikinTotal, kaigyoHiyoTotal, kaigyoShikinTotal - kaigyoHiyoTotal);
}

function checkShikinZanMinus(zankin) {
  if (zankin < 0) {
    $(".shikinZanMinus").addClass("d-block");
    $(".showResultButton").prop("disabled", true);
    $(".showResultButton").addClass("main-button--mask");
  } else {
    $(".shikinZanMinus").removeClass("d-block");
    $(".showResultButton").prop("disabled", false);
    $(".showResultButton").removeClass("main-button--mask");
  }
}

// 開業資金のイベント処理
function handleKaigyoShikinChange(event) {
  const textFieldElement = event.target;
  // カンマ区切り処理
  updateDelimitedMoney(textFieldElement);
  // 開業資金合計算出
  resetMaxRange();
  setAsideSummary();
}

// 開業資金変更によってスライダー最大値を変更する
function resetMaxRange() {
  const currentSum = kaigyoShikinSum();
  // レンジスライダー
  const rangeInputs = document.querySelectorAll(".input-slider");

  const maxRange = currentSum < 100 ? 100 : currentSum;
  rangeInputs.forEach((slider) => {
    slider.max = maxRange;
    slider.value = slider.defaultValue;
    if (Number(slider.defaultValue) !== 0) {
      if ((Number(slider.defaultValue) * 100) / maxRange + 1 > 100) {
        slider.style.backgroundSize = "100% 100%";
      } else {
        slider.style.backgroundSize =
          (Number(slider.defaultValue) * 100) / maxRange + 1 + "% 100%";
      }
    } else {
      slider.style.backgroundSize = "0% 100%";
    }
  });
}

export function addKaigyoShikinEventListenerAll() {
  // 開業費用のスライダー
  const rangeInputs = document.querySelectorAll(".input-slider");
  rangeInputs.forEach((input) => {
    input.addEventListener("input", handleRangeInputChange);
  });

  // 開業費用のスライダー横インプット
  const dataInputs = document.querySelectorAll(".js-kaigyo-hiyo-input");
  dataInputs.forEach((input) => {
    input.addEventListener("change", handleKaigyoHiyoInputChange);
  });

  // 開業資金合計
  const sumInputs = document.querySelectorAll(".js-kaigyo-shikin-input");
  sumInputs.forEach((input) => {
    input.addEventListener("change", handleKaigyoShikinChange);
  });
}

export function setInitialValueForKaigyoShikin() {
  // 開業資金合計
  let maxRange = 0;
  const kaijoShikinInputs = document.querySelectorAll(".data-sum");
  kaijoShikinInputs.forEach((input) => {
    maxRange += Number(input.value);
  });

  if (maxRange < 100) maxRange = 100;

  // 開業費用のスライダーをセット
  const dataInputs = document.querySelectorAll(".data-input");
  dataInputs.forEach((input) => {
    try {
      const sliderElement = input.parentNode.previousElementSibling.querySelector(
        "input[type='range']"
      );
      sliderElement.value = input.value;
      sliderElement.defaultValue = input.value;
      sliderElement.max = maxRange;
      if (Number(sliderElement.defaultValue) !== 0) {
        if ((Number(sliderElement.defaultValue) * 100) / maxRange + 1 > 100) {
          sliderElement.style.backgroundSize = "100% 100%";
        } else {
          sliderElement.style.backgroundSize =
            (Number(sliderElement.defaultValue) * 100) / maxRange + 1 + "% 100%";
        }
      } else {
        sliderElement.style.backgroundSize = "0% 100%";
      }
    } catch (error) {
      // console.warn(error);
    }
    // text fieldにカンマ区切りでセット
    setDelimitedMoney(input);
  });

  // サイドバーの開業資金等の更新
  setAsideSummary();
}

$(function () {
  if (
    document.getElementById("startCalculationButton") == null &&
    document.getElementById("kaigyohiyo_title_section")
  ) {
    setInitialValueForKaigyoShikin();
    addKaigyoShikinEventListenerAll();
  }

  // 初期状態でのサマリーセクションへのセット
  if (document.getElementById("initFund1")) {
    setInitAsideSummary();

    let automaticInput = $("#uriage_init_title").length ? false : true;

    // 初期状態で自己資金、借入、その他の項目がすべて埋まっていない場合、最初に入力があったタイミングで他の項目を0セットする
    [
      $("#text_jikoshikin"),
      $("#text_kariire"),
      $("#text_kaigyoshikin_sonota"),
    ].forEach((item) => {
      item.on("change", function () {
        if (automaticInput) {
          if ($("#text_jikoshikin").val() === "") {
            $("#text_jikoshikin").val(0);
            $("#jikoshikin").val(0);
          }
          if ($("#text_kariire").val() === "") {
            $("#text_kariire").val(0);
            $("#kariire").val(0);
          }
          if ($("#text_kaigyoshikin_sonota").val() === "") {
            $("#text_kaigyoshikin_sonota").val(0);
            $("#kaigyoshikin_sonota").val(0);
          }
        }
      });
    });

    // 初期状態の項目に対してカンマ区切り処理してサイドカラム反映
    [
      $("#text_jikoshikin"),
      $("#text_kariire"),
      $("#text_kaigyoshikin_sonota"),
      $("#text_sales1"),
    ].forEach((item) => {
      item.on("change", function () {
        updateDelimitedMoney(this, automaticInput);
        setInitAsideSummary();
      });
    });
  }
});
