// チェックボックスとセレクトボックスの複合。'jquery-3.4.1.min.js'が必要。
import { Modal } from 'bootstrap'
$(function() {
  const modalId = "#modal-target";
  const modalContentTargetId = "#modal-content-target";
  const apiUrl = "/api/v1/ankens/";
  var myModal;

  if ($(modalId).length) {
    myModal = new Modal($(modalId), {});
  }
  
  $(".js-open-anken-detail-modal").on('click', function() {
    var ankenId = $(this).data('id');
    var qParams = $(this).data('q-params');

    var url = apiUrl + ankenId;

    if (typeof qParams !== 'undefined' && qParams.length) {
      url = url + '?' + qParams;
    }

    $.get( url, function( data ) {
      $(modalContentTargetId).html(data);
      myModal.toggle();
    });
  });
});