//* 【共通】 ---------------------- *
//* top へ戻る ---------------------- *
$(function() {
  $('#scrollTop').hide();
  $(window).on("scroll", function () {
    if ($(this).scrollTop() > 100) {
      $('#scrollTop').fadeIn('fast');
    } else {
      $('#scrollTop').fadeOut('fast');
    }
    var scrollHeight = $(document).height();
    var scrollPosition = $(window).height() + $(window).scrollTop();
    var footHeight = $('footer').innerHeight();
    if (scrollHeight - scrollPosition <= footHeight) {
      $('#scrollTop').css({
        'position': 'absolute',
      });
      $('.detail-scroll-top').css({
        'bottom': '3rem',
      });
    } else {
      $('#scrollTop').css({
        'position': 'fixed',
      });
      $('.detail-scroll-top').css({
        'bottom': '6rem',
      });
    }
  });
  $('#scrollTop').on("click" , function () {
    $('body,html').animate({
      scrollTop: 0
    }, 100);
    return false;
  });
});

// お知らせ一覧のiframeの高さをload, resize時に取得する
function getIframeHeight () {
  try {
      const getHeight = () => {
        $('#news-frame').height(0);
        $('#news-frame').height($('#news-frame').get(0).contentWindow.document.documentElement.scrollHeight);
      }
      setTimeout(getHeight, 10);
    } catch (e) {
  }
}
$('#news-frame').on('load', function(){
  getIframeHeight();
});
$(window).on('resize', function(){
  if ($('#news-frame').length) {
    getIframeHeight();
  }
});

//* 【事業者画面】 ---------------------- *
//* top メニュー ---------------------- *
/* リサイズ時、メニュー（toggle）を元に戻す挙動 */
$(window).on("resize", function(){
  $('#mainmenuNav').removeClass("collapse");
});
/* オーバーレイ部分クリック時、メニュー（toggle）を元に戻す挙動 */
$('#mainmenuOverlay').on("click" , function() {
  $('#mainmenuNav').removeClass("collapse");
});

//* 追従メニュー（PC） ---------------------- *
$(function() {
  $('#chaseMenu').hide();
  $(window).on("scroll", function () {
    if ($(this).scrollTop() > 550) {
      $('#chaseMenu').fadeIn('fast');
    } else {
      $('#chaseMenu').fadeOut('fast');
    }
  });
  $('#m-chasemenu__button--detailsearch').on("click" , function() {
    $('#mainmenuNav').removeClass("show");
    $('#mainmenuToggler').attr({'aria-expanded' : 'false'});
    if($('#controller-detailsearch').hasClass("ds-open")){
      $('#controller-detailsearch').removeClass("ds-open").toggleClass("ds-open");
    }else{
      $('#controller-detailsearch').toggleClass("ds-open");
    }
    $('body,html').animate({
      scrollTop: 0
    }, 100);
    return false;
  });
});

//* 追従メニュー（モバイル） ---------------------- *
$(function() {
  $('#chaseMenuSmp').hide();
  $('#chaseFooterSp').hide();
  $(window).on("scroll", function () {
    if ($(this).scrollTop() > 100) {
      $('#chaseMenuSmp').fadeIn('fast');
      $('#chaseFooterSp').fadeIn('fast');
    } else {
      $('#chaseMenuSmp').fadeOut('fast');
      $('#chaseFooterSp').fadeOut('fast');
    }
  });
  $('#m-chasemenu--smp__button--detailsearch').on("click" , function() {
    $('#mainmenuNav').removeClass("show");
    $('#mainmenuToggler').attr({'aria-expanded' : 'false'});
    if($('#controller-detailsearch').hasClass("ds-open")){
      $('#controller-detailsearch').removeClass("ds-open").toggleClass("ds-open");
    }else{
      $('#controller-detailsearch').toggleClass("ds-open");
    }
    $('body,html').animate({
      scrollTop: 0
    }, 100);
    return false;
  });
  $('#m-chasemenu--ipad__button--detailsearch').on("click" , function() {
    $('#mainmenuNav').removeClass("show");
    $('#mainmenuToggler').attr({'aria-expanded' : 'false'});
    if($('#controller-detailsearch').hasClass("ds-open")){
      $('#controller-detailsearch').removeClass("ds-open").toggleClass("ds-open");
    }else{
      $('#controller-detailsearch').toggleClass("ds-open");
    }
    $('body,html').animate({
      scrollTop: 0
    }, 100);
    return false;
  });
});

// ファーストビュー（事業者画面）・「地域」のセレクトチェックボックス始動
$('.m-checkbox-ex__select-form').on("click" , function() {
  $(this).parent('.m-checkbox-ex').toggleClass("open");
});
// ファーストビュー（事業者画面）・「地域」のセレクトチェックボックス外をクリックした場合に初期状態に戻す
$(document).on("click" ,function (e) {
  var container = $('.m-checkbox-ex');
  if (container.has(e.target).length === 0)
  {
    container.removeClass("open");
  }
});

// ファーストビュー（事業者画面）・「事業形態」のセレクトボックス始動
$("select").on("click" , function() {
  $(this).parent('.main-firstview__selectiveobj').toggleClass("open");
});
// ファーストビュー（事業者画面）・「事業形態」のセレクトボックスセレクト連動
$(".business-type").on("change" , function() {
  var selection = $(this).find("option:selected").text();
  $('.main-firstview__selected').html(selection);
});
// ページ描画時に選択済みの事業形態をラベルに反映する
$(function() {
  var selectedText = $('.main-firstview__choices.business-type option:selected').text();
  $('.main-firstview__selected').text(selectedText);
  // 初期値が入っている場合、セレクトボックスの色を戻す
  if($('.main-firstview__choices.business-type').val() !== ""){
    $('.main-firstview__selected').removeClass('default');
    $('.checkbox-ex--flat__select.business-type').removeClass('default');
  }
});
$(function() {
  var selectedText = $('.lp-fv-search .main-firstview__choices.business-type option:selected').text();
  $('.lp-fv-search .main-firstview__selected').text(selectedText);
  // 初期値が入っている場合、セレクトボックスの色を戻す
  if($('.lp-fv-search .main-firstview__choices.business-type').val() !== ""){
    $('.lp-fv-search .main-firstview__selected').removeClass('default');
    $('.lp-fv-search .checkbox-ex--flat__select.business-type').removeClass('default');
  }
});
$(function() {
  var selectedText = $('.js-search_submit_button_submit_form_lp_1 .main-firstview__choices.business-type option:selected').text();
  $('.js-search_submit_button_submit_form_lp_1 .main-firstview__selected').text(selectedText);
  // 初期値が入っている場合、セレクトボックスの色を戻す
  if($('.js-search_submit_button_submit_form_lp_1 .main-firstview__choices.business-type').val() !== ""){
    $('.js-search_submit_button_submit_form_lp_1 .main-firstview__selected').removeClass('default');
    $('.js-search_submit_button_submit_form_lp_1 .checkbox-ex--flat__select.business-type').removeClass('default');
  }
});
$(function() {
  var selectedText = $('.js-search_submit_button_submit_form_lp_2 .main-firstview__choices.business-type option:selected').text();
  $('.js-search_submit_button_submit_form_lp_2 .main-firstview__selected').text(selectedText);
  // 初期値が入っている場合、セレクトボックスの色を戻す
  if($('.js-search_submit_button_submit_form_lp_2 .main-firstview__choices.business-type').val() !== ""){
    $('.js-search_submit_button_submit_form_lp_2 .main-firstview__selected').removeClass('default');
    $('.js-search_submit_button_submit_form_lp_2 .checkbox-ex--flat__select.business-type').removeClass('default');
  }
});
// ページ描画時に従業員数、業種、継続年数、資本金額、希望金額に初期値があった場合にセレクトボックスの色を戻す
$(function() {
  $('select.checkbox-ex--flat__select').each(function(){
    if ($(this).val() !== ""){
      $(this).removeClass('default');
    }
  });
});
// ファーストビュー（事業者画面）・「事業形態」のセレクトチェックボックス外をクリックした場合に初期状態に戻す
$(document).on("click" ,function (e) {
  var container = $('.main-firstview__selectiveobj');
  if (container.has(e.target).length === 0)
  {
    container.removeClass("open");
  }
});

// ファーストビュー（事業者画面）の詳細検索始動
$('#result-detailsearch').on("click" , function() {
  $('#controller-detailsearch').addClass("ds-open");
});

// セレクトチェックボックス始動
$('.checkbox-ex__select-form').on("click" , function() {
  //('[class^=checkbox-ex]').removeClass("open");
  if($('[class^=checkbox-ex]').hasClass("open")){
    $('[class^=checkbox-ex]').removeClass("open");
  }else{
    $(this).parent('[class^=checkbox-ex]').toggleClass("open");
  }
});
// セレクトチェックボックス外をクリックした場合に初期状態に戻す
$(document).on("click" ,function (e) {
  var container = $('[class^=checkbox-ex]');
  if (container.has(e.target).length === 0)
  {
    container.removeClass("open");
  }
});
// 他のセレクトボックスを開いた時にセレクトボックスチェックボックスを閉じる
$('.checkbox-ex--flat__select').on('click', function (e) {
  $('[class^=checkbox-ex]').removeClass("open");
});

// 詳細検索画面の「事業形態」・「従業員数」・「業種」・「継続年数」・「資本金額」・「希望金額」・「課題・資金使途」を選択した場合にデフォルト（class="default"）を動かす
$('.checkbox-ex--flat__select').on('change', function (e) {
  if ($(this).val() !== ""){
    $(this).removeClass("default");
  } else {
    $(this).addClass("default");
  }
});

// 絞り込み検索フィールドのメインで使われていない項目に一つでも初期値がセットされている場合、
// 画面描画時に絞り込み検索フィールドを表示した状態にする
$(function() {
  var isDefaultValueSet = hasInputValue();
  var isDetailSearch = $('#is_detail_search').val();
  // 画面サイズが768pxより小さい場合は表示しない
  var windowWidth = $(window).width();
  if ((isDefaultValueSet || isDetailSearch === 'true') && windowWidth >= 768) {
    $('#controller-detailsearch').toggleClass("ds-open");
  }
});

$('.controller-upload__button--delete').on('click', function (e) {
  var url = location.href
  if (url.match(/new/)) {
    window.location.href = url;
  } else {
    window.location.href = url + "/new";
  }
});

// 事業形態がon change時の挙動
$('#detail_search_q_anken_business_type_cd').on('change', function() {
  businessTypeValidation($(this));
});
// ロード時の事業形態の挙動
$(function() {
  if ($('#detail_search_q_anken_business_type_cd').length) {
    businessTypeValidation($('#detail_search_q_anken_business_type_cd'));
  }
});

function businessTypeValidation (element) {
  // 事業形態が「創業前」の時、「従業員数」と「継続年数」を非活性状態とする
  if (element.val() === "創業前") {
    $('#q_anken_employee_number').val("").addClass("default").prop('disabled', true);
    $('#q_anken_business_period_cd').val("").addClass("default").prop('disabled', true);
  } else {
    $('#q_anken_employee_number').prop('disabled', false);
    $('#q_anken_business_period_cd').prop('disabled', false);
  }
  // 事業形態が「法人」または初期値以外の場合、「資本金額」を非活性状態とする
  if (element.val() === "法人" || element.val() === "") {
    $('#q_anken_capital_amount').prop('disabled', false);
  } else {
    $('#q_anken_capital_amount').val("").addClass("default").prop('disabled', true);
  }
}

export function hasInputValue() {
  var isDefaultValueSet = false;
  // 地域(市区町村) checkedCitiesの配列にて判定
  if (typeof checkedCities !== 'undefined' && checkedCities.length !== 0) {
    isDefaultValueSet = true;
  }
  // 従業員数
  if ($('#q_anken_employee_number').val()) {
    isDefaultValueSet = true;
  }
  // 業種分類
  const industoryInputClassName = ".chex-ind"
  var checkboxExIndustory = $(industoryInputClassName).first().closest(".checkbox-ex");
  if (checkboxExIndustory.find('input' + industoryInputClassName + ':checked').length) {
    isDefaultValueSet = true;
  }
  // 継続年数
  if ($('#q_anken_business_period_cd').val()) {
    isDefaultValueSet = true;
  }
  // 資本金額
  if ($('#q_anken_capital_amount').val()) {
    isDefaultValueSet = true;
  }
  // 希望金額
  if ($('#q_required_amount').val()) {
    isDefaultValueSet = true;
  }
  // 課題・資金使途
  const usageInputClassName = ".chex-use";
  var checkboxExUsage = $(usageInputClassName).first().closest(".checkbox-ex--tags");
  if ($(checkboxExUsage).find('input' + usageInputClassName + ':checked').length) {
    isDefaultValueSet = true;
  }
  // 訪問不要
  if ($('input#q_anken_visit_required_unnecessary:checked').length) {
    isDefaultValueSet = true;
  }
  // 担保提供可
  if ($('input#q_tanpo_required_necessary_or_necessary_in_some_cases:checked').length) {
    isDefaultValueSet = true;
  }
  // 弥生製品データ利用
  if ($('input#q_is_yayoi_service_usable_usable:checked').length) {
    isDefaultValueSet = true;
  }
  // 募集終了も含む
  if ($('input#q_including_offering_expired:checked').length) {
    isDefaultValueSet = true;
  }
  // フリーワード
  if ($('#q_keyword').val()) {
    isDefaultValueSet = true;
  }
  return isDefaultValueSet;
}

//詳細ページ（似た資金調達手段「さらに表示」）
$(function() {
  var moreNum = 10;
  $('.m-detail__relation__column:nth-child(n + ' + (moreNum + 2) + ')').addClass('is-hidden');
  $('.m-detail__relation__more').on('click', function() {
    $('.m-detail__relation__column.is-hidden').slice(0, 10).removeClass('is-hidden');
    if ($('.m-detail__relation__column.is-hidden').length == 0) {
      $('.m-detail__relation__more').fadeOut();
    }
  });
});

//詳細ページ（似た資金調達手段「さらに表示」）スマホ画面
$(function() {
  var sp_moreNum = 5;
  $('.m-detail__relation__sp-column:nth-child(n + ' + (sp_moreNum + 1) + ')').addClass('is-hidden');
  $('.m-detail__relation__sp-more').on('click', function() {
    $('.m-detail__relation__sp-column.is-hidden').slice(0, 15).removeClass('is-hidden');
    if ($('.m-detail__relation__sp-column.is-hidden').length == 0) {
      $('.m-detail__relation__sp-more').fadeOut();
    }
  });
});

// お気に入りボタン（各案件詳細ページの追従ボタン）
$(function() {
  $('#favoriteBtn').hide();
  $(window).on("scroll", function () {
    if ($(this).scrollTop() > 100) {
      $('#favoriteBtn').fadeIn('fast');
    } else {
      $('#favoriteBtn').fadeOut('fast');
    }
    var scrollHeight = $(document).height();
    var scrollPosition = $(window).height() + $(window).scrollTop();
    var footHeight = $('footer').innerHeight();
    if (scrollHeight - scrollPosition <= footHeight) {
      $('#favoriteBtn').css({
        'position': 'absolute',
        'bottom': '8rem',
      });
    } else {
      $('#favoriteBtn').css({
        'position': 'fixed',
        'bottom': '11rem',
      });
    }
  });
});

$(function(){
  // ファーストビュー 検索結果数・現在の検索条件
  $('#result-detailsearch').on('click', function(){
    $('#detailsearch-box').slideDown();
    $('#result-box').slideUp();
  });
});

// 「課題・資金使途クリア」ボタンクリック時
$('#clear_usage').on('click', function() {
  $('.chex-use').prop('checked', false);
  $('#after_reset_search_condition').trigger('click');
});

// 追従メニュー(さらに絞り込む) 詳細検索を表示
$(function(){
  $('#m-chasemenu__button--detailsearch').on('click', function(){
    $('#controller-detailsearch').addClass("ds-open");
    $('#result-box').slideUp();
    $('#detailsearch-box').slideDown();
  });
});

// 追従フッター 似た資金調達手段
$(function(){
  $('#chase_footer_related, #chase_footer_related_ipad, #chase_footer_related_sp').on('click', function(){
    const relatedTop = $('#related').offset().top;
    $("body,html").animate({scrollTop: relatedTop});
  });
});