// 「登録情報を反映」ボタンクリック時
$('#reflect_user_data_to_search_condition_button').on('click', function() {
  $('#reflect_user_data_to_search_condition_form_submit_button').trigger('click');
});

// 「条件クリア」ボタンクリック時
$('#clear_search_condition').on('click', function() {
  // 地域
  if (typeof checkedPrefs !== 'undefined') {
    checkedPrefs = [];
  }
  if (typeof checkedCities !== 'undefined') {
    checkedCities = [];
  }
  
  // 従業員数
  $('#q_anken_employee_number').val('');
  // 継続年数
  $('#q_anken_business_period_cd').val('');
  // 資本金額
  $('#q_anken_capital_amount').val('');
  // 上記の3つは事業形態の選択により非活性状態にする場合があるため、事業形態の処理より前に実行する
  
  // 事業形態
  $('.business-type').val('')
  $(".business-type").trigger('change');
  
  // 業種
  $('.chex-ind').prop('checked', false);
  
  // 希望金額
  $('#q_required_amount').val('')
  
  // 課題・資金使途
  $('.chex-use').prop('checked', false);
  
  // 訪問不要
  $('#q_anken_visit_required_unnecessary').prop('checked', false);
  
  // 担保提供可（融資のみ）
  $('#q_tanpo_required_necessary_or_necessary_in_some_cases').prop('checked', false);
  
  // 弥生製品データ利用
  $('#q_is_yayoi_service_usable_usable').prop('checked', false);
  
  // 募集終了も含む
  $('#q_including_offering_expired').prop('checked', false);
  
  // フリーワード
  $('#q_keyword').val('')
  
  $('.checkbox-ex--flat__select').trigger('change');
  $('#after_reset_search_condition').trigger('click');
});

// 外部リンククリック時に実行
$(document).on('click', '.gtm-external-link', function() {
  const id = $(this).data('id');
  if (typeof id !== 'undefined' && id !== '' && id !== null) {
    $.ajax({
      type: "POST",
      url: "/gtm_external_link",
      data: {id: id}
    });
  }
});


import { Modal } from 'bootstrap'
// 未ログイン時お気に入りアイコンクリック時モーダル表示
$(document).on('click', '.js-open_favorite_signup_login_modal', function() {
  const favoriteSignupLoginModal = new Modal(document.getElementById('favoriteSignupLoginModal'));
  favoriteSignupLoginModal.show();
});
// セッション切れリロード後モーダル表示
$(function() {
  if ($('.js-open_session_timeout_modal').length) {
    const sessionTimeoutModal = new Modal(document.getElementById('sessionTimeoutModal'));
    sessionTimeoutModal.show();
  }
  if ($('.js-open_user_info_does_not_exist_modal').length) {
    const userInfoDoesNotExistModal = new Modal(document.getElementById('userInfoDoesNotExistModal'));
    userInfoDoesNotExistModal.show();
  }
});

// 多重モーダル対応
$(function() {
  var myModals = document.querySelectorAll('.modal');

  myModals.forEach(function(myModal) {
    myModal.addEventListener('show.bs.modal', function (event) {
      var zIndex = 1040 + (10 * $('.modal:visible').length);
      $(event.target).css('z-index', zIndex);
      setTimeout(function() {
        $('.modal-backdrop')
          .not('.modal-stack')
          .css('z-index', zIndex - 1)
          .addClass('modal-stack');
      }, 0);
    })
  });

  myModals.forEach(function(myModal) {
    myModal.addEventListener('shown.bs.modal', function (event) {
      event.target.focus();
    })
  });

  myModals.forEach(function(myModal) {
    myModal.addEventListener('hidden.bs.modal', function (event) {
      if ($('.modal:visible').length > 0) {
        var $visibleModalsSortByZIndex = $('.modal:visible').sort(function(a, b) {
          return (parseInt($(a).css('z-index')) < parseInt($(b).css('z-index')) ? 1 : -1);
        });
        if ($visibleModalsSortByZIndex.length > 0) {
          $visibleModalsSortByZIndex[0].focus();
        }
      }
    });
  });
});
