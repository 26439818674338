/*
try {
  let sales1 = 0;
  let sales2 = 0;
  let sales3 = 0;
  let cost1 = 0;
  let cost2 = 0;
  let cost3 = 0;
  let benefit1 = 0;
  let benefit2 = 0;
  let benefit3 = 0;

  let suuchiShikin = 0;
  let suuchiHiyou = 0;

  const salesInput1 = document.getElementById("sales1");
  const salesInput2 = document.getElementById("sales2");
  const salesInput3 = document.getElementById("sales3");
  const costInputs1 = document.querySelectorAll(".suuchiCost1");
  const costInputs2 = document.querySelectorAll(".suuchiCost2");
  const costInputs3 = document.querySelectorAll(".suuchiCost3");
  const benefitInput1 = document.getElementById("benefit1");
  const benefitInput2 = document.getElementById("benefit2");
  const benefitInput3 = document.getElementById("benefit3");
  const suuchiNumBtnSets = document.querySelectorAll(".suuchi-numBtn");
  const shikinInputs = document.querySelectorAll(".suuchiShikin");
  const hiyouInputs = document.querySelectorAll(".suuchiHiyou");

  function handleSalesChange() {
    sales1 = salesInput1.value ? parseInt(salesInput1.value, 10) : 0;
    sales2 = salesInput2.value ? parseInt(salesInput2.value, 10) : 0;
    sales3 = salesInput3.value ? parseInt(salesInput3.value, 10) : 0;
  }
  function handleBenefitChange() {
    benefit1 = benefitInput1.value ? parseInt(benefitInput1.value, 10) : 0;
    benefit2 = benefitInput2.value ? parseInt(benefitInput2.value, 10) : 0;
    benefit3 = benefitInput3.value ? parseInt(benefitInput3.value, 10) : 0;
  }
  function handleCostChange() {
    cost1 = 0;
    cost2 = 0;
    cost3 = 0;
    costInputs1.forEach((input) => {
      const inputValue = input.value ? parseInt(input.value, 10) : 0;
      cost1 += inputValue;
    });
    costInputs2.forEach((input) => {
      const inputValue = input.value ? parseInt(input.value, 10) : 0;
      cost2 += inputValue;
    });
    costInputs3.forEach((input) => {
      const inputValue = input.value ? parseInt(input.value, 10) : 0;
      cost3 += inputValue;
    });
  }
  function handleShikinChange() {
    suuchiShikin = 0;
    shikinInputs.forEach((input) => {
      const inputValue = input.value ? parseInt(input.value, 10) : 0;
      suuchiShikin += inputValue;
    });
  }
  function handleHiyouChange() {
    suuchiHiyou = 0;
    hiyouInputs.forEach((input) => {
      const inputValue = input.value ? parseInt(input.value, 10) : 0;
      suuchiHiyou += inputValue;
    });
  }

  function calcBenefit() {
    benefitInput1.value = sales1 - cost1;
    benefitInput2.value = sales2 - cost2;
    benefitInput3.value = sales3 - cost3;
    handleBenefitChange();
  }

  function calcCosts() {
    if (cost1 !== 0) {
      costInputs1.forEach((input) => {
        const inputValue = input.value ? parseInt(input.value, 10) : 0;
        const inputRatio = inputValue / cost1;
        const newCost = sales1 - benefit1;
        input.value = Math.round(newCost * inputRatio);
      });
    }
    if (cost2 !== 0) {
      costInputs2.forEach((input) => {
        const inputValue = input.value ? parseInt(input.value, 10) : 0;
        const inputRatio = inputValue / cost2;
        const newCost = sales2 - benefit2;
        input.value = Math.round(newCost * inputRatio);
      });
    }
    if (cost3 !== 0) {
      costInputs3.forEach((input) => {
        const inputValue = input.value ? parseInt(input.value, 10) : 0;
        const inputRatio = inputValue / cost3;
        const newCost = sales3 - benefit3;
        input.value = Math.round(newCost * inputRatio);
      });
    }
    handleCostChange();
  }

  function setSimulationBar(
    sales,
    cost,
    benefit,
    barId,
    salesId,
    costId,
    benefitId
  ) {
    const costRatio = (cost / sales) * 100;
    const targetBar = document.querySelectorAll(`.${barId}`);
    const salesPara = document.querySelectorAll(`.${salesId}`);
    const costPara = document.querySelectorAll(`.${costId}`);
    const benefitPara = document.querySelectorAll(`.${benefitId}`);
    const minusCaution = document.getElementById("suuchiCaution");
    const cautionAreasKaigyou = document.querySelectorAll(
      ".cautionAreaKaigyou"
    );
    const cautionAreas1 = document.querySelectorAll(".cautionArea1");
    const cautionAreas2 = document.querySelectorAll(".cautionArea2");
    const cautionAreas3 = document.querySelectorAll(".cautionArea3");

    targetBar.forEach((element) => {
      element.style.width = `${costRatio}%`;
      if (benefit < 0) {
        element.style.width = "100%";
        element.style.backgroundColor = "#FF2E00";
      } else {
        element.style.width = `${costRatio}%`;
        element.style.backgroundColor = "#edf2f9";
      }
    });

    salesPara.forEach((element) => (element.textContent = sales));
    costPara.forEach((element) => (element.textContent = cost));
    benefitPara.forEach((element) => (element.textContent = benefit));

    if (suuchiShikin - suuchiHiyou < 0) {
      cautionAreasKaigyou.forEach(
        (element) => (element.style.color = "#FF2E00")
      );
    } else {
      cautionAreasKaigyou.forEach(
        (element) => (element.style.color = "#436fc0")
      );
    }
    if (benefit1 < 0) {
      cautionAreas1.forEach((element) => (element.style.color = "#FF2E00"));
    } else {
      cautionAreas1.forEach((element) => (element.style.color = "#436fc0"));
    }
    if (benefit2 < 0) {
      cautionAreas2.forEach((element) => (element.style.color = "#FF2E00"));
    } else {
      cautionAreas2.forEach((element) => (element.style.color = "#436fc0"));
    }
    if (benefit3 < 0) {
      cautionAreas3.forEach((element) => (element.style.color = "#FF2E00"));
    } else {
      cautionAreas3.forEach((element) => (element.style.color = "#436fc0"));
    }

    if (
      suuchiShikin - suuchiHiyou < 0 ||
      benefit1 < 0 ||
      benefit2 < 0 ||
      benefit3 < 0
    ) {
      minusCaution.classList.add("display");
    } else {
      minusCaution.classList.remove("display");
    }
  }

  salesInput1.addEventListener("change", function () {
    handleSalesChange();
    calcBenefit();
    setSimulationBar(
      sales1,
      cost1,
      benefit1,
      "suuchiBar1",
      "suuchiSalesDisplay1",
      "suuchiCostDisplay1",
      "suuchiBenefitDisplay1"
    );
  });
  salesInput2.addEventListener("change", function () {
    handleSalesChange();
    calcBenefit();
    setSimulationBar(
      sales2,
      cost2,
      benefit2,
      "suuchiBar2",
      "suuchiSalesDisplay2",
      "suuchiCostDisplay2",
      "suuchiBenefitDisplay2"
    );
  });
  salesInput3.addEventListener("change", function () {
    handleSalesChange();
    calcBenefit();
    setSimulationBar(
      sales3,
      cost3,
      benefit3,
      "suuchiBar3",
      "suuchiSalesDisplay3",
      "suuchiCostDisplay3",
      "suuchiBenefitDisplay3"
    );
  });
  benefitInput1.addEventListener("change", function () {
    handleBenefitChange();
    calcCosts();
    setSimulationBar(
      sales1,
      cost1,
      benefit1,
      "suuchiBar1",
      "suuchiSalesDisplay1",
      "suuchiCostDisplay1",
      "suuchiBenefitDisplay1"
    );
  });
  benefitInput2.addEventListener("change", function () {
    handleBenefitChange();
    calcCosts();
    setSimulationBar(
      sales2,
      cost2,
      benefit2,
      "suuchiBar2",
      "suuchiSalesDisplay2",
      "suuchiCostDisplay2",
      "suuchiBenefitDisplay2"
    );
  });
  benefitInput3.addEventListener("change", function () {
    handleBenefitChange();
    calcCosts();
    setSimulationBar(
      sales3,
      cost3,
      benefit3,
      "suuchiBar3",
      "suuchiSalesDisplay3",
      "suuchiCostDisplay3",
      "suuchiBenefitDisplay3"
    );
  });
  costInputs1.forEach((input) => {
    input.addEventListener("change", function () {
      handleCostChange();
      calcBenefit();
      setSimulationBar(
        sales1,
        cost1,
        benefit1,
        "suuchiBar1",
        "suuchiSalesDisplay1",
        "suuchiCostDisplay1",
        "suuchiBenefitDisplay1"
      );
    });
  });
  costInputs2.forEach((input) => {
    input.addEventListener("change", function () {
      handleCostChange();
      calcBenefit();
      setSimulationBar(
        sales2,
        cost2,
        benefit2,
        "suuchiBar2",
        "suuchiSalesDisplay2",
        "suuchiCostDisplay2",
        "suuchiBenefitDisplay2"
      );
    });
  });
  costInputs3.forEach((input) => {
    input.addEventListener("change", function () {
      handleCostChange();
      calcBenefit();
      setSimulationBar(
        sales3,
        cost3,
        benefit3,
        "suuchiBar3",
        "suuchiSalesDisplay3",
        "suuchiCostDisplay3",
        "suuchiBenefitDisplay3"
      );
    });
  });
  shikinInputs.forEach((input) => {
    input.addEventListener("change", function () {
      handleShikinChange();
      setSimulationBar(
        suuchiShikin,
        suuchiHiyou,
        suuchiShikin - suuchiHiyou,
        "kaigyouBar",
        "suuchiShikin",
        "suuchiHiyou",
        "suuchiShikinzan"
      );
    });
  });
  hiyouInputs.forEach((input) => {
    input.addEventListener("change", function () {
      handleHiyouChange();
      setSimulationBar(
        suuchiShikin,
        suuchiHiyou,
        suuchiShikin - suuchiHiyou,
        "kaigyouBar",
        "suuchiShikin",
        "suuchiHiyou",
        "suuchiShikinzan"
      );
    });
  });

  suuchiNumBtnSets.forEach((numBtnSet) => {
    var suuchiInput = numBtnSet.querySelector(".suuchiInput");
    var suuchiPlus = numBtnSet.querySelector(".suuchiPlus");
    var suuchiMinus = numBtnSet.querySelector(".suuchiMinus");

    suuchiPlus.addEventListener("click", function () {
      handleSalesChange();
      handleBenefitChange();
      handleCostChange();
      handleShikinChange();
      handleHiyouChange();
      if (
        suuchiInput.classList.contains("suuchiCost1") ||
        suuchiInput.classList.contains("suuchiCost2") ||
        suuchiInput.classList.contains("suuchiCost3") ||
        suuchiInput.id === "sales1" ||
        suuchiInput.id === "sales2" ||
        suuchiInput.id === "sales3"
      ) {
        calcBenefit();
      } else if (
        suuchiInput.id === "benefit1" ||
        suuchiInput.id === "benefit2" ||
        suuchiInput.id === "benefit3"
      ) {
        calcCosts();
      }
      setSimulationBar(
        sales1,
        cost1,
        benefit1,
        "suuchiBar1",
        "suuchiSalesDisplay1",
        "suuchiCostDisplay1",
        "suuchiBenefitDisplay1"
      );
      setSimulationBar(
        sales2,
        cost2,
        benefit2,
        "suuchiBar2",
        "suuchiSalesDisplay2",
        "suuchiCostDisplay2",
        "suuchiBenefitDisplay2"
      );
      setSimulationBar(
        sales3,
        cost3,
        benefit3,
        "suuchiBar3",
        "suuchiSalesDisplay3",
        "suuchiCostDisplay3",
        "suuchiBenefitDisplay3"
      );
      setSimulationBar(
        suuchiShikin,
        suuchiHiyou,
        suuchiShikin - suuchiHiyou,
        "kaigyouBar",
        "suuchiShikin",
        "suuchiHiyou",
        "suuchiShikinzan"
      );
    });
    suuchiMinus.addEventListener("click", function () {
      handleSalesChange();
      handleBenefitChange();
      handleCostChange();
      handleShikinChange();
      handleHiyouChange();
      if (
        suuchiInput.classList.contains("suuchiCost1") ||
        suuchiInput.classList.contains("suuchiCost2") ||
        suuchiInput.classList.contains("suuchiCost3") ||
        suuchiInput.id === "sales1" ||
        suuchiInput.id === "sales2" ||
        suuchiInput.id === "sales3"
      ) {
        calcBenefit();
      } else if (
        suuchiInput.id === "benefit1" ||
        suuchiInput.id === "benefit2" ||
        suuchiInput.id === "benefit3"
      ) {
        calcCosts();
      }
      setSimulationBar(
        sales1,
        cost1,
        benefit1,
        "suuchiBar1",
        "suuchiSalesDisplay1",
        "suuchiCostDisplay1",
        "suuchiBenefitDisplay1"
      );
      setSimulationBar(
        sales2,
        cost2,
        benefit2,
        "suuchiBar2",
        "suuchiSalesDisplay2",
        "suuchiCostDisplay2",
        "suuchiBenefitDisplay2"
      );
      setSimulationBar(
        sales3,
        cost3,
        benefit3,
        "suuchiBar3",
        "suuchiSalesDisplay3",
        "suuchiCostDisplay3",
        "suuchiBenefitDisplay3"
      );
      setSimulationBar(
        suuchiShikin,
        suuchiHiyou,
        suuchiShikin - suuchiHiyou,
        "kaigyouBar",
        "suuchiShikin",
        "suuchiHiyou",
        "suuchiShikinzan"
      );
    });
  });
} catch (error) {
  console.warn("このページには数値ボタンはありません" + error);
}
*/

//スクロールによるグラフ表示・非表示
try {
  const suuchiGraph = document.getElementById("suuchiGraph");
  const suuchiGraphScroll = document.getElementById("suuchiGraphScroll");

  window.onscroll = function () {
    let scrollY = window.scrollY;
    if (suuchiGraph && suuchiGraphScroll) {
      if (scrollY > 400) {
        suuchiGraph.style.display = "none";
        suuchiGraphScroll.style.display = "block";
      } else {
        suuchiGraph.style.display = "block";
        suuchiGraphScroll.style.display = "none";
      }
    }
  };
} catch (error) {
  console.warn("このページにはグラフはありません" + error);
}

export function handleKaigyoShikinSetSimulationBar(kaigyoShikin, kaigyoHiyo, kaigyoShikinZan) {
  const profit1y = null;
  const profit2y = null;
  const profit3y = null;

  // 開業資金・開業費用
  setSimulationBar(
    kaigyoShikin,
    kaigyoHiyo,
    kaigyoShikinZan,
    "kaigyouBar",
    "suuchiShikin",
    "suuchiHiyou",
    "suuchiShikinzan",
    kaigyoShikin,
    kaigyoHiyo,
    profit1y,
    profit2y,
    profit3y
  );
}

export function handleUntenShikinSetSimulationBar(
  sales1y, sales2y, sales3y,
  cost1y, cost2y, cost3y,
  profit1y, profit2y, profit3y
) {
  const kaigyoShikin = null;
  const kaigyoHiyo = null;

  // 売上・運転資金 1年目
  setSimulationBar(
    sales1y,
    cost1y,
    profit1y,
    "suuchiBar1",
    "suuchiSalesDisplay1",
    "suuchiCostDisplay1",
    "suuchiBenefitDisplay1",
    kaigyoShikin,
    kaigyoHiyo,
    profit1y,
    profit2y,
    profit3y
  );

  // 売上・運転資金 2年目
  setSimulationBar(
    sales2y,
    cost2y,
    profit2y,
    "suuchiBar2",
    "suuchiSalesDisplay2",
    "suuchiCostDisplay2",
    "suuchiBenefitDisplay2",
    kaigyoShikin,
    kaigyoHiyo,
    profit1y,
    profit2y,
    profit3y
  );

  // 売上・運転資金 3年目
  setSimulationBar(
    sales3y,
    cost3y,
    profit3y,
    "suuchiBar3",
    "suuchiSalesDisplay3",
    "suuchiCostDisplay3",
    "suuchiBenefitDisplay3",
    kaigyoShikin,
    kaigyoHiyo,
    profit1y,
    profit2y,
    profit3y
  );
}

function setSimulationBar(
  sales,
  cost,
  benefit,
  barId,
  salesId,
  costId,
  benefitId,
  suuchiShikin,
  suuchiHiyou,
  benefit1,
  benefit2,
  benefit3
) {
  const costRatio = (cost / sales) * 100;
  const targetBar = document.querySelectorAll(`.${barId}`);
  const salesPara = document.querySelectorAll(`.${salesId}`);
  const costPara = document.querySelectorAll(`.${costId}`);
  const benefitPara = document.querySelectorAll(`.${benefitId}`);
  const cautionAreasKaigyou = document.querySelectorAll(
    ".cautionAreaKaigyou"
  );
  const cautionAreas1 = document.querySelectorAll(".cautionArea1");
  const cautionAreas2 = document.querySelectorAll(".cautionArea2");
  const cautionAreas3 = document.querySelectorAll(".cautionArea3");

  targetBar.forEach((element) => {
    element.style.width = `${costRatio}%`;
    if (benefit < 0) {
      element.style.width = "100%";
      element.style.backgroundColor = "#FF2E00";
    } else {
      element.style.width = `${costRatio}%`;
      element.style.backgroundColor = "#edf2f9";
    }
  });

  salesPara.forEach((element) => (element.textContent = sales.toLocaleString('ja-JP')));
  costPara.forEach((element) => (element.textContent = cost.toLocaleString('ja-JP')));
  benefitPara.forEach((element) => (element.textContent = benefit.toLocaleString('ja-JP')));

  if (suuchiShikin != null && suuchiHiyou != null) {
    if (suuchiShikin - suuchiHiyou < 0) {
      cautionAreasKaigyou.forEach(
        (element) => (element.style.color = "#FF2E00")
      );
    } else {
      cautionAreasKaigyou.forEach(
        (element) => (element.style.color = "#436fc0")
      );
    }
  }

  if (benefit1 != null) {
    if (benefit1 < 0) {
      cautionAreas1.forEach((element) => (element.style.color = "#FF2E00"));
    } else {
      cautionAreas1.forEach((element) => (element.style.color = "#436fc0"));
    }
  }
  if (benefit2 != null) {
    if (benefit2 < 0) {
      cautionAreas2.forEach((element) => (element.style.color = "#FF2E00"));
    } else {
      cautionAreas2.forEach((element) => (element.style.color = "#436fc0"));
    }
  }
  if (benefit3 != null) {
    if (benefit3 < 0) {
      cautionAreas3.forEach((element) => (element.style.color = "#FF2E00"));
    } else {
      cautionAreas3.forEach((element) => (element.style.color = "#436fc0"));
    }
  }
}
