$(function() {
  const copySpModal = document.getElementById("copy-sp");
  if (copySpModal) {
    copySpModal.addEventListener('show.bs.modal', event => {
      const button = event.relatedTarget;
      const copyText = button.getAttribute('data-bs-copy-text');
      const copyTarget = copySpModal.querySelector("#copyTarget2");
      copyTarget.textContent = copyText;
    });
  }
});
