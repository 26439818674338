// 創業計画書の入力フォーム上でエンターを押してもサブミットしない
$(function() {
  $("#sogyo_keikaku_form").on("keypress", function (event) {
    var isTextarea = false;
    $(".js-textarea").each(function(){
      if($(event.target).attr('id') == $(this).attr('id')) {
        isTextarea = true;
      }
    });

    var keyPressed = event.keyCode || event.which;
    if (!isTextarea && keyPressed === 13) {
        event.preventDefault();
        return false;
    }
  });
});

$(function() {
  $(".js-validate").on('input', function (e) {
    let isValid = true;
    const targetName = e.target.dataset.targetName;
    const targetViewType = e.target.dataset.targetViewType;
    let targetCharCount = parseInt(e.target.dataset.targetCharCount)
    $('[data-target-name="' + targetName + '"]').each(function(){
      const charCount = parseInt(this.dataset.targetCharCount)
      const textLength = $(this).val().replace(/\r?\n/g, '');
      if(textLength.length > charCount) {
        isValid = false;
        targetCharCount = charCount;
      }
    });
    if (targetViewType == 'mobile_tablet') {
      if(isValid) {
        $("#" + targetName + "-error" ).removeClass("d-block");
      } else {
        $("#" + targetName + "-error" ).html("このエリアの文字数の目安は" + targetCharCount + "文字です。<br>文字数をオーバーすると、エクセルできれいに表示されない可能性があります。");
        $("#" + targetName + "-error" ).addClass("d-block");
      }
    } else {
      if(isValid) {
        $("#" + targetName + "-error" ).removeClass("d-block");
      } else {
        if(targetName == "sogyodoki"){
          $("#" + targetName + "-error" ).text("目安の１行あたり全角文字数 " + targetCharCount + " を超えて入力されている行があります。あまり文字数が増えると読みにくくなりますのでご注意ください");
        } else {
          $("#" + targetName + "-error" ).text("目安の全角文字数 " + targetCharCount + " を超えて入力されています。あまり文字数が増えると読みにくくなりますのでご注意ください");
        }
        $("#" + targetName + "-error" ).addClass("d-block");
      }
    }
  });
  $(".js-validate-textarea").on('input', function (e) {
    let isValid = true;
    const targetName = e.target.dataset.targetName;
    const targetViewType = e.target.dataset.targetViewType;
    let targetCharCount = parseInt(e.target.dataset.targetCharCount);
    $('[data-target-name="' + targetName + '"]').each(function(){
      const charCount = parseInt(this.dataset.targetCharCount)
      const texts = $(this).val().split(/\n/);
      texts.forEach(text => {
        if(text.length > charCount) {
          isValid = false;
        }
      });
    });
    if (targetViewType == 'mobile_tablet') {
      if(isValid) {
        $("#" + targetName + "-error" ).removeClass("d-block");
      } else {
        if(targetName == "sogyodoki"){
          $("#" + targetName + "-error" ).html("このエリアの文字数の目安は1行" + targetCharCount + "文字" + (targetCharCount * 4) + "文字です。<br>文字数をオーバーすると、エクセルできれいに表示されない可能性があります。");
        } else {
          $("#" + targetName + "-error" ).html("このエリアの文字数の目安は" + targetCharCount + "文字です。<br>文字数をオーバーすると、エクセルできれいに表示されない可能性があります。");
        }
        $("#" + targetName + "-error" ).addClass("d-block");
      }
    } else {
      if(isValid) {
        $("#" + targetName + "-error" ).removeClass("d-block");
      } else {
        $("#" + targetName + "-error" ).text("目安の１行あたり全角文字数 " + targetCharCount + " を超えて入力されている行があります。あまり文字数が増えると読みにくくなりますのでご注意ください");
        $("#" + targetName + "-error" ).addClass("d-block");
      }
    }
  });

  $(".js-validate").trigger('input');
  $(".js-validate-textarea").trigger('input');
});

// 知的財産権等
// 特になしをクリックしたら「申請中」「登録済み」からチェックを外す
$(function() {
  $("#select_chizai1").on('click', function (e) {
    $("#select_chizai3").prop('checked', false);
    $("#select_chizai4").prop('checked', false);
  });
});

// 人数入力
$(function() {
  $(".js-input-people").on('change', function (e) {
    SogyoKeikakuUpdateDelimitedMoney(this);
  });
});

// パーセント入力
// 0~100。100より大きい数値が入力された場合は100をセット。全角は半角に。マイナス入力はマイナスを削除してプラスにする。数値以外が入力された場合削除。
$(function() {
  $(".input-percent").on('change', function (e) {
    let result = $(this).val();
    // 全角数字の変換
    result = result.replace(/[０-９]/g, function(s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
    // ハイフンやマイナスがある場合は削除する
    "－‐−‒—–―ーｰ─━一-".split("").forEach((hyphen) => {
      result = result.replace(hyphen, "");
    });

    // 100より大きい数値が入力された場合は100をセット
    if (Number.isFinite(parseInt(result))) {
      if (result > 100) result = 100;
    } else {
      result = "";
    }

    $(this).val(result);
  });
});

export function calcSection7() {
  setSetsubiShikinSogaku();
  setUntenShikinSogaku();
  setHitsuyounaShikinSogaku();
  setChotatsuHoho();
  checkSougakuAndSetMessage();
}

// レンダリング後にhidden fieldからtext fieldへの初期セット
$(function() {
  $(".js-sogyo-keikaku-set-delimited-money").each(function(){
    if (this.value !== "") {
      // hidde fieldからtext fieldに値をコピー
      SogyoKeikakuSetDelimitedMoney(this);
    }
  });
});

// 入力フォームの中身のチェック&変更
$(function() {
  // ロード時に初期計算を行う
  calcSection7();

  // on-change時の処理
  $(".inputMoney").on('change', function (e) {
    // text fieldをカンマ区切り&hidden fieldへのセット
    SogyoKeikakuUpdateDelimitedMoney(this);

    // 設備資金総額または運転資金が変更の場合
    if ($(this).attr("class").includes("setsubiShikin") || $(this).attr("class").includes("untenShikin")) {
      // 設備資金総額の計算
      setSetsubiShikinSogaku();
      // 運転資金総額の計算
      setUntenShikinSogaku();
      // 必要な資金の計算
      setHitsuyounaShikinSogaku();
      // 必要な資金と調達の方法のチェック
      checkSougakuAndSetMessage();
    } else if ($(this).attr("class").includes("chotatsuHoho")){
      // 調達の方法の合計
      setChotatsuHoho();
      // 必要な資金と調達の方法のチェック
      checkSougakuAndSetMessage();
    }
  });
});

function sanitizeNumber(element) {
  let result = $(element).val()
  // 全角数字の変換
  result = result.replace(/[０-９]/g, function(s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
  });
  // ハイフンやマイナスがある場合は削除する
  "－‐−‒—–―ーｰ─━一-".split("").forEach((hyphen) => {
    result = result.replace(hyphen, "")
  });

  // 数値変換出来ない場合は""に置換
  result = Number.isFinite(parseInt(result)) ? result : ""

  // 100億以上はトリムする
  if (result.length > 9) result = result.slice(0, 9)

  $(element).val(result)
}

function sanitizeMoney(element) {
  let result = $(element).val()
  // 全角数字の変換
  result = result.replace(/[０-９]/g, function(s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
  });
  // ハイフンやマイナスがある場合は削除する
  "－‐−‒—–―ーｰ─━一-,，".split("").forEach((hyphen) => {
    result = result.replace(hyphen, "")
  });

  // 数値変換出来ない場合は0に置換
  result = Number.isFinite(parseInt(result)) ? result : 0

  // 100億以上はトリムする
  if (result.length > 5) result = result.slice(0, 5)

  return parseInt(result)
}

// 設備資金総額の計算
function setSetsubiShikinSogaku() {
  let result = 0;
  $(".setsubiShikin").each(function (){
    if (Number.isFinite(parseInt($(this).val()))){
      result += parseInt($(this).val().replace(/,/, ''));
    }
  })
  $("#setsubiShikinSogaku").text(result.toLocaleString('ja-JP'));
}

function setUntenShikinSogaku() {
  let result = 0;
  $(".untenShikin").each(function (){
    if (Number.isFinite(parseInt($(this).val()))){
      result += parseInt($(this).val().replace(/,/, ''));
    }
  })
  $("#untenShikinSogaku").text(result.toLocaleString('ja-JP'));
}

function setHitsuyounaShikinSogaku(){
  let result = 0;
  if (Number.isFinite(parseInt($("#setsubiShikinSogaku").text()))){
    result += parseInt($("#setsubiShikinSogaku").text().replace(/,/, ''));
  }
  if (Number.isFinite(parseInt($("#untenShikinSogaku").text()))){
    result += parseInt($("#untenShikinSogaku").text().replace(/,/, ''));
  }
  $("#hitsuyounaShikinSogaku").text(result.toLocaleString('ja-JP'));
}

function checkSougakuAndSetMessage(){
  const hitsuyounaShikinSogaku = Number.isFinite(parseInt($("#hitsuyounaShikinSogaku").text().replace(",", ""))) ? parseInt($("#hitsuyounaShikinSogaku").text().replace(",", "")) : 0;
  const chotatsuHohoSogaku = Number.isFinite(parseInt($("#chotatsuHohoSogaku").text().replace(",", ""))) ? parseInt($("#chotatsuHohoSogaku").text().replace(",", "")) : 0;
  if (hitsuyounaShikinSogaku === chotatsuHohoSogaku) {
    $(".main-form__feedback").removeClass("d-none");
    $(".main-form__feedback--error").addClass("d-none");
    $("#totalHitsuyouShikin").removeClass("unmatch");
    $("#totalChotatsuHoHo").removeClass("unmatch");
    $("#hitsuyounaShikinSogakuErr").addClass("d-none");
    $("#chotatsuHohoSogakuErr").addClass("d-none");
    $("#formAmmount").removeClass("error");
  } else {
    $(".main-form__feedback").addClass("d-none");
    $(".main-form__feedback--error").removeClass("d-none");
    $("#totalHitsuyouShikin").addClass("unmatch");
    $("#totalChotatsuHoHo").addClass("unmatch");
    $("#hitsuyounaShikinSogakuErr").removeClass("d-none");
    $("#chotatsuHohoSogakuErr").removeClass("d-none");
    $("#formAmmount").addClass("error");
  }
  setInvalidToHitsuyoShikinAndChotatsuHoho(hitsuyounaShikinSogaku === chotatsuHohoSogaku);
  setDisabledToSogyoBtnNext();
  changeColorHikakuShikinSp(hitsuyounaShikinSogaku === chotatsuHohoSogaku);
  setReturnToContentsBtnPaddingBottom();
}

function setDisabledToSogyoBtnNext() {
  if ($("#sogyoBtnNext").length) {
    if ($("#sogyoForm7").hasClass("display") && !$("#feedbackError").hasClass("d-none")) {
      $("#sogyoBtnNext").prop("disabled", true);
    } else {
      $("#sogyoBtnNext").prop("disabled", false);
    }
  }
}

function changeColorHikakuShikinSp(isValid) {
  if ($(".js-hikaku_shikin_sp").length) {
    if (isValid) {
      $(".js-hikaku_shikin_sp").removeClass("unmatch");
    } else {
      $(".js-hikaku_shikin_sp").addClass("unmatch");
    }
  }
}

function setInvalidToHitsuyoShikinAndChotatsuHoho(isValid) {
  $(".setsubiShikin").each(function (){
    $(this).removeClass("is-invalid");
    if ($(this).val() && !isValid){
      $(this).addClass("is-invalid");
    }
  })
  $(".untenShikin").each(function (){
    $(this).removeClass("is-invalid");
    if ($(this).val() && !isValid){
      $(this).addClass("is-invalid");
    }
  })
  $(".chotatsuHoho").each(function (){
    $(this).removeClass("is-invalid");
    if ($(this).val() && !isValid){
      $(this).addClass("is-invalid");
    }
  })
}

function setChotatsuHoho(){
  let result = 0;
  $(".chotatsuHoho").each(function (){
    if (Number.isFinite(parseInt($(this).val()))){
      result += parseInt($(this).val().replace(/,/, ''));
    }
  })
  $("#chotatsuHohoSogaku").text(result.toLocaleString('ja-JP'));
}

// 過去の事業経験、資格有無、知財有無
// 未選択中のフィールドをreadonlyにする(DBには保存したいため)
$(function() {
  toggleJigyoKeiken();
  $('input[name="jfc_template[jigyokeiken]"]').on('change', function (e) {
    toggleJigyoKeiken();
  });
  toggleShikakuUmu();
  $('input[name="jfc_template[shikaku_umu]"]').on('change', function (e) {
    toggleShikakuUmu();
  });
  toggleChizaiUmu();
  $('input[name="jfc_template[chizai_umu]"]').on('change', function (e) {
    toggleChizaiUmu();
  }); 
});

function toggleJigyoKeiken() {
  const checkedId = $('input[name="jfc_template[jigyokeiken]"]:checked').attr('id')
  // if 事業を経営していたことはない
  if (checkedId == "select_business1") {
    $('input[name="jfc_template[keizokujigyonaiyo]"]').prop('readonly', true);
    disableSelectField($('select[name="jfc_template[yametajiki_nen]"]'));
    disableSelectField($('select[name="jfc_template[yametajiki_getsu]"]'));
  } else if (checkedId == "select_business2") {
    $('input[name="jfc_template[keizokujigyonaiyo]"]').prop('readonly', false);
    disableSelectField($('select[name="jfc_template[yametajiki_nen]"]'));
    disableSelectField($('select[name="jfc_template[yametajiki_getsu]"]'));
  } else if (checkedId == "select_business3") {
    $('input[name="jfc_template[keizokujigyonaiyo]"]').prop('readonly', true);
    enableSelectField($('select[name="jfc_template[yametajiki_nen]"]'));
    enableSelectField($('select[name="jfc_template[yametajiki_getsu]"]'));
  } else {
    $('input[name="jfc_template[keizokujigyonaiyo]"]').prop('readonly', false);
    enableSelectField($('select[name="jfc_template[yametajiki_nen]"]'));
    enableSelectField($('select[name="jfc_template[yametajiki_getsu]"]'));
  }
}

function toggleShikakuUmu() {
  const checkedId = $('input[name="jfc_template[shikaku_umu]"]:checked').attr('id')
  if (checkedId == "select_shikaku1") {
    $('input[name="jfc_template[shikakumei]"]').prop('readonly', true);
    $('input[name="jfc_template[shikakubango]"]').prop('readonly', true);
    disableSelectField($('select[name="jfc_template[shikakushutoku_nen]"]'));
    disableSelectField($('select[name="jfc_template[shikakushutoku_getsu]"]'));
  } else if (checkedId == "select_shikaku2") {
    $('input[name="jfc_template[shikakumei]"]').prop('readonly', false);
    $('input[name="jfc_template[shikakubango]"]').prop('readonly', false);
    enableSelectField($('select[name="jfc_template[shikakushutoku_nen]"]'));
    enableSelectField($('select[name="jfc_template[shikakushutoku_getsu]"]'));
  } else {
    $('input[name="jfc_template[shikakumei]"]').prop('readonly', false);
    $('input[name="jfc_template[shikakubango]"]').prop('readonly', false);
    enableSelectField($('select[name="jfc_template[shikakushutoku_nen]"]'));
    enableSelectField($('select[name="jfc_template[shikakushutoku_getsu]"]'));
  }
}

function toggleChizaiUmu() {
  const checkedId = $('input[name="jfc_template[chizai_umu]"]:checked').attr('id')
  if (checkedId == "select_chizai1") {
    $('input[name="jfc_template[chizai_naiyo]"]').prop('readonly', true);
    $('input[name="jfc_template[chizai_jokyo]"]').prop('disabled', true);
  } else if (checkedId == "select_chizai2") {
    $('input[name="jfc_template[chizai_naiyo]"]').prop('readonly', false);
    $('input[name="jfc_template[chizai_jokyo]"]').prop('disabled', false);
  } else {
    $('input[name="jfc_template[chizai_naiyo]"]').prop('readonly', false);
    $('input[name="jfc_template[chizai_jokyo]"]').prop('disabled', false);
  }
}

function disableSelectField(jqSelectElm) {
  jqSelectElm.addClass('disabled')
  jqSelectElm.children().each((i, opt)=> {
    if(opt.selected) {
      opt.disabled = false;
    } else {
      opt.disabled = true;
    }
  });
}

function enableSelectField(jqSelectElm) {
  jqSelectElm.removeClass('disabled')
  jqSelectElm.children().each((i, opt)=> {
    opt.disabled = false;
  });
}

function SogyoKeikakuUpdateDelimitedMoney(textFieldElement) {
  // hidden fieldへ値をセット
  const hiddenElementId = textFieldElement.dataset.target;
  const hiddenElement = document.getElementById(hiddenElementId);
  hiddenElement.value = sanitizeMoney(textFieldElement);

  // text fieldにカンマを付与
  $(textFieldElement).val(sanitizeMoney(textFieldElement).toLocaleString('ja-JP'));
}

function SogyoKeikakuSetDelimitedMoney(hiddenElement) {
  // hidden fieldからtext fieldに値をコピー
  const inputElementId = "text_" + hiddenElement.id;
  const inputElement = document.getElementById(inputElementId);
  inputElement.value = parseInt(hiddenElement.value).toLocaleString('ja-JP');
}

function setSogyoKeikakuMainInnerMinHeight() {
  if ($("#sogyoKeikakuMainInner").length) {
    const offsetTop = $("#sogyoKeikakuMainInner").offset().top;
    const value = `calc(100vh - ${offsetTop}px)`;
    $("#sogyoKeikakuMainInner").css("min-height", value);
  }
}

function setReturnToContentsBtnPaddingBottom() {
  if (($("#sogyoSticky.display").length || $("#sogyoContentsSticky.display").length) && $("#returnToContentsBtn").length) {
    let height = null;
    if ($("#sogyoSticky.display").length) {
      height = $("#sogyoSticky.display").outerHeight();
    } else if ($("#sogyoContentsSticky.display").length) {
      height = $("#sogyoContentsSticky.display").outerHeight();
    }
    if (height != null) {
      const value = `${height}px`;
      $("#returnToContentsBtn").css("padding-bottom", value);
    }
  }
}

try {
  //スマホページ遷移
  let currentPage = "";
  function toggleForm() {
    var feedbacks = document.getElementsByClassName("main-form__feedback--sp");
    var feedbackArray = Array.from(feedbacks);
    var formAmmount = document.getElementById("formAmmount");
    if (currentPage === "Contents") {
      document.getElementById("sogyoFormContents").classList.add("display");
      document.getElementById("sogyoContentsSticky").classList.add("display");
      // document.getElementById("sogyoBtnReverseFinal").classList.remove("display");
      document.getElementById("sogyoSticky").classList.remove("display");
      document.getElementById("sogyo-menu").classList.remove("display");
      document.getElementById("sogyoBtnContents").classList.remove("display");
      document.getElementById("sogyoKeikakuMainWrapper").classList.remove("display");
      for (let i = 0; i < 10; i++) {
        let formId = "sogyoForm" + i.toString();
        let btnId = "sogyoNavBtn" + i.toString();
        document.getElementById(formId).classList.remove("display");
        document
          .getElementById(btnId)
          .classList.remove("sogyo-menu__btn--selected");
      }
      feedbackArray.forEach((feedback) => feedback.classList.remove("display"));
      formAmmount.classList.remove("display");
    } else {
      document.getElementById("sogyoFormContents").classList.remove("display");
      document
        .getElementById("sogyoContentsSticky")
        .classList.remove("display");
      // document.getElementById("sogyoBtnReverseFinal").classList.remove("display");
      document.getElementById("sogyo-menu").classList.add("display");
      document.getElementById("sogyoBtnContents").classList.add("display");
      document.getElementById("sogyoKeikakuMainWrapper").classList.add("display");
      for (let i = 0; i < 10; i++) {
        let formId = "sogyoForm" + i.toString();
        let btnId = "sogyoNavBtn" + i.toString();
        if (i.toString() === currentPage) {
          document.getElementById(formId).classList.add("display");
          document
            .getElementById(btnId)
            .classList.add("sogyo-menu__btn--selected");
        } else {
          document.getElementById(formId).classList.remove("display");
          document
            .getElementById(btnId)
            .classList.remove("sogyo-menu__btn--selected");
        }
      }
      if (currentPage === "9") {
        document.getElementById("sogyoContentsSticky").classList.add("display");
        // document.getElementById("sogyoBtnReverseFinal").classList.add("display");
        document.getElementById("sogyoSticky").classList.remove("display");
      } else {
        document.getElementById("sogyoSticky").classList.add("display");
      }

      if (currentPage === "7") {
        feedbackArray.forEach((feedback) => feedback.classList.add("display"));
        formAmmount.classList.add("display");
      } else {
        feedbackArray.forEach((feedback) =>
          feedback.classList.remove("display")
        );
        formAmmount.classList.remove("display");
      }
      setSogyoKeikakuMainInnerMinHeight();
      setReturnToContentsBtnPaddingBottom();
    }
    setDisabledToSogyoBtnNext();
    toggleSogyoBtnReverseFirstSogyoBtnReverseFinal();

    window.scroll({
      top: 0,
      behavior: "instant",
    });

    document.getElementById("sogyo_keikaku_form_sp_current_page").value = currentPage;
  }
  window.addEventListener("load", function() {
    if (document.getElementById("sogyo_keikaku_form_sp_current_page") != null && document.getElementById("sogyo_keikaku_form_sp_current_page").value != "") {
      currentPage = document.getElementById("sogyo_keikaku_form_sp_current_page").value;
      toggleForm();
    }
  });
  function handleStepBtn(btnId) {
    if (document.getElementById(btnId)) {
      document.getElementById(btnId).addEventListener("click", function () {
        let idNumber = btnId.replace("sogyoBtn", "");
        currentPage = idNumber;
        toggleForm();
      });
    }
  }
  if (document.getElementById("sogyoBtnStepContents")) {
    document
      .getElementById("sogyoBtnStepContents")
      .addEventListener("click", function () {
        currentPage = "Contents";
        toggleForm();
      });
  }
  function handleStepNavBtn(btnId) {
    if (document.getElementById(btnId)) {
      document.getElementById(btnId).addEventListener("click", function () {
        let idNumber = btnId.replace("sogyoNavBtn", "");
        currentPage = idNumber;
        toggleForm();
      });
    }
  }
  function handleStepReverseBtn() {
    if (document.getElementById("sogyoBtnReverse")) {
      document
        .getElementById("sogyoBtnReverse")
        .addEventListener("click", function () {
          if (currentPage === "0") {
            currentPage = "Contents";
          } else {
            currentPage = (parseInt(currentPage) - 1).toString();
          }
          toggleForm();
        });
    }
    if (document.getElementById("sogyoBtnReverseFinal")) {
      document
        .getElementById("sogyoBtnReverseFinal")
        .addEventListener("click", function () {
          if (currentPage === "9") {
            currentPage = "8";
            toggleForm();
          }
        });
    }
  }
  function handleStepNextBtn() {
    if (document.getElementById("sogyoBtnNext")) {
      document
        .getElementById("sogyoBtnNext")
        .addEventListener("click", function () {
          currentPage = (parseInt(currentPage) + 1).toString();
          toggleForm();
        });
    }
  }

  handleStepBtn("sogyoBtn0");
  handleStepBtn("sogyoBtn1");
  handleStepBtn("sogyoBtn2");
  handleStepBtn("sogyoBtn3");
  handleStepBtn("sogyoBtn4");
  handleStepBtn("sogyoBtn5");
  handleStepBtn("sogyoBtn6");
  handleStepBtn("sogyoBtn7");
  handleStepBtn("sogyoBtn8");
  handleStepBtn("sogyoBtn9");
  handleStepBtn("sogyoBtnContents");
  handleStepNavBtn("sogyoNavBtn0");
  handleStepNavBtn("sogyoNavBtn1");
  handleStepNavBtn("sogyoNavBtn2");
  handleStepNavBtn("sogyoNavBtn3");
  handleStepNavBtn("sogyoNavBtn4");
  handleStepNavBtn("sogyoNavBtn5");
  handleStepNavBtn("sogyoNavBtn6");
  handleStepNavBtn("sogyoNavBtn7");
  handleStepNavBtn("sogyoNavBtn8");
  handleStepNavBtn("sogyoNavBtn9");
  handleStepReverseBtn();
  handleStepNextBtn();

  //項目設問のプルダウン
  function toggleAccordion(btnId, accordionId, iconId) {
    var accordionBtn = document.getElementById(btnId);
    var accordion = document.getElementById(accordionId);
    var icon = document.getElementById(iconId);
    if (accordionBtn && accordion && icon) {
      accordionBtn.addEventListener("click", function () {
        if (accordion.classList.contains("display")) {
          accordion.classList.remove("display");
          icon.classList.remove("display");
        } else {
          accordion.classList.add("display");
          icon.classList.add("display");
        }
      });
    }
  }

  function toggleAccordionIconOnLoad(accordionId, iconId) {
    var accordion = document.getElementById(accordionId);
    var icon = document.getElementById(iconId);
    if (accordion && icon) {
      if (accordion.classList.contains("display")) {
        icon.classList.add("display");
      } else {
        icon.classList.remove("display");
      }
    }
  }

  function displayInputBySelect(selectId, inputId, keyword) {
    document.addEventListener("DOMContentLoaded", function () {
      var selectElement = document.getElementById(selectId);
      var targetDiv = document.getElementById(inputId);

      if (selectElement) {
        selectElement.addEventListener("change", function () {
          var selectedValue = selectElement.value;

          if (selectedValue === keyword) {
            targetDiv.classList.add("display");
          } else {
            targetDiv.classList.remove("display");
          }
        });
      }
    });
  }

  for (let i = 1; i <= 31; i++) {
    const btnId = `formAccordionBtn${i}`;
    const contentId = `formAccordion${i}`;
    const iconId = `formAccordionIcon${i}`;
    toggleAccordion(btnId, contentId, iconId);
    toggleAccordionIconOnLoad(contentId, iconId);
  }

  // displayInputBySelect("select_career1", "additionalInput1", "keizoku");
  // displayInputBySelect("select_career1", "additionalInput2", "kako");
  // displayInputBySelect("select_career2", "additionalInput3", "ari");
  // displayInputBySelect("select_career3", "additionalInput4", "ari");

  //入力チェック

  //0
  const sogyokeikakushomei = document.getElementById(
    "jfc_template_sogyokeikakushomei"
  );
  const templatefullname = document.getElementById("jfc_template_fullname");
  const templatesakuseibi = document.getElementById("jfc_template_sakuseibi");
  sogyokeikakushomei.addEventListener("input", handleInputChangeItem0);
  templatefullname.addEventListener("input", handleInputChangeItem0);
  templatesakuseibi.addEventListener("input", handleInputChangeItem0);
  window.addEventListener("load", handleInputChangeItem0);
  function handleInputChangeItem0() {
    if (document.getElementById("inputStatus0") === null) {
      return;
    }
    if (
      sogyokeikakushomei.value !== "" ||
      templatefullname.value !== "" ||
      templatesakuseibi.value !== ""
    ) {
      document.getElementById("inputStatus0").classList.add("inputted");
    } else {
      document.getElementById("inputStatus0").classList.remove("inputted");
    }
  }

  //1
  const sogyodoki1 = document.getElementById("jfc_template_sogyodoki_1");
  const sogyodoki2 = document.getElementById("jfc_template_sogyodoki_2");
  const sogyodoki3 = document.getElementById("jfc_template_sogyodoki_3");
  const sogyodoki4 = document.getElementById("jfc_template_sogyodoki_4");
  sogyodoki1.addEventListener("input", handleInputChangeItem1);
  sogyodoki2.addEventListener("input", handleInputChangeItem1);
  sogyodoki3.addEventListener("input", handleInputChangeItem1);
  sogyodoki4.addEventListener("input", handleInputChangeItem1);
  window.addEventListener("load", handleInputChangeItem1);
  function handleInputChangeItem1() {
    if (document.getElementById("inputStatus1") === null) {
      return;
    }
    if (
      sogyodoki1.value !== "" ||
      sogyodoki2.value !== "" ||
      sogyodoki3.value !== "" ||
      sogyodoki4.value !== ""
    ) {
      document.getElementById("inputStatus1").classList.add("inputted");
    } else {
      document.getElementById("inputStatus1").classList.remove("inputted");
    }
  }

  //2
  const keirekiNen1 = document.getElementById(
    "jfc_template_keieisyakeireki_nen_1"
  );
  const keirekiGetsu1 = document.getElementById(
    "jfc_template_keieisyakeireki_getsu_1"
  );
  const keirekiNaiyo1 = document.getElementById(
    "jfc_template_keieisyakeireki_naiyo_1"
  );
  const keirekiNen2 = document.getElementById(
    "jfc_template_keieisyakeireki_nen_2"
  );
  const keirekiGetsu2 = document.getElementById(
    "jfc_template_keieisyakeireki_getsu_2"
  );
  const keirekiNaiyo2 = document.getElementById(
    "jfc_template_keieisyakeireki_naiyo_2"
  );
  const keirekiNen3 = document.getElementById(
    "jfc_template_keieisyakeireki_nen_3"
  );
  const keirekiGetsu3 = document.getElementById(
    "jfc_template_keieisyakeireki_getsu_3"
  );
  const keirekiNaiyo3 = document.getElementById(
    "jfc_template_keieisyakeireki_naiyo_3"
  );
  const keirekiNen4 = document.getElementById(
    "jfc_template_keieisyakeireki_nen_4"
  );
  const keirekiGetsu4 = document.getElementById(
    "jfc_template_keieisyakeireki_getsu_4"
  );
  const keirekiNaiyo4 = document.getElementById(
    "jfc_template_keieisyakeireki_naiyo_4"
  );
  const keirekiNen5 = document.getElementById(
    "jfc_template_keieisyakeireki_nen_5"
  );
  const keirekiGetsu5 = document.getElementById(
    "jfc_template_keieisyakeireki_getsu_5"
  );
  const keirekiNaiyo5 = document.getElementById(
    "jfc_template_keieisyakeireki_naiyo_5"
  );
  const keirekiNen6 = document.getElementById(
    "jfc_template_keieisyakeireki_nen_6"
  );
  const keirekiGetsu6 = document.getElementById(
    "jfc_template_keieisyakeireki_getsu_6"
  );
  const keirekiNaiyo6 = document.getElementById(
    "jfc_template_keieisyakeireki_naiyo_6"
  );
  keirekiNen1.addEventListener("input", handleInputChangeRyakureki);
  keirekiGetsu1.addEventListener("input", handleInputChangeRyakureki);
  keirekiNaiyo1.addEventListener("input", handleInputChangeRyakureki);
  keirekiNen2.addEventListener("input", handleInputChangeRyakureki);
  keirekiGetsu2.addEventListener("input", handleInputChangeRyakureki);
  keirekiNaiyo2.addEventListener("input", handleInputChangeRyakureki);
  keirekiNen3.addEventListener("input", handleInputChangeRyakureki);
  keirekiGetsu3.addEventListener("input", handleInputChangeRyakureki);
  keirekiNaiyo3.addEventListener("input", handleInputChangeRyakureki);
  keirekiNen4.addEventListener("input", handleInputChangeRyakureki);
  keirekiGetsu4.addEventListener("input", handleInputChangeRyakureki);
  keirekiNaiyo4.addEventListener("input", handleInputChangeRyakureki);
  keirekiNen5.addEventListener("input", handleInputChangeRyakureki);
  keirekiGetsu5.addEventListener("input", handleInputChangeRyakureki);
  keirekiNaiyo5.addEventListener("input", handleInputChangeRyakureki);
  keirekiNen6.addEventListener("input", handleInputChangeRyakureki);
  keirekiGetsu6.addEventListener("input", handleInputChangeRyakureki);
  keirekiNaiyo6.addEventListener("input", handleInputChangeRyakureki);
  window.addEventListener("load", handleInputChangeRyakureki);
  function handleInputChangeRyakureki() {
    if (document.getElementById("formAccordionCheck17") === null) {
      return;
    }
    if (
      keirekiNen1.value !== "" ||
      keirekiGetsu1.value !== "" ||
      keirekiNaiyo1.value !== "" ||
      keirekiNen2.value !== "" ||
      keirekiGetsu2.value !== "" ||
      keirekiNaiyo2.value !== "" ||
      keirekiNen3.value !== "" ||
      keirekiGetsu3.value !== "" ||
      keirekiNaiyo3.value !== "" ||
      keirekiNen4.value !== "" ||
      keirekiGetsu4.value !== "" ||
      keirekiNaiyo4.value !== "" ||
      keirekiNen5.value !== "" ||
      keirekiGetsu5.value !== "" ||
      keirekiNaiyo5.value !== "" ||
      keirekiNen6.value !== "" ||
      keirekiGetsu6.value !== "" ||
      keirekiNaiyo6.value !== ""
    ) {
      document.getElementById("formAccordionCheck17").classList.add("inputted");
    } else {
      document
        .getElementById("formAccordionCheck17")
        .classList.remove("inputted");
    }
    handleInputChangeItem2();
  }

  const career1_1 = document.querySelector("#formAccordion1 #select_business1");
  const career1_2 = document.querySelector("#formAccordion1 #select_business2");
  const career1_3 = document.querySelector("#formAccordion1 #select_business3");
  career1_1.addEventListener("change", handleInputChangeCarrer1);
  career1_2.addEventListener("change", handleInputChangeCarrer1);
  career1_3.addEventListener("change", handleInputChangeCarrer1);
  window.addEventListener("load", handleInputChangeCarrer1);
  function handleInputChangeCarrer1() {
    if (
      career1_1.checked ||
      career1_2.checked ||
      career1_3.checked
    ) {
      document.getElementById("formAccordionCheck1").classList.add("inputted");
    } else {
      document
        .getElementById("formAccordionCheck1")
        .classList.remove("inputted");
    }
    handleInputChangeItem2();

    const additionalInput1 = document.getElementById("additionalInput1");
    const additionalInput2 = document.getElementById("additionalInput2");
    if (career1_1.checked) {
      additionalInput1.classList.remove("display");
      additionalInput2.classList.remove("display");
    } else if (career1_2.checked) {
      additionalInput1.classList.add("display");
      additionalInput2.classList.remove("display");
    } else if (career1_3.checked) {
      additionalInput1.classList.remove("display");
      additionalInput2.classList.add("display");
    } else {
      additionalInput1.classList.remove("display");
      additionalInput2.classList.remove("display");
    }
  }

  const career2_1 = document.querySelector("#formAccordion2 #select_shikaku1");
  const career2_2 = document.querySelector("#formAccordion2 #select_shikaku2");
  career2_1.addEventListener("change", handleInputChangeCarrer2);
  career2_2.addEventListener("change", handleInputChangeCarrer2);
  window.addEventListener("load", handleInputChangeCarrer2);
  function handleInputChangeCarrer2() {
    if (
      career2_1.checked ||
      career2_2.checked
    ) {
      document.getElementById("formAccordionCheck2").classList.add("inputted");
    } else {
      document
        .getElementById("formAccordionCheck2")
        .classList.remove("inputted");
    }
    handleInputChangeItem2();

    const additionalInput3 = document.getElementById("additionalInput3");
    if (career2_1.checked) {
      additionalInput3.classList.remove("display");
    } else if (career2_2.checked) {
      additionalInput3.classList.add("display");
    } else {
      additionalInput3.classList.remove("display");
    }
  }

  const career3_1 = document.querySelector("#formAccordion3 #select_chizai1");
  const career3_2 = document.querySelector("#formAccordion3 #select_chizai2");
  career3_1.addEventListener("change", handleInputChangeCarrer3);
  career3_2.addEventListener("change", handleInputChangeCarrer3);
  window.addEventListener("load", handleInputChangeCarrer3);
  function handleInputChangeCarrer3() {
    if (
      career3_1.checked ||
      career3_2.checked
    ) {
      document.getElementById("formAccordionCheck3").classList.add("inputted");
    } else {
      document
        .getElementById("formAccordionCheck3")
        .classList.remove("inputted");
    }
    handleInputChangeItem2();

    const additionalInput4 = document.getElementById("additionalInput4");
    if (career3_1.checked) {
      additionalInput4.classList.remove("display");
    } else if (career3_2.checked) {
      additionalInput4.classList.add("display");
    } else {
      additionalInput4.classList.remove("display");
    }
  }

  function handleInputChangeItem2() {
    if (
      keirekiNen1.value !== "" ||
      keirekiGetsu1.value !== "" ||
      keirekiNaiyo1.value !== "" ||
      keirekiNen2.value !== "" ||
      keirekiGetsu2.value !== "" ||
      keirekiNaiyo2.value !== "" ||
      keirekiNen3.value !== "" ||
      keirekiGetsu3.value !== "" ||
      keirekiNaiyo3.value !== "" ||
      keirekiNen4.value !== "" ||
      keirekiGetsu4.value !== "" ||
      keirekiNaiyo4.value !== "" ||
      keirekiNen5.value !== "" ||
      keirekiGetsu5.value !== "" ||
      keirekiNaiyo5.value !== "" ||
      keirekiNen6.value !== "" ||
      keirekiGetsu6.value !== "" ||
      keirekiNaiyo6.value !== "" ||
      career1_1.checked ||
      career1_2.checked ||
      career1_3.checked ||
      career2_1.checked ||
      career2_2.checked ||
      career3_1.checked ||
      career3_2.checked
    ) {
      document.getElementById("inputStatus2").classList.add("inputted");
    } else {
      document.getElementById("inputStatus2").classList.remove("inputted");
    }
  }

  //3
  const shohingaiyo1 = document.getElementById("jfc_template_shohingaiyo_1");
  const shohinshare1 = document.getElementById("jfc_template_shohin_share_1");
  const shohingaiyo2 = document.getElementById("jfc_template_shohingaiyo_2");
  const shohinshare2 = document.getElementById("jfc_template_shohin_share_2");
  const shohingaiyo3 = document.getElementById("jfc_template_shohingaiyo_3");
  const shohinshare3 = document.getElementById("jfc_template_shohin_share_3");
  shohingaiyo1.addEventListener("input", handleInputChangeShohin);
  shohinshare1.addEventListener("input", handleInputChangeShohin);
  shohingaiyo2.addEventListener("input", handleInputChangeShohin);
  shohinshare2.addEventListener("input", handleInputChangeShohin);
  shohingaiyo3.addEventListener("input", handleInputChangeShohin);
  shohinshare3.addEventListener("input", handleInputChangeShohin);
  window.addEventListener("load", handleInputChangeShohin);
  function handleInputChangeShohin() {
    if (
      shohingaiyo1.value !== "" ||
      shohinshare1.value !== "" ||
      shohingaiyo2.value !== "" ||
      shohinshare2.value !== "" ||
      shohingaiyo3.value !== "" ||
      shohinshare3.value !== ""
    ) {
      document.getElementById("formAccordionShohin").classList.add("inputted");
    } else {
      document
        .getElementById("formAccordionShohin")
        .classList.remove("inputted");
    }
    handleInputChangeItem3();
  }

  const salesPoint1 = document.getElementById("jfc_template_sales_point_1");
  const salesPoint2 = document.getElementById("jfc_template_sales_point_2");
  const salesPoint3 = document.getElementById("jfc_template_sales_point_3");
  salesPoint1.addEventListener("input", handleInputChangeSalesPoint);
  salesPoint2.addEventListener("input", handleInputChangeSalesPoint);
  salesPoint3.addEventListener("input", handleInputChangeSalesPoint);
  window.addEventListener("load", handleInputChangeSalesPoint);
  function handleInputChangeSalesPoint() {
    if (
      salesPoint1.value !== "" ||
      salesPoint2.value !== "" ||
      salesPoint3.value !== ""
    ) {
      document.getElementById("formAccordionCheck5").classList.add("inputted");
    } else {
      document
        .getElementById("formAccordionCheck5")
        .classList.remove("inputted");
    }
    handleInputChangeItem3();
  }

  const hambaiTarget1 = document.getElementById("jfc_template_hambai_target_1");
  const hambaiTarget2 = document.getElementById("jfc_template_hambai_target_2");
  const hambaiTarget3 = document.getElementById("jfc_template_hambai_target_3");
  hambaiTarget1.addEventListener("input", handleInputChangeHanbaiTarget);
  hambaiTarget2.addEventListener("input", handleInputChangeHanbaiTarget);
  hambaiTarget3.addEventListener("input", handleInputChangeHanbaiTarget);
  window.addEventListener("load", handleInputChangeHanbaiTarget);
  function handleInputChangeHanbaiTarget() {
    if (
      hambaiTarget1.value !== "" ||
      hambaiTarget2.value !== "" ||
      hambaiTarget3.value !== ""
    ) {
      document.getElementById("formAccordionCheck6").classList.add("inputted");
    } else {
      document
        .getElementById("formAccordionCheck6")
        .classList.remove("inputted");
    }
    handleInputChangeItem3();
  }

  const shijokankyo1 = document.getElementById("jfc_template_shijokankyo_1");
  const shijokankyo2 = document.getElementById("jfc_template_shijokankyo_2");
  const shijokankyo3 = document.getElementById("jfc_template_shijokankyo_3");
  shijokankyo1.addEventListener("input", handleInputChangeShijo);
  shijokankyo2.addEventListener("input", handleInputChangeShijo);
  shijokankyo3.addEventListener("input", handleInputChangeShijo);
  window.addEventListener("load", handleInputChangeShijo);
  function handleInputChangeShijo() {
    if (
      shijokankyo1.value !== "" ||
      shijokankyo2.value !== "" ||
      shijokankyo3.value !== ""
    ) {
      document.getElementById("formAccordionCheck7").classList.add("inputted");
    } else {
      document
        .getElementById("formAccordionCheck7")
        .classList.remove("inputted");
    }
    handleInputChangeItem3();
  }

  function handleInputChangeItem3() {
    if (
      shohingaiyo1.value !== "" ||
      shohinshare1.value !== "" ||
      shohingaiyo2.value !== "" ||
      shohinshare2.value !== "" ||
      shohingaiyo3.value !== "" ||
      shohinshare3.value !== "" ||
      salesPoint1.value !== "" ||
      salesPoint2.value !== "" ||
      salesPoint3.value !== "" ||
      hambaiTarget1.value !== "" ||
      hambaiTarget2.value !== "" ||
      hambaiTarget3.value !== "" ||
      shijokankyo1.value !== "" ||
      shijokankyo2.value !== "" ||
      shijokankyo3.value !== ""
    ) {
      document.getElementById("inputStatus3").classList.add("inputted");
    } else {
      document.getElementById("inputStatus3").classList.remove("inputted");
    }
  }

  //4
  const hambaisakimei1 = document.getElementById(
    "jfc_template_hambaisakimei_1"
  );
  const hambaisakimeiKana1 = document.getElementById(
    "jfc_template_hambaisakimei_kana_1"
  );
  const hambaisakiShozaichi1 = document.getElementById(
    "jfc_template_hambaisaki_shozaichi_1"
  );
  const hambaisakiShare1 = document.getElementById(
    "jfc_template_hambaisaki_share_1"
  );
  const hambaisakikakewariai1 = document.getElementById(
    "jfc_template_hambaisaki_kakewariai_1"
  );
  const hambaisakishimebi1 = document.getElementById(
    "jfc_template_hambaisaki_shimebi_1"
  );
  const hambaisakiKaishubi1 = document.getElementById(
    "jfc_template_hambaisaki_kaishubi_1"
  );
  const hambaisakimei2 = document.getElementById(
    "jfc_template_hambaisakimei_2"
  );
  const hambaisakimeiKana2 = document.getElementById(
    "jfc_template_hambaisakimei_kana_2"
  );
  const hambaisakiShozaichi2 = document.getElementById(
    "jfc_template_hambaisaki_shozaichi_2"
  );
  const hambaisakiShare2 = document.getElementById(
    "jfc_template_hambaisaki_share_2"
  );
  const hambaisakikakewariai2 = document.getElementById(
    "jfc_template_hambaisaki_kakewariai_2"
  );
  const hambaisakishimebi2 = document.getElementById(
    "jfc_template_hambaisaki_shimebi_2"
  );
  const hambaisakiKaishubi2 = document.getElementById(
    "jfc_template_hambaisaki_kaishubi_2"
  );
  const hambaisakiTorihikisakisu = document.getElementById(
    "jfc_template_hambaisaki_torihikisakisu"
  );
  const hambaisakiShareHoka = document.getElementById(
    "jfc_template_hambaisaki_share_hoka"
  );
  const hambaisakiKakewariaiHoka = document.getElementById(
    "jfc_template_hambaisaki_kakewariai_hoka"
  );
  const hambaisakiShimebiHoka = document.getElementById(
    "jfc_template_hambaisaki_shimebi_hoka"
  );
  const hambaisakiKaishubiHoka = document.getElementById(
    "jfc_template_hambaisaki_kaishubi_hoka"
  );
  hambaisakimei1.addEventListener("input", handleInputChangeHanbaisaki);
  hambaisakimeiKana1.addEventListener("input", handleInputChangeHanbaisaki);
  hambaisakiShozaichi1.addEventListener("input", handleInputChangeHanbaisaki);
  hambaisakiShare1.addEventListener("input", handleInputChangeHanbaisaki);
  hambaisakikakewariai1.addEventListener("input", handleInputChangeHanbaisaki);
  hambaisakishimebi1.addEventListener("input", handleInputChangeHanbaisaki);
  hambaisakiKaishubi1.addEventListener("input", handleInputChangeHanbaisaki);
  hambaisakimei2.addEventListener("input", handleInputChangeHanbaisaki);
  hambaisakimeiKana2.addEventListener("input", handleInputChangeHanbaisaki);
  hambaisakiShozaichi2.addEventListener("input", handleInputChangeHanbaisaki);
  hambaisakiShare2.addEventListener("input", handleInputChangeHanbaisaki);
  hambaisakikakewariai2.addEventListener("input", handleInputChangeHanbaisaki);
  hambaisakishimebi2.addEventListener("input", handleInputChangeHanbaisaki);
  hambaisakiKaishubi2.addEventListener("input", handleInputChangeHanbaisaki);
  hambaisakiTorihikisakisu.addEventListener("input", handleInputChangeHanbaisaki);
  hambaisakiShareHoka.addEventListener("input", handleInputChangeHanbaisaki);
  hambaisakiKakewariaiHoka.addEventListener("input", handleInputChangeHanbaisaki);
  hambaisakiShimebiHoka.addEventListener("input", handleInputChangeHanbaisaki);
  hambaisakiKaishubiHoka.addEventListener("input", handleInputChangeHanbaisaki);
  window.addEventListener("load", handleInputChangeHanbaisaki);
  function handleInputChangeHanbaisaki() {
    if (
      hambaisakimei1.value !== "" ||
      hambaisakimeiKana1.value !== "" ||
      hambaisakiShozaichi1.value !== "" ||
      hambaisakiShare1.value !== "" ||
      hambaisakikakewariai1.value !== "" ||
      hambaisakishimebi1.value !== "" ||
      hambaisakiKaishubi1.value !== "" ||
      hambaisakimei2.value !== "" ||
      hambaisakimeiKana2.value !== "" ||
      hambaisakiShozaichi2.value !== "" ||
      hambaisakiShare2.value !== "" ||
      hambaisakikakewariai2.value !== "" ||
      hambaisakishimebi2.value !== "" ||
      hambaisakiKaishubi2.value !== "" ||
      hambaisakiTorihikisakisu.value !== "" ||
      hambaisakiShareHoka.value !== "" ||
      hambaisakiKakewariaiHoka.value !== "" ||
      hambaisakiShimebiHoka.value !== "" ||
      hambaisakiKaishubiHoka.value !== ""
    ) {
      document.getElementById("formAccordionCheck8").classList.add("inputted");
    } else {
      document
        .getElementById("formAccordionCheck8")
        .classList.remove("inputted");
    }
    handleInputChangeItem4();
  }

  const shiiresakimei1 = document.getElementById(
    "jfc_template_shiiresakimei_1"
  );
  const shiiresakimeiKana1 = document.getElementById(
    "jfc_template_shiiresakimei_kana_1"
  );
  const shiiresakiShozaichi1 = document.getElementById(
    "jfc_template_shiiresaki_shozaichi_1"
  );
  const shiiresakiShare1 = document.getElementById(
    "jfc_template_shiiresaki_share_1"
  );
  const shiiresakiKakewariai1 = document.getElementById(
    "jfc_template_shiiresaki_kakewariai_1"
  );
  const shiiresakiShimebi1 = document.getElementById(
    "jfc_template_shiiresaki_shimebi_1"
  );
  const shiiresakiKaishubi1 = document.getElementById(
    "jfc_template_shiiresaki_kaishubi_1"
  );
  const shiiresakimei2 = document.getElementById(
    "jfc_template_shiiresakimei_2"
  );
  const shiiresakimeiKana2 = document.getElementById(
    "jfc_template_shiiresakimei_kana_2"
  );
  const shiiresakiShozaichi2 = document.getElementById(
    "jfc_template_shiiresaki_shozaichi_2"
  );
  const shiiresakiShare2 = document.getElementById(
    "jfc_template_shiiresaki_share_2"
  );
  const shiiresakiKakewariai2 = document.getElementById(
    "jfc_template_shiiresaki_kakewariai_2"
  );
  const shiiresakiShimebi2 = document.getElementById(
    "jfc_template_shiiresaki_shimebi_2"
  );
  const shiiresakiKaishubi2 = document.getElementById(
    "jfc_template_shiiresaki_kaishubi_2"
  );
  const shiiresakitorihikisakisu = document.getElementById(
    "jfc_template_shiiresaki_torihikisakisu"
  );
  const shiiresakishareHoka = document.getElementById(
    "jfc_template_shiiresaki_share_hoka"
  );
  const shiiresakikakewariaiHoka = document.getElementById(
    "jfc_template_shiiresaki_kakewariai_hoka"
  );
  const shiiresakishimebiHoka = document.getElementById(
    "jfc_template_shiiresaki_shimebi_hoka"
  );
  const shiiresakiKaishubiHoka = document.getElementById(
    "jfc_template_shiiresaki_kaishubi_hoka"
  );
  shiiresakimei1.addEventListener("input", handleInputChangeShiire);
  shiiresakimeiKana1.addEventListener("input", handleInputChangeShiire);
  shiiresakiShozaichi1.addEventListener("input", handleInputChangeShiire);
  shiiresakiShare1.addEventListener("input", handleInputChangeShiire);
  shiiresakiKakewariai1.addEventListener("input", handleInputChangeShiire);
  shiiresakiShimebi1.addEventListener("input", handleInputChangeShiire);
  shiiresakiKaishubi1.addEventListener("input", handleInputChangeShiire);
  shiiresakimei2.addEventListener("input", handleInputChangeShiire);
  shiiresakimeiKana2.addEventListener("input", handleInputChangeShiire);
  shiiresakiShozaichi2.addEventListener("input", handleInputChangeShiire);
  shiiresakiShare2.addEventListener("input", handleInputChangeShiire);
  shiiresakiKakewariai2.addEventListener("input", handleInputChangeShiire);
  shiiresakiShimebi2.addEventListener("input", handleInputChangeShiire);
  shiiresakiKaishubi2.addEventListener("input", handleInputChangeShiire);
  shiiresakitorihikisakisu.addEventListener("input", handleInputChangeShiire);
  shiiresakishareHoka.addEventListener("input", handleInputChangeShiire);
  shiiresakikakewariaiHoka.addEventListener("input", handleInputChangeShiire);
  shiiresakishimebiHoka.addEventListener("input", handleInputChangeShiire);
  shiiresakiKaishubiHoka.addEventListener("input", handleInputChangeShiire);
  window.addEventListener("load", handleInputChangeShiire);
  function handleInputChangeShiire() {
    if (
      shiiresakimei1.value !== "" ||
      shiiresakimeiKana1.value !== "" ||
      shiiresakiShozaichi1.value !== "" ||
      shiiresakiShare1.value !== "" ||
      shiiresakiKakewariai1.value !== "" ||
      shiiresakiShimebi1.value !== "" ||
      shiiresakiKaishubi1.value !== "" ||
      shiiresakimei2.value !== "" ||
      shiiresakimeiKana2.value !== "" ||
      shiiresakiShozaichi2.value !== "" ||
      shiiresakiShare2.value !== "" ||
      shiiresakiKakewariai2.value !== "" ||
      shiiresakiShimebi2.value !== "" ||
      shiiresakiKaishubi2.value !== "" ||
      shiiresakitorihikisakisu.value !== "" ||
      shiiresakishareHoka.value !== "" ||
      shiiresakikakewariaiHoka.value !== "" ||
      shiiresakishimebiHoka.value !== "" ||
      shiiresakiKaishubiHoka.value !== ""
    ) {
      document.getElementById("formAccordionCheck9").classList.add("inputted");
    } else {
      document
        .getElementById("formAccordionCheck9")
        .classList.remove("inputted");
    }
    handleInputChangeItem4();
  }

  const gaichusakimei1 = document.getElementById(
    "jfc_template_gaichusakimei_1"
  );
  const gaichusakimeiKana1 = document.getElementById(
    "jfc_template_gaichusakimei_kana_1"
  );
  const gaichusakiShozaichi1 = document.getElementById(
    "jfc_template_gaichusaki_shozaichi_1"
  );
  const gaichusakiShare1 = document.getElementById(
    "jfc_template_gaichusaki_share_1"
  );
  const gaichusakikakewariai1 = document.getElementById(
    "jfc_template_gaichusaki_kakewariai_1"
  );
  const gaichusakishimebi1 = document.getElementById(
    "jfc_template_gaichusaki_shimebi_1"
  );
  const gaichusakiKaishubi1 = document.getElementById(
    "jfc_template_gaichusaki_kaishubi_1"
  );
  const gaichusakitorihikisakisu = document.getElementById(
    "jfc_template_gaichusaki_torihikisakisu"
  );
  const gaichusakishareHoka = document.getElementById(
    "jfc_template_gaichusaki_share_hoka"
  );
  const gaichusakikakewariaiHoka = document.getElementById(
    "jfc_template_gaichusaki_kakewariai_hoka"
  );
  const gaichusakishimebiHoka = document.getElementById(
    "jfc_template_gaichusaki_shimebi_hoka"
  );
  const gaichusakiKaishubiHoka = document.getElementById(
    "jfc_template_gaichusaki_kaishubi_hoka"
  );
  gaichusakimei1.addEventListener("input", handleInputChangeGaichu);
  gaichusakimeiKana1.addEventListener("input", handleInputChangeGaichu);
  gaichusakiShozaichi1.addEventListener("input", handleInputChangeGaichu);
  gaichusakiShare1.addEventListener("input", handleInputChangeGaichu);
  gaichusakikakewariai1.addEventListener("input", handleInputChangeGaichu);
  gaichusakishimebi1.addEventListener("input", handleInputChangeGaichu);
  gaichusakiKaishubi1.addEventListener("input", handleInputChangeGaichu);
  gaichusakitorihikisakisu.addEventListener("input", handleInputChangeGaichu);
  gaichusakishareHoka.addEventListener("input", handleInputChangeGaichu);
  gaichusakikakewariaiHoka.addEventListener("input", handleInputChangeGaichu);
  gaichusakishimebiHoka.addEventListener("input", handleInputChangeGaichu);
  gaichusakiKaishubiHoka.addEventListener("input", handleInputChangeGaichu);
  window.addEventListener("load", handleInputChangeGaichu);
  function handleInputChangeGaichu() {
    if (
      gaichusakimei1.value !== "" ||
      gaichusakimeiKana1.value !== "" ||
      gaichusakiShozaichi1.value !== "" ||
      gaichusakiShare1.value !== "" ||
      gaichusakikakewariai1.value !== "" ||
      gaichusakishimebi1.value !== "" ||
      gaichusakiKaishubi1.value !== "" ||
      gaichusakitorihikisakisu.value !== "" ||
      gaichusakishareHoka.value !== "" ||
      gaichusakikakewariaiHoka.value !== "" ||
      gaichusakishimebiHoka.value !== "" ||
      gaichusakiKaishubiHoka.value !== ""
    ) {
      document.getElementById("formAccordionCheck10").classList.add("inputted");
    } else {
      document
        .getElementById("formAccordionCheck10")
        .classList.remove("inputted");
    }
    handleInputChangeItem4();
  }

  const jinkenhiShimebi = document.getElementById(
    "jfc_template_jinkenhi_shimebi"
  );
  const jinkenhiShiharaibi = document.getElementById(
    "jfc_template_jinkenhi_shiharaibi"
  );
  const jinkenhiBonusGetsu1 = document.getElementById(
    "jfc_template_jinkenhi_bonus_getsu_1"
  );
  const jinkenhiBonusGetsu2 = document.getElementById(
    "jfc_template_jinkenhi_bonus_getsu_2"
  );
  jinkenhiShimebi.addEventListener("input", handleInputChangeJinkenhi);
  jinkenhiShiharaibi.addEventListener("input", handleInputChangeJinkenhi);
  jinkenhiBonusGetsu1.addEventListener("input", handleInputChangeJinkenhi);
  jinkenhiBonusGetsu2.addEventListener("input", handleInputChangeJinkenhi);
  window.addEventListener("load", handleInputChangeJinkenhi);
  function handleInputChangeJinkenhi() {
    if (
      jinkenhiShimebi.value !== "" ||
      jinkenhiShiharaibi.value !== "" ||
      jinkenhiBonusGetsu1.value !== "" ||
      jinkenhiBonusGetsu2.value !== ""
    ) {
      document.getElementById("formAccordionCheck11").classList.add("inputted");
    } else {
      document
        .getElementById("formAccordionCheck11")
        .classList.remove("inputted");
    }
    handleInputChangeItem4();
  }

  function handleInputChangeItem4() {
    if (
      hambaisakimei1.value !== "" ||
      hambaisakimeiKana1.value !== "" ||
      hambaisakiShozaichi1.value !== "" ||
      hambaisakiShare1.value !== "" ||
      hambaisakikakewariai1.value !== "" ||
      hambaisakishimebi1.value !== "" ||
      hambaisakiKaishubi1.value !== "" ||
      hambaisakimei2.value !== "" ||
      hambaisakimeiKana2.value !== "" ||
      hambaisakiShozaichi2.value !== "" ||
      hambaisakiShare2.value !== "" ||
      hambaisakikakewariai2.value !== "" ||
      hambaisakishimebi2.value !== "" ||
      hambaisakiKaishubi2.value !== "" ||
      hambaisakiTorihikisakisu.value !== "" ||
      hambaisakiShareHoka.value !== "" ||
      hambaisakiKakewariaiHoka.value !== "" ||
      hambaisakiShimebiHoka.value !== "" ||
      hambaisakiKaishubiHoka.value !== "" ||
      shiiresakimei1.value !== "" ||
      shiiresakimeiKana1.value !== "" ||
      shiiresakiShozaichi1.value !== "" ||
      shiiresakiShare1.value !== "" ||
      shiiresakiKakewariai1.value !== "" ||
      shiiresakiShimebi1.value !== "" ||
      shiiresakiKaishubi1.value !== "" ||
      shiiresakimei2.value !== "" ||
      shiiresakimeiKana2.value !== "" ||
      shiiresakiShozaichi2.value !== "" ||
      shiiresakiShare2.value !== "" ||
      shiiresakiKakewariai2.value !== "" ||
      shiiresakiShimebi2.value !== "" ||
      shiiresakiKaishubi2.value !== "" ||
      shiiresakitorihikisakisu.value !== "" ||
      shiiresakishareHoka.value !== "" ||
      shiiresakikakewariaiHoka.value !== "" ||
      shiiresakishimebiHoka.value !== "" ||
      shiiresakiKaishubiHoka.value !== "" ||
      gaichusakimei1.value !== "" ||
      gaichusakimeiKana1.value !== "" ||
      gaichusakiShozaichi1.value !== "" ||
      gaichusakiShare1.value !== "" ||
      gaichusakikakewariai1.value !== "" ||
      gaichusakishimebi1.value !== "" ||
      gaichusakiKaishubi1.value !== "" ||
      gaichusakitorihikisakisu.value !== "" ||
      gaichusakishareHoka.value !== "" ||
      gaichusakikakewariaiHoka.value !== "" ||
      gaichusakishimebiHoka.value !== "" ||
      gaichusakiKaishubiHoka.value !== "" ||
      jinkenhiShimebi.value !== "" ||
      jinkenhiShiharaibi.value !== "" ||
      jinkenhiBonusGetsu1.value !== "" ||
      jinkenhiBonusGetsu2.value !== ""
    ) {
      document.getElementById("inputStatus4").classList.add("inputted");
    } else {
      document.getElementById("inputStatus4").classList.remove("inputted");
    }
  }

  //5
  const yakuinsu = document.getElementById("text_yakuinsu");
  const jugyoinsu = document.getElementById("text_jugyoinsu");
  const uchikazoku = document.getElementById("text_uchi_kazoku");
  const uchipart = document.getElementById("text_uchi_part");
  yakuinsu.addEventListener("input", handleInputChangeItem5);
  jugyoinsu.addEventListener("input", handleInputChangeItem5);
  uchikazoku.addEventListener("input", handleInputChangeItem5);
  uchipart.addEventListener("input", handleInputChangeItem5);
  window.addEventListener("load", handleInputChangeItem5);
  function handleInputChangeItem5() {
    if (
      yakuinsu.value !== "" ||
      jugyoinsu.value !== "" ||
      uchikazoku.value !== "" ||
      uchipart.value !== ""
    ) {
      document.getElementById("inputStatus5").classList.add("inputted");
    } else {
      document.getElementById("inputStatus5").classList.remove("inputted");
    }
  }

  //6
  const kariiresaki1 = document.getElementById("jfc_template_kariiresaki_1");
  const usage1jigyo = document.getElementById("usage1_jigyo");
  const usage1jutaku = document.getElementById("usage1_jutaku");
  const usage1car = document.getElementById("usage1_car");
  const usage1kyoiku = document.getElementById("usage1_kyoiku");
  const usage1card = document.getElementById("usage1_card");
  const usage1sonota = document.getElementById("usage1_sonota");
  const kariirezandaka1 = document.getElementById("text_kariirezandaka_1");
  const nenkanhensaigaku1 = document.getElementById(
    "text_kariire_nenkanhensaigaku_1"
  );
  const kariiresaki2 = document.getElementById("jfc_template_kariiresaki_2");
  const usage2jigyo = document.getElementById("usage2_jigyo");
  const usage2jutaku = document.getElementById("usage2_jutaku");
  const usage2car = document.getElementById("usage2_car");
  const usage2kyoiku = document.getElementById("usage2_kyoiku");
  const usage2card = document.getElementById("usage2_card");
  const usage2sonota = document.getElementById("usage2_sonota");
  const kariirezandaka2 = document.getElementById("text_kariirezandaka_2");
  const nenkanhensaigaku2 = document.getElementById(
    "text_kariire_nenkanhensaigaku_2"
  );
  const kariiresaki3 = document.getElementById("jfc_template_kariiresaki_3");
  const usage3jigyo = document.getElementById("usage3_jigyo");
  const usage3jutaku = document.getElementById("usage3_jutaku");
  const usage3car = document.getElementById("usage3_car");
  const usage3kyoiku = document.getElementById("usage3_kyoiku");
  const usage3card = document.getElementById("usage3_card");
  const usage3sonota = document.getElementById("usage3_sonota");
  const kariirezandaka3 = document.getElementById("text_kariirezandaka_3");
  const nenkanhensaigaku3 = document.getElementById(
    "text_kariire_nenkanhensaigaku_3"
  );
  kariiresaki1.addEventListener("input", handleInputChangeItem6);
  usage1jigyo.addEventListener("input", handleInputChangeItem6);
  usage1jutaku.addEventListener("input", handleInputChangeItem6);
  usage1car.addEventListener("input", handleInputChangeItem6);
  usage1kyoiku.addEventListener("input", handleInputChangeItem6);
  usage1card.addEventListener("input", handleInputChangeItem6);
  usage1sonota.addEventListener("input", handleInputChangeItem6);
  kariirezandaka1.addEventListener("input", handleInputChangeItem6);
  nenkanhensaigaku1.addEventListener("input", handleInputChangeItem6);
  kariiresaki2.addEventListener("input", handleInputChangeItem6);
  usage2jigyo.addEventListener("input", handleInputChangeItem6);
  usage2jutaku.addEventListener("input", handleInputChangeItem6);
  usage2car.addEventListener("input", handleInputChangeItem6);
  usage2kyoiku.addEventListener("input", handleInputChangeItem6);
  usage2card.addEventListener("input", handleInputChangeItem6);
  usage2sonota.addEventListener("input", handleInputChangeItem6);
  kariirezandaka2.addEventListener("input", handleInputChangeItem6);
  nenkanhensaigaku2.addEventListener("input", handleInputChangeItem6);
  kariiresaki3.addEventListener("input", handleInputChangeItem6);
  usage3jigyo.addEventListener("input", handleInputChangeItem6);
  usage3jutaku.addEventListener("input", handleInputChangeItem6);
  usage3car.addEventListener("input", handleInputChangeItem6);
  usage3kyoiku.addEventListener("input", handleInputChangeItem6);
  usage3card.addEventListener("input", handleInputChangeItem6);
  usage3sonota.addEventListener("input", handleInputChangeItem6);
  kariirezandaka3.addEventListener("input", handleInputChangeItem6);
  nenkanhensaigaku3.addEventListener("input", handleInputChangeItem6);
  window.addEventListener("load", handleInputChangeItem6);
  function handleInputChangeItem6() {
    if (
      kariiresaki1.value !== "" ||
      usage1jigyo.checked ||
      usage1jutaku.checked ||
      usage1car.checked ||
      usage1kyoiku.checked ||
      usage1card.checked ||
      usage1sonota.checked ||
      kariirezandaka1.value !== "" ||
      nenkanhensaigaku1.value !== "" ||
      kariiresaki2.value !== "" ||
      usage2jigyo.checked ||
      usage2jutaku.checked ||
      usage2car.checked ||
      usage2kyoiku.checked ||
      usage2card.checked ||
      usage2sonota.checked ||
      kariirezandaka2.value !== "" ||
      nenkanhensaigaku2.value !== "" ||
      kariiresaki3.value !== "" ||
      usage3jigyo.checked ||
      usage3jutaku.checked ||
      usage3car.checked ||
      usage3kyoiku.checked ||
      usage3card.checked ||
      usage3sonota.checked ||
      kariirezandaka3.value !== "" ||
      nenkanhensaigaku3.value !== ""
    ) {
      document.getElementById("inputStatus6").classList.add("inputted");
    } else {
      document.getElementById("inputStatus6").classList.remove("inputted");
    }
  }

  //7
  const setsubishikingaiyo1 = document.getElementById(
    "jfc_template_setsubishikin_gaiyo_1"
  );
  const setsubishikinmitsumorisaki1 = document.getElementById(
    "jfc_template_setsubishikin_mitsumorisaki_1"
  );
  const setsubishikinkingaku1 = document.getElementById(
    "text_setsubishikin_kingaku_1"
  );
  const setsubishikingaiyo2 = document.getElementById(
    "jfc_template_setsubishikin_gaiyo_2"
  );
  const setsubishikinmitsumorisaki2 = document.getElementById(
    "jfc_template_setsubishikin_mitsumorisaki_2"
  );
  const setsubishikinkingaku2 = document.getElementById(
    "text_setsubishikin_kingaku_2"
  );
  const setsubishikingaiyo3 = document.getElementById(
    "jfc_template_setsubishikin_gaiyo_3"
  );
  const setsubishikinmitsumorisaki3 = document.getElementById(
    "jfc_template_setsubishikin_mitsumorisaki_3"
  );
  const setsubishikinkingaku3 = document.getElementById(
    "text_setsubishikin_kingaku_3"
  );
  const setsubishikingaiyo4 = document.getElementById(
    "jfc_template_setsubishikin_gaiyo_4"
  );
  const setsubishikinmitsumorisaki4 = document.getElementById(
    "jfc_template_setsubishikin_mitsumorisaki_4"
  );
  const setsubishikinkingaku4 = document.getElementById(
    "text_setsubishikin_kingaku_4"
  );
  const setsubishikingaiyo5 = document.getElementById(
    "jfc_template_setsubishikin_gaiyo_5"
  );
  const setsubishikinmitsumorisaki5 = document.getElementById(
    "jfc_template_setsubishikin_mitsumorisaki_5"
  );
  const setsubishikinkingaku5 = document.getElementById(
    "text_setsubishikin_kingaku_5"
  );
  const setsubishikingaiyo6 = document.getElementById(
    "jfc_template_setsubishikin_gaiyo_6"
  );
  const setsubishikinmitsumorisaki6 = document.getElementById(
    "jfc_template_setsubishikin_mitsumorisaki_6"
  );
  const setsubishikinkingaku6 = document.getElementById(
    "text_setsubishikin_kingaku_6"
  );
  const setsubishikingaiyo7 = document.getElementById(
    "jfc_template_setsubishikin_gaiyo_7"
  );
  const setsubishikinmitsumorisaki7 = document.getElementById(
    "jfc_template_setsubishikin_mitsumorisaki_7"
  );
  const setsubishikinkingaku7 = document.getElementById(
    "text_setsubishikin_kingaku_7"
  );
  const setsubishikingaiyo8 = document.getElementById(
    "jfc_template_setsubishikin_gaiyo_8"
  );
  const setsubishikinmitsumorisaki8 = document.getElementById(
    "jfc_template_setsubishikin_mitsumorisaki_8"
  );
  const setsubishikinkingaku8 = document.getElementById(
    "text_setsubishikin_kingaku_8"
  );
  const setsubishikingaiyo9 = document.getElementById(
    "jfc_template_setsubishikin_gaiyo_9"
  );
  const setsubishikinmitsumorisaki9 = document.getElementById(
    "jfc_template_setsubishikin_mitsumorisaki_9"
  );
  const setsubishikinkingaku9 = document.getElementById(
    "text_setsubishikin_kingaku_9"
  );
  const setsubishikingaiyo10 = document.getElementById(
    "jfc_template_setsubishikin_gaiyo_10"
  );
  const setsubishikinmitsumorisaki10 = document.getElementById(
    "jfc_template_setsubishikin_mitsumorisaki_10"
  );
  const setsubishikinkingaku10 = document.getElementById(
    "text_setsubishikin_kingaku_10"
  );
  const untenshikingaiyo1 = document.getElementById(
    "jfc_template_untenshikin_gaiyo_1"
  );
  const untenshikinkingaku1 = document.getElementById(
    "text_untenshikin_kingaku_1"
  );
  const untenshikingaiyo2 = document.getElementById(
    "jfc_template_untenshikin_gaiyo_2"
  );
  const untenshikinkingaku2 = document.getElementById(
    "text_untenshikin_kingaku_2"
  );
  const untenshikingaiyo3 = document.getElementById(
    "jfc_template_untenshikin_gaiyo_3"
  );
  const untenshikinkingaku3 = document.getElementById(
    "text_untenshikin_kingaku_3"
  );
  const untenshikingaiyo4 = document.getElementById(
    "jfc_template_untenshikin_gaiyo_4"
  );
  const untenshikinkingaku4 = document.getElementById(
    "text_untenshikin_kingaku_4"
  );
  setsubishikingaiyo1.addEventListener("input", handleInputChangeShikin);
  setsubishikinmitsumorisaki1.addEventListener("input", handleInputChangeShikin);
  setsubishikinkingaku1.addEventListener("input", handleInputChangeShikin);
  setsubishikingaiyo2.addEventListener("input", handleInputChangeShikin);
  setsubishikinmitsumorisaki2.addEventListener("input", handleInputChangeShikin);
  setsubishikinkingaku2.addEventListener("input", handleInputChangeShikin);
  setsubishikingaiyo3.addEventListener("input", handleInputChangeShikin);
  setsubishikinmitsumorisaki3.addEventListener("input", handleInputChangeShikin);
  setsubishikinkingaku3.addEventListener("input", handleInputChangeShikin);
  setsubishikingaiyo4.addEventListener("input", handleInputChangeShikin);
  setsubishikinmitsumorisaki4.addEventListener("input", handleInputChangeShikin);
  setsubishikinkingaku4.addEventListener("input", handleInputChangeShikin);
  setsubishikingaiyo5.addEventListener("input", handleInputChangeShikin);
  setsubishikinmitsumorisaki5.addEventListener("input", handleInputChangeShikin);
  setsubishikinkingaku5.addEventListener("input", handleInputChangeShikin);
  setsubishikingaiyo6.addEventListener("input", handleInputChangeShikin);
  setsubishikinmitsumorisaki6.addEventListener("input", handleInputChangeShikin);
  setsubishikinkingaku6.addEventListener("input", handleInputChangeShikin);
  setsubishikingaiyo7.addEventListener("input", handleInputChangeShikin);
  setsubishikinmitsumorisaki7.addEventListener("input", handleInputChangeShikin);
  setsubishikinkingaku7.addEventListener("input", handleInputChangeShikin);
  setsubishikingaiyo8.addEventListener("input", handleInputChangeShikin);
  setsubishikinmitsumorisaki8.addEventListener("input", handleInputChangeShikin);
  setsubishikinkingaku8.addEventListener("input", handleInputChangeShikin);
  setsubishikingaiyo9.addEventListener("input", handleInputChangeShikin);
  setsubishikinmitsumorisaki9.addEventListener("input", handleInputChangeShikin);
  setsubishikinkingaku9.addEventListener("input", handleInputChangeShikin);
  setsubishikingaiyo10.addEventListener("input", handleInputChangeShikin);
  setsubishikinmitsumorisaki10.addEventListener("input", handleInputChangeShikin);
  setsubishikinkingaku10.addEventListener("input", handleInputChangeShikin);
  untenshikingaiyo1.addEventListener("input", handleInputChangeShikin);
  untenshikinkingaku1.addEventListener("input", handleInputChangeShikin);
  untenshikingaiyo2.addEventListener("input", handleInputChangeShikin);
  untenshikinkingaku2.addEventListener("input", handleInputChangeShikin);
  untenshikingaiyo3.addEventListener("input", handleInputChangeShikin);
  untenshikinkingaku3.addEventListener("input", handleInputChangeShikin);
  untenshikingaiyo4.addEventListener("input", handleInputChangeShikin);
  untenshikinkingaku4.addEventListener("input", handleInputChangeShikin);
  window.addEventListener("load", handleInputChangeShikin);
  function handleInputChangeShikin() {
    if (
      setsubishikingaiyo1.value !== "" ||
      setsubishikinmitsumorisaki1.value !== "" ||
      setsubishikinkingaku1.value !== "" ||
      setsubishikingaiyo2.value !== "" ||
      setsubishikinmitsumorisaki2.value !== "" ||
      setsubishikinkingaku2.value !== "" ||
      setsubishikingaiyo3.value !== "" ||
      setsubishikinmitsumorisaki3.value !== "" ||
      setsubishikinkingaku3.value !== "" ||
      setsubishikingaiyo4.value !== "" ||
      setsubishikinmitsumorisaki4.value !== "" ||
      setsubishikinkingaku4.value !== "" ||
      setsubishikingaiyo5.value !== "" ||
      setsubishikinmitsumorisaki5.value !== "" ||
      setsubishikinkingaku5.value !== "" ||
      setsubishikingaiyo6.value !== "" ||
      setsubishikinmitsumorisaki6.value !== "" ||
      setsubishikinkingaku6.value !== "" ||
      setsubishikingaiyo7.value !== "" ||
      setsubishikinmitsumorisaki7.value !== "" ||
      setsubishikinkingaku7.value !== "" ||
      setsubishikingaiyo8.value !== "" ||
      setsubishikinmitsumorisaki8.value !== "" ||
      setsubishikinkingaku8.value !== "" ||
      setsubishikingaiyo9.value !== "" ||
      setsubishikinmitsumorisaki9.value !== "" ||
      setsubishikinkingaku9.value !== "" ||
      setsubishikingaiyo10.value !== "" ||
      setsubishikinmitsumorisaki10.value !== "" ||
      setsubishikinkingaku10.value !== "" ||
      untenshikingaiyo1.value !== "" ||
      untenshikinkingaku1.value !== "" ||
      untenshikingaiyo2.value !== "" ||
      untenshikinkingaku2.value !== "" ||
      untenshikingaiyo3.value !== "" ||
      untenshikinkingaku3.value !== "" ||
      untenshikingaiyo4.value !== "" ||
      untenshikinkingaku4.value !== ""
    ) {
      document.getElementById("formAccordionCheck15").classList.add("inputted");
    } else {
      document
        .getElementById("formAccordionCheck15")
        .classList.remove("inputted");
    }
    handleInputChangeItem7();
  }

  const jikoshikin = document.getElementById("text_jikoshikin");
  const shinzokuKariirekin = document.getElementById(
    "text_shinzoku_kariirekin"
  );
  const shinzokuKariiremoto = document.getElementById(
    "jfc_template_shinzoku_kariiremoto"
  );
  const shinzokuHensaihoho = document.getElementById(
    "jfc_template_shinzoku_hensaihoho"
  );
  const kokoKariirekin = document.getElementById("text_koko_kariirekin");
  const kokoHensaihoho = document.getElementById(
    "jfc_template_koko_hensaihoho"
  );
  const sonotaKariirekin = document.getElementById("text_sonota_kariirekin");
  const sonotaHensaihoho = document.getElementById(
    "jfc_template_sonota_hensaihoho"
  );
  jikoshikin.addEventListener("input", handleInputChangeChotatsu);
  shinzokuKariirekin.addEventListener("input", handleInputChangeChotatsu);
  shinzokuKariiremoto.addEventListener("input", handleInputChangeChotatsu);
  shinzokuHensaihoho.addEventListener("input", handleInputChangeChotatsu);
  kokoKariirekin.addEventListener("input", handleInputChangeChotatsu);
  kokoHensaihoho.addEventListener("input", handleInputChangeChotatsu);
  sonotaKariirekin.addEventListener("input", handleInputChangeChotatsu);
  sonotaHensaihoho.addEventListener("input", handleInputChangeChotatsu);
  window.addEventListener("load", handleInputChangeChotatsu);
  function handleInputChangeChotatsu() {
    if (
      jikoshikin.value !== "" ||
      shinzokuKariirekin.value !== "" ||
      shinzokuKariiremoto.value !== "" ||
      shinzokuHensaihoho.value !== "" ||
      kokoKariirekin.value !== "" ||
      kokoHensaihoho.value !== "" ||
      sonotaKariirekin.value !== "" ||
      sonotaHensaihoho.value !== ""
    ) {
      document.getElementById("formAccordionCheck16").classList.add("inputted");
    } else {
      document
        .getElementById("formAccordionCheck16")
        .classList.remove("inputted");
    }
    handleInputChangeItem7();
  }

  function handleInputChangeItem7() {
    if (
      setsubishikingaiyo1.value !== "" ||
      setsubishikinmitsumorisaki1.value !== "" ||
      setsubishikinkingaku1.value !== "" ||
      setsubishikingaiyo2.value !== "" ||
      setsubishikinmitsumorisaki2.value !== "" ||
      setsubishikinkingaku2.value !== "" ||
      setsubishikingaiyo3.value !== "" ||
      setsubishikinmitsumorisaki3.value !== "" ||
      setsubishikinkingaku3.value !== "" ||
      setsubishikingaiyo4.value !== "" ||
      setsubishikinmitsumorisaki4.value !== "" ||
      setsubishikinkingaku4.value !== "" ||
      setsubishikingaiyo5.value !== "" ||
      setsubishikinmitsumorisaki5.value !== "" ||
      setsubishikinkingaku5.value !== "" ||
      setsubishikingaiyo6.value !== "" ||
      setsubishikinmitsumorisaki6.value !== "" ||
      setsubishikinkingaku6.value !== "" ||
      setsubishikingaiyo7.value !== "" ||
      setsubishikinmitsumorisaki7.value !== "" ||
      setsubishikinkingaku7.value !== "" ||
      setsubishikingaiyo8.value !== "" ||
      setsubishikinmitsumorisaki8.value !== "" ||
      setsubishikinkingaku8.value !== "" ||
      setsubishikingaiyo9.value !== "" ||
      setsubishikinmitsumorisaki9.value !== "" ||
      setsubishikinkingaku9.value !== "" ||
      setsubishikingaiyo10.value !== "" ||
      setsubishikinmitsumorisaki10.value !== "" ||
      setsubishikinkingaku10.value !== "" ||
      untenshikingaiyo1.value !== "" ||
      untenshikinkingaku1.value !== "" ||
      untenshikingaiyo2.value !== "" ||
      untenshikinkingaku2.value !== "" ||
      untenshikingaiyo3.value !== "" ||
      untenshikinkingaku3.value !== "" ||
      untenshikingaiyo4.value !== "" ||
      untenshikinkingaku4.value !== "" ||
      jikoshikin.value !== "" ||
      shinzokuKariirekin.value !== "" ||
      shinzokuKariiremoto.value !== "" ||
      shinzokuHensaihoho.value !== "" ||
      kokoKariirekin.value !== "" ||
      kokoHensaihoho.value !== "" ||
      sonotaKariirekin.value !== "" ||
      sonotaHensaihoho.value !== ""
    ) {
      document.getElementById("inputStatus7").classList.add("inputted");
    } else {
      document.getElementById("inputStatus7").classList.remove("inputted");
    }
  }

  //8
  const kidoMedoNen = document.getElementById("jfc_template_kido_medo_nen");
  const kidoMedoGetsu = document.getElementById("jfc_template_kido_medo_getsu");
  kidoMedoNen.addEventListener("input", handleInputChangeKido);
  kidoMedoGetsu.addEventListener("input", handleInputChangeKido);
  window.addEventListener("load", handleInputChangeKido);
  function handleInputChangeKido() {
    if (kidoMedoNen.value !== "" || kidoMedoGetsu.value !== "") {
      document.getElementById("formAccordionCheck12").classList.add("inputted");
    } else {
      document
        .getElementById("formAccordionCheck12")
        .classList.remove("inputted");
    }
    handleInputChangeItem8();
  }

  const toshouriagedaka = document.getElementById("text_tosho_uriagedaka");
  const kidogouriagedaka = document.getElementById("text_kidogo_uriagedaka");
  const toshouriagegenka = document.getElementById("text_tosho_uriagegenka");
  const kidogouriagegenka = document.getElementById("text_kidogo_uriagegenka");
  const toshojinkenhi = document.getElementById("text_tosho_jinkenhi");
  const kidogojinkenhi = document.getElementById("text_kidogo_jinkenhi");
  const toshoyachin = document.getElementById("text_tosho_yachin");
  const kidogoyachin = document.getElementById("text_kidogo_yachin");
  const toshorisoku = document.getElementById("text_tosho_risoku");
  const kidogorisoku = document.getElementById("text_kidogo_risoku");
  const toshosonota = document.getElementById("text_tosho_sonota");
  const kidogosonota = document.getElementById("text_kidogo_sonota");
  toshouriagedaka.addEventListener("input", handleInputChangeMitoshi);
  kidogouriagedaka.addEventListener("input", handleInputChangeMitoshi);
  toshouriagegenka.addEventListener("input", handleInputChangeMitoshi);
  kidogouriagegenka.addEventListener("input", handleInputChangeMitoshi);
  toshojinkenhi.addEventListener("input", handleInputChangeMitoshi);
  kidogojinkenhi.addEventListener("input", handleInputChangeMitoshi);
  toshoyachin.addEventListener("input", handleInputChangeMitoshi);
  kidogoyachin.addEventListener("input", handleInputChangeMitoshi);
  toshorisoku.addEventListener("input", handleInputChangeMitoshi);
  kidogorisoku.addEventListener("input", handleInputChangeMitoshi);
  toshosonota.addEventListener("input", handleInputChangeMitoshi);
  kidogosonota.addEventListener("input", handleInputChangeMitoshi);
  window.addEventListener("load", handleInputChangeMitoshi);
  function handleInputChangeMitoshi() {
    if (
      toshouriagedaka.value !== "" ||
      kidogouriagedaka.value !== "" ||
      toshouriagegenka.value !== "" ||
      kidogouriagegenka.value !== "" ||
      toshojinkenhi.value !== "" ||
      kidogojinkenhi.value !== "" ||
      toshoyachin.value !== "" ||
      kidogoyachin.value !== "" ||
      toshorisoku.value !== "" ||
      kidogorisoku.value !== "" ||
      toshosonota.value !== "" ||
      kidogosonota.value !== ""
    ) {
      document.getElementById("formAccordionCheck13").classList.add("inputted");
    } else {
      document
        .getElementById("formAccordionCheck13")
        .classList.remove("inputted");
    }
    handleInputChangeItem8();
  }

  const keisankonkyo = document.getElementById("jfc_template_keisankonkyo");
  keisankonkyo.addEventListener("input", handleInputChangeKonkyo);
  window.addEventListener("load", handleInputChangeKonkyo);
  function handleInputChangeKonkyo() {
    if (keisankonkyo.value !== "") {
      document.getElementById("formAccordionCheck14").classList.add("inputted");
    } else {
      document
        .getElementById("formAccordionCheck14")
        .classList.remove("inputted");
    }
    handleInputChangeItem8();
  }

  function handleInputChangeItem8() {
    if (
      kidoMedoNen.value !== "" ||
      kidoMedoGetsu.value !== "" ||
      toshouriagedaka.value !== "" ||
      kidogouriagedaka.value !== "" ||
      toshouriagegenka.value !== "" ||
      kidogouriagegenka.value !== "" ||
      toshojinkenhi.value !== "" ||
      kidogojinkenhi.value !== "" ||
      toshoyachin.value !== "" ||
      kidogoyachin.value !== "" ||
      toshorisoku.value !== "" ||
      kidogorisoku.value !== "" ||
      toshosonota.value !== "" ||
      kidogosonota.value !== "" ||
      keisankonkyo.value !== ""
    ) {
      document.getElementById("inputStatus8").classList.add("inputted");
    } else {
      document.getElementById("inputStatus8").classList.remove("inputted");
    }
  }

  //9
  const jiyukinyuran = document.getElementById("jfc_template_jiyukinyuran");
  jiyukinyuran.addEventListener("input", handleInputChangeItem9);
  window.addEventListener("load", handleInputChangeItem9);
  function handleInputChangeItem9() {
    if (jiyukinyuran.value !== "") {
      document.getElementById("inputStatus9").classList.add("inputted");
    } else {
      document.getElementById("inputStatus9").classList.remove("inputted");
    }
  }

  //さらに表示
  const moreButton1 = document.getElementById("additionalBtnCareer");
  const moreItemsClass1 = "additionalCareer";
  const moreButton2 = document.getElementById("additionalBtnService");
  const moreItemsClass2 = "additionalService";
  const moreButton3 = document.getElementById("additionalBtnSalesPoint");
  const moreItemsClass3 = "additionalSalesPoint";
  const moreButton4 = document.getElementById("additionalBtnTarget");
  const moreItemsClass4 = "additionalTarget";
  const moreButton5 = document.getElementById("additionalBtnKankyo");
  const moreItemsClass5 = "additionalKankyo";
  const moreButton6 = document.getElementById("additionalBtnHambaisaki");
  const moreItemsClass6 = "additionalHambaisaki";
  const moreButton7 = document.getElementById("additionalBtnShiiresaki");
  const moreItemsClass7 = "additionalShiiresaki";
  const moreButton8 = document.getElementById("additionalBtnGaichusaki");
  const moreItemsClass8 = "additionalGaichusaki";
  const moreButton9 = document.getElementById("additionalBtnKariiresaki");
  const moreItemsClass9 = "additionalKariiresaki";
  const moreButton10 = document.getElementById("additionalBtnSetsubi");
  const moreItemsClass10 = "additionalSetsubi";
  const moreButton11 = document.getElementById("additionalBtnUnten");
  const moreItemsClass11 = "additionalUnten";

  function addMoreItem(addClass) {
    const hiddenItems = document.querySelectorAll(`.${addClass}.hidden`);
    if (hiddenItems[0]) {
      hiddenItems[0].classList.remove("hidden");
    }
  }

  function hideMoreButton(moreButton, addClass) {
    const hiddenItems = document.querySelectorAll(`.${addClass}.hidden`);
    if (!hiddenItems[0]) {
      moreButton.classList.add("d-none");
    }
  }

  moreButton1.addEventListener("click", function () {
    addMoreItem(moreItemsClass1);
    hideMoreButton(moreButton1, moreItemsClass1);
  });
  moreButton2.addEventListener("click", function () {
    addMoreItem(moreItemsClass2);
    hideMoreButton(moreButton2, moreItemsClass2);
  });
  moreButton3.addEventListener("click", function () {
    addMoreItem(moreItemsClass3);
    hideMoreButton(moreButton3, moreItemsClass3);
  });
  moreButton4.addEventListener("click", function () {
    addMoreItem(moreItemsClass4);
    hideMoreButton(moreButton4, moreItemsClass4);
  });
  moreButton5.addEventListener("click", function () {
    addMoreItem(moreItemsClass5);
    hideMoreButton(moreButton5, moreItemsClass5);
  });
  moreButton6.addEventListener("click", function () {
    addMoreItem(moreItemsClass6);
    hideMoreButton(moreButton6, moreItemsClass6);
  });
  moreButton7.addEventListener("click", function () {
    addMoreItem(moreItemsClass7);
    hideMoreButton(moreButton7, moreItemsClass7);
  });
  moreButton8.addEventListener("click", function () {
    addMoreItem(moreItemsClass8);
    hideMoreButton(moreButton8, moreItemsClass8);
  });
  moreButton9.addEventListener("click", function () {
    addMoreItem(moreItemsClass9);
    hideMoreButton(moreButton9, moreItemsClass9);
  });
  moreButton10.addEventListener("click", function () {
    addMoreItem(moreItemsClass10);
    hideMoreButton(moreButton10, moreItemsClass10);
  });
  moreButton11.addEventListener("click", function () {
    addMoreItem(moreItemsClass11);
    hideMoreButton(moreButton11, moreItemsClass11);
  });

  window.addEventListener("load", hideMoreButton(moreButton1, moreItemsClass1));
  window.addEventListener("load", hideMoreButton(moreButton2, moreItemsClass2));
  window.addEventListener("load", hideMoreButton(moreButton3, moreItemsClass3));
  window.addEventListener("load", hideMoreButton(moreButton4, moreItemsClass4));
  window.addEventListener("load", hideMoreButton(moreButton5, moreItemsClass5));
  window.addEventListener("load", hideMoreButton(moreButton6, moreItemsClass6));
  window.addEventListener("load", hideMoreButton(moreButton7, moreItemsClass7));
  window.addEventListener("load", hideMoreButton(moreButton8, moreItemsClass8));
  window.addEventListener("load", hideMoreButton(moreButton9, moreItemsClass9));
  window.addEventListener("load", hideMoreButton(moreButton10, moreItemsClass10));
  window.addEventListener("load", hideMoreButton(moreButton11, moreItemsClass11));

  //入力文字数を表示
  function writeInputLength(textarea, indicatorId) {
    const inputValueLength = textarea.value.length;
    const indicator = document.getElementById(indicatorId);
    indicator.textContent = inputValueLength;
  }

  const countedTextarea1 = document.getElementById(
    "jfc_template_keieisyakeireki_naiyo_1"
  );
  const countedTextarea2 = document.getElementById(
    "jfc_template_keieisyakeireki_naiyo_2"
  );
  const countedTextarea3 = document.getElementById(
    "jfc_template_keieisyakeireki_naiyo_3"
  );
  const countedTextarea4 = document.getElementById(
    "jfc_template_shohingaiyo_1"
  );
  const countedTextarea5 = document.getElementById(
    "jfc_template_shohingaiyo_2"
  );
  const countedTextarea6 = document.getElementById(
    "jfc_template_shohingaiyo_3"
  );
  const countedTextarea7 = document.getElementById(
    "jfc_template_sales_point_1"
  );
  const countedTextarea8 = document.getElementById(
    "jfc_template_sales_point_2"
  );
  const countedTextarea9 = document.getElementById(
    "jfc_template_sales_point_3"
  );
  const countedTextarea10 = document.getElementById(
    "jfc_template_hambai_target_1"
  );
  const countedTextarea11 = document.getElementById(
    "jfc_template_hambai_target_2"
  );
  const countedTextarea12 = document.getElementById(
    "jfc_template_hambai_target_3"
  );
  const countedTextarea13 = document.getElementById(
    "jfc_template_shijokankyo_1"
  );
  const countedTextarea14 = document.getElementById(
    "jfc_template_shijokankyo_2"
  );
  const countedTextarea15 = document.getElementById(
    "jfc_template_shijokankyo_3"
  );
  const countedTextarea16 = document.getElementById(
    "jfc_template_kariiresaki_1"
  );
  const countedTextarea17 = document.getElementById(
    "jfc_template_kariiresaki_2"
  );
  const countedTextarea18 = document.getElementById(
    "jfc_template_kariiresaki_3"
  );
  const countedTextarea19 = document.getElementById(
    "jfc_template_setsubishikin_gaiyo_1"
  );
  const countedTextarea20 = document.getElementById(
    "jfc_template_setsubishikin_gaiyo_2"
  );
  const countedTextarea21 = document.getElementById(
    "jfc_template_setsubishikin_gaiyo_3"
  );
  const countedTextarea22 = document.getElementById(
    "jfc_template_setsubishikin_mitsumorisaki_1"
  );
  const countedTextarea23 = document.getElementById(
    "jfc_template_setsubishikin_mitsumorisaki_2"
  );
  const countedTextarea24 = document.getElementById(
    "jfc_template_setsubishikin_mitsumorisaki_3"
  );
  const countedTextarea25 = document.getElementById(
    "jfc_template_untenshikin_gaiyo_1"
  );
  const countedTextarea26 = document.getElementById(
    "jfc_template_untenshikin_gaiyo_2"
  );
  const countedTextarea27 = document.getElementById(
    "jfc_template_untenshikin_gaiyo_3"
  );
  const countedTextarea28 = document.getElementById(
    "jfc_template_shinzoku_kariiremoto"
  );
  const countedTextarea29 = document.getElementById(
    "jfc_template_shinzoku_hensaihoho"
  );
  const countedTextarea30 = document.getElementById(
    "jfc_template_koko_hensaihoho"
  );
  const countedTextarea31 = document.getElementById(
    "jfc_template_sonota_hensaihoho"
  );
  const countedTextarea32 = document.getElementById(
    "jfc_template_keisankonkyo"
  );
  const countedTextarea33 = document.getElementById(
    "jfc_template_jiyukinyuran"
  );
  const countedTextarea34 = document.getElementById(
    "jfc_template_keizokujigyonaiyo"
  );
  const countedTextarea35 = document.getElementById(
    "jfc_template_shikakumei"
  );
  const countedTextarea36 = document.getElementById(
    "jfc_template_chizai_naiyo"
  );
  const countedTextarea37 = document.getElementById(
    "jfc_template_keieisyakeireki_naiyo_4"
  );
  const countedTextarea38 = document.getElementById(
    "jfc_template_keieisyakeireki_naiyo_5"
  );
  const countedTextarea39 = document.getElementById(
    "jfc_template_keieisyakeireki_naiyo_6"
  );
  const countedTextarea40 = document.getElementById(
    "jfc_template_setsubishikin_gaiyo_4"
  );
  const countedTextarea41 = document.getElementById(
    "jfc_template_setsubishikin_gaiyo_5"
  );
  const countedTextarea42 = document.getElementById(
    "jfc_template_setsubishikin_gaiyo_6"
  );
  const countedTextarea43 = document.getElementById(
    "jfc_template_setsubishikin_gaiyo_7"
  );
  const countedTextarea44 = document.getElementById(
    "jfc_template_setsubishikin_gaiyo_8"
  );
  const countedTextarea45 = document.getElementById(
    "jfc_template_setsubishikin_gaiyo_9"
  );
  const countedTextarea46 = document.getElementById(
    "jfc_template_setsubishikin_gaiyo_10"
  );
  const countedTextarea47 = document.getElementById(
    "jfc_template_setsubishikin_mitsumorisaki_4"
  );
  const countedTextarea48 = document.getElementById(
    "jfc_template_setsubishikin_mitsumorisaki_5"
  );
  const countedTextarea49 = document.getElementById(
    "jfc_template_setsubishikin_mitsumorisaki_6"
  );
  const countedTextarea50 = document.getElementById(
    "jfc_template_setsubishikin_mitsumorisaki_7"
  );
  const countedTextarea51 = document.getElementById(
    "jfc_template_setsubishikin_mitsumorisaki_8"
  );
  const countedTextarea52 = document.getElementById(
    "jfc_template_setsubishikin_mitsumorisaki_9"
  );
  const countedTextarea53 = document.getElementById(
    "jfc_template_setsubishikin_mitsumorisaki_10"
  );
  const countedTextarea54 = document.getElementById(
    "jfc_template_untenshikin_gaiyo_4"
  );

  countedTextarea1.addEventListener("input", function () {
    writeInputLength(countedTextarea1, "span_keieisyakeireki_naiyo_1");
  });
  countedTextarea2.addEventListener("input", function () {
    writeInputLength(countedTextarea2, "span_keieisyakeireki_naiyo_2");
  });
  countedTextarea3.addEventListener("input", function () {
    writeInputLength(countedTextarea3, "span_keieisyakeireki_naiyo_3");
  });
  countedTextarea4.addEventListener("input", function () {
    writeInputLength(countedTextarea4, "span_shohingaiyo_1");
  });
  countedTextarea5.addEventListener("input", function () {
    writeInputLength(countedTextarea5, "span_shohingaiyo_2");
  });
  countedTextarea6.addEventListener("input", function () {
    writeInputLength(countedTextarea6, "span_shohingaiyo_3");
  });
  countedTextarea7.addEventListener("input", function () {
    writeInputLength(countedTextarea7, "span_sales_point_1");
  });
  countedTextarea8.addEventListener("input", function () {
    writeInputLength(countedTextarea8, "span_sales_point_2");
  });
  countedTextarea9.addEventListener("input", function () {
    writeInputLength(countedTextarea9, "span_sales_point_3");
  });
  countedTextarea10.addEventListener("input", function () {
    writeInputLength(countedTextarea10, "span_hambai_target_1");
  });
  countedTextarea11.addEventListener("input", function () {
    writeInputLength(countedTextarea11, "span_hambai_target_2");
  });
  countedTextarea12.addEventListener("input", function () {
    writeInputLength(countedTextarea12, "span_hambai_target_3");
  });
  countedTextarea13.addEventListener("input", function () {
    writeInputLength(countedTextarea13, "span_shijokankyo_1");
  });
  countedTextarea14.addEventListener("input", function () {
    writeInputLength(countedTextarea14, "span_shijokankyo_2");
  });
  countedTextarea15.addEventListener("input", function () {
    writeInputLength(countedTextarea15, "span_shijokankyo_3");
  });
  countedTextarea16.addEventListener("input", function () {
    writeInputLength(countedTextarea16, "span_kariiresaki_1");
  });
  countedTextarea17.addEventListener("input", function () {
    writeInputLength(countedTextarea17, "span_kariiresaki_2");
  });
  countedTextarea18.addEventListener("input", function () {
    writeInputLength(countedTextarea18, "span_kariiresaki_3");
  });
  countedTextarea19.addEventListener("input", function () {
    writeInputLength(countedTextarea19, "span_setsubishikin_gaiyo_1");
  });
  countedTextarea20.addEventListener("input", function () {
    writeInputLength(countedTextarea20, "span_setsubishikin_gaiyo_2");
  });
  countedTextarea21.addEventListener("input", function () {
    writeInputLength(countedTextarea21, "span_setsubishikin_gaiyo_3");
  });
  countedTextarea22.addEventListener("input", function () {
    writeInputLength(countedTextarea22, "span_setsubishikin_mitsumorisaki_1");
  });
  countedTextarea23.addEventListener("input", function () {
    writeInputLength(countedTextarea23, "span_setsubishikin_mitsumorisaki_2");
  });
  countedTextarea24.addEventListener("input", function () {
    writeInputLength(countedTextarea24, "span_setsubishikin_mitsumorisaki_3");
  });
  countedTextarea25.addEventListener("input", function () {
    writeInputLength(countedTextarea25, "span_untenshikin_gaiyo_1");
  });
  countedTextarea26.addEventListener("input", function () {
    writeInputLength(countedTextarea26, "span_untenshikin_gaiyo_2");
  });
  countedTextarea27.addEventListener("input", function () {
    writeInputLength(countedTextarea27, "span_untenshikin_gaiyo_3");
  });
  countedTextarea28.addEventListener("input", function () {
    writeInputLength(countedTextarea28, "span_shinzoku_kariiremoto");
  });
  countedTextarea29.addEventListener("input", function () {
    writeInputLength(countedTextarea29, "span_shinzoku_hensaihoho");
  });
  countedTextarea30.addEventListener("input", function () {
    writeInputLength(countedTextarea30, "span_koko_hensaihoho");
  });
  countedTextarea31.addEventListener("input", function () {
    writeInputLength(countedTextarea31, "span_sonota_hensaihoho");
  });
  countedTextarea32.addEventListener("input", function () {
    writeInputLength(countedTextarea32, "span_keisankonkyo");
  });
  countedTextarea33.addEventListener("input", function () {
    writeInputLength(countedTextarea33, "span_jiyukinyuran");
  });
  countedTextarea34.addEventListener("input", function () {
    writeInputLength(countedTextarea34, "span_keizokujigyonaiyo");
  });
  countedTextarea35.addEventListener("input", function () {
    writeInputLength(countedTextarea35, "span_shikakumei");
  });
  countedTextarea36.addEventListener("input", function () {
    writeInputLength(countedTextarea36, "span_chizai_naiyo");
  });
  countedTextarea37.addEventListener("input", function () {
    writeInputLength(countedTextarea37, "span_keieisyakeireki_naiyo_4");
  });
  countedTextarea38.addEventListener("input", function () {
    writeInputLength(countedTextarea38, "span_keieisyakeireki_naiyo_5");
  });
  countedTextarea39.addEventListener("input", function () {
    writeInputLength(countedTextarea39, "span_keieisyakeireki_naiyo_6");
  });
  countedTextarea40.addEventListener("input", function () {
    writeInputLength(countedTextarea40, "span_setsubishikin_gaiyo_4");
  });
  countedTextarea41.addEventListener("input", function () {
    writeInputLength(countedTextarea41, "span_setsubishikin_gaiyo_5");
  });
  countedTextarea42.addEventListener("input", function () {
    writeInputLength(countedTextarea42, "span_setsubishikin_gaiyo_6");
  });
  countedTextarea43.addEventListener("input", function () {
    writeInputLength(countedTextarea43, "span_setsubishikin_gaiyo_7");
  });
  countedTextarea44.addEventListener("input", function () {
    writeInputLength(countedTextarea44, "span_setsubishikin_gaiyo_8");
  });
  countedTextarea45.addEventListener("input", function () {
    writeInputLength(countedTextarea45, "span_setsubishikin_gaiyo_9");
  });
  countedTextarea46.addEventListener("input", function () {
    writeInputLength(countedTextarea46, "span_setsubishikin_gaiyo_10");
  });
  countedTextarea47.addEventListener("input", function () {
    writeInputLength(countedTextarea47, "span_setsubishikin_mitsumorisaki_4");
  });
  countedTextarea48.addEventListener("input", function () {
    writeInputLength(countedTextarea48, "span_setsubishikin_mitsumorisaki_5");
  });
  countedTextarea49.addEventListener("input", function () {
    writeInputLength(countedTextarea49, "span_setsubishikin_mitsumorisaki_6");
  });
  countedTextarea50.addEventListener("input", function () {
    writeInputLength(countedTextarea50, "span_setsubishikin_mitsumorisaki_7");
  });
  countedTextarea51.addEventListener("input", function () {
    writeInputLength(countedTextarea51, "span_setsubishikin_mitsumorisaki_8");
  });
  countedTextarea52.addEventListener("input", function () {
    writeInputLength(countedTextarea52, "span_setsubishikin_mitsumorisaki_9");
  });
  countedTextarea53.addEventListener("input", function () {
    writeInputLength(countedTextarea53, "span_setsubishikin_mitsumorisaki_10");
  });
  countedTextarea54.addEventListener("input", function () {
    writeInputLength(countedTextarea54, "span_untenshikin_gaiyo_4");
  });

  const inputEvent = new Event("input");
  countedTextarea1.dispatchEvent(inputEvent);
  countedTextarea2.dispatchEvent(inputEvent);
  countedTextarea3.dispatchEvent(inputEvent);
  countedTextarea4.dispatchEvent(inputEvent);
  countedTextarea5.dispatchEvent(inputEvent);
  countedTextarea6.dispatchEvent(inputEvent);
  countedTextarea7.dispatchEvent(inputEvent);
  countedTextarea8.dispatchEvent(inputEvent);
  countedTextarea9.dispatchEvent(inputEvent);
  countedTextarea10.dispatchEvent(inputEvent);
  countedTextarea11.dispatchEvent(inputEvent);
  countedTextarea12.dispatchEvent(inputEvent);
  countedTextarea13.dispatchEvent(inputEvent);
  countedTextarea14.dispatchEvent(inputEvent);
  countedTextarea15.dispatchEvent(inputEvent);
  countedTextarea16.dispatchEvent(inputEvent);
  countedTextarea17.dispatchEvent(inputEvent);
  countedTextarea18.dispatchEvent(inputEvent);
  countedTextarea19.dispatchEvent(inputEvent);
  countedTextarea20.dispatchEvent(inputEvent);
  countedTextarea21.dispatchEvent(inputEvent);
  countedTextarea22.dispatchEvent(inputEvent);
  countedTextarea23.dispatchEvent(inputEvent);
  countedTextarea24.dispatchEvent(inputEvent);
  countedTextarea25.dispatchEvent(inputEvent);
  countedTextarea26.dispatchEvent(inputEvent);
  countedTextarea27.dispatchEvent(inputEvent);
  countedTextarea28.dispatchEvent(inputEvent);
  countedTextarea29.dispatchEvent(inputEvent);
  countedTextarea30.dispatchEvent(inputEvent);
  countedTextarea31.dispatchEvent(inputEvent);
  countedTextarea32.dispatchEvent(inputEvent);
  countedTextarea33.dispatchEvent(inputEvent);
  countedTextarea34.dispatchEvent(inputEvent);
  countedTextarea35.dispatchEvent(inputEvent);
  countedTextarea36.dispatchEvent(inputEvent);
  countedTextarea37.dispatchEvent(inputEvent);
  countedTextarea38.dispatchEvent(inputEvent);
  countedTextarea39.dispatchEvent(inputEvent);
  countedTextarea40.dispatchEvent(inputEvent);
  countedTextarea41.dispatchEvent(inputEvent);
  countedTextarea42.dispatchEvent(inputEvent);
  countedTextarea43.dispatchEvent(inputEvent);
  countedTextarea44.dispatchEvent(inputEvent);
  countedTextarea45.dispatchEvent(inputEvent);
  countedTextarea46.dispatchEvent(inputEvent);
  countedTextarea47.dispatchEvent(inputEvent);
  countedTextarea48.dispatchEvent(inputEvent);
  countedTextarea49.dispatchEvent(inputEvent);
  countedTextarea50.dispatchEvent(inputEvent);
  countedTextarea51.dispatchEvent(inputEvent);
  countedTextarea52.dispatchEvent(inputEvent);
  countedTextarea53.dispatchEvent(inputEvent);
  countedTextarea54.dispatchEvent(inputEvent);

  $("#founding_template_reflected").on("click", function() {
    //7
    handleInputChangeShikin();
    handleInputChangeChotatsu();
    //8
    handleInputChangeKido();
    handleInputChangeMitoshi();
    handleInputChangeKonkyo();
  });

  function toggleSogyoBtnReverseFirstSogyoBtnReverseFinal() {
    if ($("#sogyoFormContents").hasClass("display")) {
      $("#sogyoBtnReverseFirst").removeClass("d-none");
      $("#sogyoBtnReverseFinal").addClass("d-none");
    } else if ($("#sogyoForm9").hasClass("display")) {
      $("#sogyoBtnReverseFinal").removeClass("d-none");
      $("#sogyoBtnReverseFirst").addClass("d-none");
    } else {
      $("#sogyoBtnReverseFirst").addClass("d-none");
      $("#sogyoBtnReverseFinal").addClass("d-none");
    }
  }
  toggleSogyoBtnReverseFirstSogyoBtnReverseFinal();
} catch (error) {
  console.warn("このページには創業計画に関する項目はありません");
}
