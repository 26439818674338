export function setWithCountAnimation(element, from, to){
  const DURATION = 500
  const startTime = Date.now()
  const timer = setInterval(() => {
    const elapsedTime = Date.now() - startTime
    const progress = elapsedTime / DURATION

    let value = 0;
    if (progress < 1) {
      value = Math.floor(from + progress * (to - from));
    } else {
      value = to
      clearInterval(timer)
    }
    element.textContent = value.toLocaleString('ja-JP');
    if (value < 0){
      element.classList.add("minus-red");
    } else {
      element.classList.remove("minus-red");
    }
  }, 20)
}

export function sanitizeMoney(value) {
  let result = value
  // 全角数字の変換
  result = result.replace(/[０-９]/g, function(s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
  });
  // ハイフンやマイナス、カンマがある場合は削除する
  "－‐−‒—–―ーｰ─━一-,，".split("").forEach((hyphen) => {
    result = result.replace(hyphen, "")
  });

  // 数値変換出来ない場合は0に置換
  result = Number.isFinite(parseInt(result)) ? result : 0

  // 100億以上はトリムする
  if (result.length > 5) result = result.slice(0, 5)

  return parseInt(result)
}

export function delimitedMoney(money){
  return sanitizeMoney(money).toLocaleString('ja-JP')
}

export function updateDelimitedMoney(textFieldElement, automaticInput = true) {
  // hidden fieldへのコピー
  const hiddenElementId = textFieldElement.dataset.target;
  const hiddenElement = document.getElementById(hiddenElementId);
  hiddenElement.value = sanitizeMoney(textFieldElement.value);
  if (automaticInput || textFieldElement.value != '') {
    // サニタイズ&カンマ区切り
    textFieldElement.value = delimitedMoney(textFieldElement.value);
  }

  // hidden fieldにchangeイベントを発生させる
  const changeEvent = new Event("change");
  hiddenElement.dispatchEvent(changeEvent);
}

// 対応するtext fieldにカンマ区切りでセットする
export function setDelimitedMoney(hiddenElement) {
  const inputElementId =  "text_" + hiddenElement.id;
  const inputElement = document.getElementById(inputElementId);
  inputElement.value = parseInt(hiddenElement.value).toLocaleString('ja-JP');
}

// レンダリング後にhidden fieldからtext fieldへの初期セット
$(function() {
  $(".js-set-delimited-money").each(function(){
    if (this.value !== "") {
      setDelimitedMoney(this);
    }
  });
});

// 数値計画の入力フォーム上でエンターを押してもサブミットしない
$(function() {
  $("#sogyo_suchi_form").on("keypress", function (event) {
    var keyPressed = event.keyCode || event.which;
    if (keyPressed === 13) {
        event.preventDefault();
        return false;
    }
  });
});