// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
//import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
//Turbolinks.start()
ActiveStorage.start()

require("trix")
require("@rails/actiontext")

import "bootstrap"
import "stylesheets/style"

require("src/muitiple-selective-checkbox")
require("src/modal-fetch")
require("src/customs")
require("src/createSwiper")
require("src/kaigyoShikinSlider")
require("src/untenShikinSlider")
require("src/clipboardcopy")
require("src/inputNumber")
require("src/sogyo-keikaku")

require("src/user")
require("src/search")
require("src/plan")
require("src/founding-template")
require("src/jfc-template")

require("src/easy-diagnosis")
require("src/shindan-form")

require("src/numBtn")
require("src/suuchi-keikaku")

require("src/copy-sp-modal")

require("src/learn-search/suggest")
require("src/learn-search/searchTag")
require("src/learn-search/category")
require("src/learn-search/favorite")
require("src/learn-search/scroll")
require("src/learn-search/result")

require("src/learn-search/study")

// .js.erbファイルでjQueryを使用するための記述
window.$ = jQuery;

const images = require.context("../../images", true)
const imagePath = name => images(name, true)

// tooltip 初期化
import { Tooltip } from "bootstrap";
const tooltipTriggerList = [].slice.call(
  document.querySelectorAll('[data-bs-toggle="tooltip"]')
);
const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new Tooltip(tooltipTriggerEl);
});
