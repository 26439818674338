import { sanitizeMoney, updateDelimitedMoney } from "./suchiUtil";
import { automaticCalculationValidate } from "./founding-template";
// 入力フォームの中身のチェック&変更
$(function() {
  $(".inputMoneyFillByZero").on("change", function (e) {
    let result = $(this).val();
    $(this).val(sanitizeMoney(result));
  });
});

// プラス/マイナスボタンで値を10ずつ変化
const inputEvent = new Event("input");
const changeEvent = new Event("change");
const suuchiNumBtnSets = document.querySelectorAll(".suuchi-numBtn");
function incrementValue(input) {
  var inputValue = sanitizeMoney(input.value);
  if (input.value === "") {
    input.value = 100;
  } else {
    inputValue += 10;
    input.value = inputValue;
  }
  input.dispatchEvent(inputEvent);
  input.dispatchEvent(changeEvent);
}
function decrementValue(input) {
  var inputValue = sanitizeMoney(input.value);
  if (input.value === "") {
    input.value = 100;
  } else if (inputValue >= 10) {
    inputValue -= 10;
    input.value = inputValue;
  } else {
    input.value = 0;
  }
  input.dispatchEvent(inputEvent);
  input.dispatchEvent(changeEvent);
}
suuchiNumBtnSets.forEach((numBtnSet) => {
  var suuchiInput = numBtnSet.querySelector(".suuchiInput");
  var suuchiPlus = numBtnSet.querySelector(".suuchiPlus");
  var suuchiMinus = numBtnSet.querySelector(".suuchiMinus");
  suuchiPlus.addEventListener("click", function () {
    incrementValue(suuchiInput);
  });
  suuchiMinus.addEventListener("click", function () {
    decrementValue(suuchiInput);
  });
});

const step1SuuchiInputs = document.querySelectorAll("#form_suuchi_step1_sp .suuchiInput");
step1SuuchiInputs.forEach((suuchiInput) => {
  suuchiInput.addEventListener("change", function () {
    // カンマ区切り処理
    updateDelimitedMoney(suuchiInput, false);
  });
});

const step2SuuchiInputs = document.querySelectorAll("#form_suuchi_step2_sp .suuchiInput");
step2SuuchiInputs.forEach((suuchiInput) => {
  suuchiInput.addEventListener("change", function () {
    // カンマ区切り処理
    updateDelimitedMoney(suuchiInput, true);
  });
});
