document.addEventListener("DOMContentLoaded", function () {
  const searchForm = document.querySelector(".ls-searchForm");
  if (!searchForm) return;

  const upperRow = searchForm.querySelector(".ls-searchForm__row");
  const lowerRow = searchForm.querySelector(".ls-searchForm__row--lower");
  if (!upperRow || !lowerRow) return;

  let lastScrollTop = 0;
  let ticking = false;

  function updateSearchForm(scrollTop) {
    const scrollDown = scrollTop > lastScrollTop;
    const upperRowHeight = upperRow.offsetHeight;
    const lowerRowHeight = lowerRow.offsetHeight;

    if (scrollTop > upperRowHeight) {
      upperRow.style.position = "fixed";
      upperRow.style.top = "0";
      upperRow.style.left = "0";
      upperRow.style.width = "100%";
      upperRow.style.zIndex = "1000";
      searchForm.style.paddingTop = `${upperRowHeight}px`;

      if (scrollDown) {
        lowerRow.style.transform = `translateY(-${lowerRowHeight}px)`;
      } else {
        lowerRow.style.transform = "translateY(0)";
      }

      lowerRow.style.position = "fixed";
      lowerRow.style.top = `${upperRowHeight}px`;
      lowerRow.style.left = "0";
      lowerRow.style.width = "100%";
      lowerRow.style.zIndex = "999";
    } else {
      upperRow.style.position = "static";
      upperRow.style.width = "auto";
      searchForm.style.paddingTop = "0";
      lowerRow.style.position = "static";
      lowerRow.style.transform = "translateY(0)";
    }

    lastScrollTop = scrollTop;
  }

  window.addEventListener("scroll", function (e) {
    if (!ticking) {
      window.requestAnimationFrame(function () {
        updateSearchForm(window.pageYOffset);
        ticking = false;
      });

      ticking = true;
    }
  });
});
